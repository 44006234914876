.flos-ResponsiveCoverage {
    .price {
        display: inline;
        margin-right: 10px;
        font-size: $size-font-medium;
        font-weight: 700;
    }

    .flos-ResponsiveCoverage-dropdown-section {
        padding: 15px 20px;
        @media (max-width: $screen-xs-max) {
            padding: 30px 15px;
        }
        .deductibleBoxes {
            .Box--selectable {
                font-size: $size-font-base;
                padding: 8px 0;
            }

            > select {
                margin-top: 15px;
            }
        }
    }
    .row--alt {
        margin-top: 30px;
    }
}

.collapse-deductible,
.collapse-details {
    .flos-ResponsiveCoverage-content {
        padding: 0 20px 15px;
        @media (max-width: $screen-xs-max) {
            padding: 0 11px 15px;
        }
        .flos-ResponsiveCoverage-dropdown-section {
            border-top: 1px solid #eee;
            padding-top: 30px;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.item-row {
    margin-left: -8px;
    margin-right: -8px;
}

.item-flex {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
}

.flex-col {
    margin-left: 8px;
    margin-right: 8px;
}

/**** The follow styles is copied from Bootstrap v. 4 ****/
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}
/*********************************************************/

.a-disabled {
    pointer-events: none;
    cursor: default;
}

/* IE10 and IE11 styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    input[type='range'] {
        padding: 0 2px;
        height: 34px;

        &::-ms-tooltip {
            display: none;
        }
    }
}

@supports (-ms-ime-align: auto) {
    input[type='range'] {
        padding: 0 2px;
        height: 34px;

        &::-ms-tooltip {
            display: none;
        }
    }
}
