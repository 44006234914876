//
// List groups
// --------------------------------------------------

//** Background color on `.list-group-item`
$list-group-bg: #fff !default;
//** `.list-group-item` border color
$list-group-border: #ddd !default;
//** Text color of disabled list items
$list-group-disabled-color: #777 !default;
//** Background color of disabled list items
$list-group-disabled-bg: #eee !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;
//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color: #fff !default;
//** Background color of active list items
$list-group-active-bg: $color-primary-2-base !default;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%) !default;

$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;
// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
    // No need to set list-style: none; since .list-group-item is block level
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 20px;
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    // Place the border on the list items and negative margin up for better styling
    margin-bottom: -1px;
    background-color: $list-group-bg;
    border: 1px solid $list-group-border;

    // Round the first and last items
    &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    &:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    // Disabled state
    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
        color: $list-group-disabled-color;
        cursor: $cursor-disabled;
        background-color: $list-group-disabled-bg;

        // Force color to inherit for custom content
        .list-group-item-heading {
            color: inherit;
        }
        .list-group-item-text {
            color: $list-group-disabled-text-color;
        }
    }

    // Active class on item itself, not parent
    &.active,
    &.active:hover,
    &.active:focus {
        z-index: 2; // Place active items above their siblings for proper border styling
        color: $list-group-active-color;
        background-color: $list-group-active-bg;
        border-color: $list-group-active-border;

        // Force color to inherit for custom content
        .list-group-item-heading,
        .list-group-item-heading > small,
        .list-group-item-heading > .small {
            color: inherit;
        }
        .list-group-item-text {
            color: $list-group-active-text-color;
        }
    }
}

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item,
button.list-group-item {
    color: $list-group-link-color;

    // Hover state
    &:hover,
    &:focus {
        color: $list-group-link-hover-color;
        text-decoration: none;
        background-color: $list-group-hover-bg;
    }
}

button.list-group-item {
    width: 100%;
    text-align: left;
}
