.SummaryBar-price {
    width: 100%;
    &__heading {
        font-size: $size-font-small;
    }
    & i {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        &.up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            transition: 300ms linear all;
        }
        &.down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transition: 300ms linear all;
        }
    }
    .top-right {
        margin-top: -15px;
        float: right;
    }
    .a-disabled {
        pointer-events: none;
        cursor: default;
    }
    .SummaryBar-price__heading {
        @media (min-width: 900px) {
            font-size: $size-font-medium;
        }
    }
    .cm-yourPrice {
        display: flex;
    }
    .pricebar-arrow-flex {
        flex-grow: 1;
    }
    a.SummaryBar-panel-link {
        display: inline-block !important;
    }
}
