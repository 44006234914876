.flos-checkbox-group {
    display: flex;
    gap: $spacing-3;
    flex-wrap: wrap;

    > .flos-field {
        flex-grow: 1;
        flex-basis: 0;
    }
    &--stacked {
        flex-direction: column;

        .flos-field {
            margin-bottom: unset;
        }
        .flos-field--with-helptext {
            margin-bottom: $spacing-6;
        }
    }
}

.flos-field {
    input[type='checkbox'].flos-input {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.flos-checkbox {
    border-radius: 4px;
    border: 1px solid $formfield-input-bordercolor;
    color: $formfield-label-color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: $spacing-5;
    user-select: none;
    position: relative;
    transition-property: border-color, background-color;
    transition-duration: $formfield-checked-duration;
    transition-timing-function: $formfield-timing-function;

    .has-error & {
        border-color: $formfield-input-color--invalid;
    }

    &--check-top {
        display: block;
    }

    &:active,
    &:focus,
    &:hover {
        border-color: $formfield-checked-color;
    }

    &--label {
        color: $checkbox-color;
        font-weight: $formfield-font-weight--focus;
        padding-left: $spacing-8;
        line-height: $spacing-6;
    }
    &--text {
        font-weight: 300;
        font-size: $size-font-xsmall;
        padding-left: $spacing-8;
        line-height: $spacing-6;
        color: $checkbox-text-color;
        margin-top: $spacing-3;
    }

    svg path {
        display: none;
    }
    flos-icon {
        background-clip: content-box;
        border-radius: $formfield-input-border-radius;
        border: 1px solid $formfield-checked-color;
        display: inline-block;
        flex-shrink: 0;
        left: $spacing-5;
        padding: $spacing-2;
        position: absolute;
        top: $spacing-5;

        &::before {
            content: '';
            background-color: $formfield-checked-color;
            border-radius: $formfield-input-border-radius;
            display: inline-block;
            position: absolute;
            height: 18px;
            width: 18px;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0) scale(0);
            transition-property: transform;
            transition-duration: $formfield-checked-duration;
            transition-timing-function: $formfield-timing-function;
        }
    }

    &.is-disabled {
        cursor: not-allowed;
        border-color: $checkbox-bordercolor--disabled;
        .flos-checkbox {
            &--label {
                color: $formfield-input-color--disabled;
                flos-icon {
                    border-color: $formfield-input-color--disabled;
                }
            }
            &--text {
                color: $formfield-input-color--disabled;
            }
        }
        &:active,
        &:focus,
        &:hover {
            border-color: $checkbox-bordercolor--disabled;
        }
    }

    input.flos-input:checked ~ &, // support apps that haven't update.
	&--checked {
        border-color: $formfield-checked-color;
        background-color: $formfield-checked-bgcolor;

        .has-error & {
            border-color: $formfield-checked-color;
        }

        &.is-disabled {
            background-color: $checkbox-bgcolor--disabled;
            border-color: $formfield-input-color--disabled;
            &:active,
            &:focus,
            &:hover {
                border-color: $formfield-input-color--disabled;
                border-color: $formfield-input-color--disabled;
            }

            & flos-icon {
                border-color: $formfield-input-color--disabled;
                background-color: $checkbox-bgcolor--disabled;
                svg {
                    background-color: $formfield-input-color--disabled;
                }
                &::before {
                    background-color: $formfield-input-color--disabled;
                }
            }
        }

        svg path {
            display: unset;
        }
        flos-icon {
            border-color: $formfield-checked-color;

            &::before {
                background-color: $formfield-checked-color;
                transform: translate3d(-50%, -50%, 0) scale(1);
                transition-timing-function: $formfield-checked-timing;
            }

            svg {
                position: absolute;
                height: 10px;
                width: 10px;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
            path {
                stroke: white;
                stroke-width: 1.2;
                display: block;
            }
        }
    }
}
