//
// Wells
// --------------------------------------------------

// Base class
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $color-secondary-4-base;
    &--active {
        border-left: 6px solid $color-primary-1-base;
        background-color: $color-secondary-2-base;
    }
    &--inactive {
        border-left: 6px solid $color-supplement-grey-light;
    }

    p:last-child {
        margin-bottom: 0;
    }
    blockquote {
        border-color: rgba(0, 0, 0, 0.15);
    }
}

// Sizes
.well-lg {
    padding: 24px;
}
.well-sm {
    padding: 9px;
}
