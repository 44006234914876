.site-header {
    background-color: $site-header-bg;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: $spacing-2 $spacing-7;
    display: flex;
    border-bottom: 1px solid $site-header-border-color;
    height: $site-header-desktop-height;
    & > ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        margin-bottom: 0;
    }
}

.site-header-menu-item {
    margin-bottom: 0;
    a,
    button {
        white-space: nowrap;

        &:not(.flos-button) {
            background-color: transparent;
            border: none;
            outline: none;
        }
    }

    .flos-button.flos-button--small {
        padding-left: $spacing-5;
        padding-right: $spacing-5;
    }

    .flos-link-arrow {
        stroke: $site-header-link-arrow-color;
    }

    &.active > a:not(.flos-button) {
        color: $site-header-link-color--hover;
        flos-icon {
            stroke: $site-header-link-color--hover;
        }
    }
    label {
        font-weight: 400;
    }
}

.site-header-menu-item--logout {
    text-align: center;
    &:not(:only-child) {
        border-top: 1px solid $color-supplement-grey-base;
        margin-top: $spacing-3 !important;
        padding-top: $spacing-3;
        margin-left: -$spacing-3 !important;
        margin-right: -$spacing-3 !important;
    }
    a {
        color: $color-supplement-error-base !important;
    }
}

.site-header-menu-item--user-name {
    &:not(:only-child) {
        border-bottom: 1px solid $color-supplement-grey-base;
        @media (min-width: $screen-md) {
            margin-left: -$spacing-3 !important;
            margin-right: -$spacing-3 !important;
            margin-bottom: $spacing-3;
        }
    }
    p {
        padding: $spacing-5;
        margin-bottom: 0;
        text-align: center;
        max-width: none;
        @media (min-width: $screen-md) {
            text-align: left;
            padding: $spacing-3 $spacing-3 $spacing-5 $spacing-5;
        }
    }
}

.site-header-menu-item--secondary {
    color: $color-supplement-grey-medium;
    & > a {
        padding: $spacing-3;
        border-radius: $border-radius-small;
        &:hover {
            background-color: $site-header-mobile-link-bg--hover;
            flos-icon:not(.flos-link-arrow) {
                stroke: $site-header-mobile-link-color;
            }
        }
    }
}

.site-header-menu-item--static {
    color: $color-supplement-grey-medium;
}

.site-header-menu-item-link {
    color: $site-header-mobile-link-color;
    display: flex;
    gap: 16px;
    padding: $spacing-5;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-start;

    &.submenu-label-back {
        color: $color-primary-1-medium;
    }

    &:hover {
        color: $site-header-mobile-link-color--hover;
    }
    .flos-link-arrow {
        margin-left: auto;
    }
    @media (min-width: $screen-md) {
        color: $site-header-link-color;
        &:hover {
            color: $site-header-link-color--hover;
            flos-icon:not(.flos-link-arrow) {
                stroke: $site-header-link-color--hover;
            }
        }
    }
}

.site-header-logo {
    display: flex;
    align-items: center;
    svg {
        display: block;
    }
    @media (min-width: $screen-md) {
        position: absolute;
        top: $site-header-action-menu-top;
    }
}

.site-header-menu {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flos-icon {
        display: inline-block;
        stroke: $site-header-mobile-link-color;
    }

    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-basis: 100%;
        min-width: 200px;
        & ~ a {
            background-color: $color-supplement-grey-light;
        }
    }
}

.site-header-actionmenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-5;
    flos-icon {
        display: inline-block;
        stroke: $site-header-link-color;
    }
    .site-header-menu-item-link:not(.flos-button) {
        padding: 0;
        gap: $spacing-3;
    }
    @media (min-width: $screen-md) {
        position: absolute;
        right: $spacing-7;
        top: $site-header-action-menu-top;
    }
}

.submenu-label-back {
    .flos-link-arrow {
        margin-left: 0;
    }
}

.has-submenu {
    &.is-open > .site-header-submenu {
        visibility: visible;
        opacity: 1;
        display: block;
    }
}

.site-header-sidebar {
    z-index: 1000;
    position: fixed;
    list-style: none;
    background-color: $color-supplement-white-base;
    height: calc(100vh - #{$site-header-desktop-height});
    padding: $spacing-7;
    width: $site-header-sidebar-desktop-width;
    top: $site-header-desktop-height;
    right: 0;
    overflow-y: auto;
    transition-timing-function: ease-out;

    @media (max-width: $screen-sm-max) {
        position: absolute;
        height: 0;
        padding: $spacing-7 $spacing-5;
        right: 0;
        top: $site-header-mobile-height;
        width: 100vw;
    }

    &.is-visible {
        visibility: visible;
        opacity: 1;
        transition: transform 250ms, opacity 250ms;

        @media (max-width: $screen-sm-max) {
            transition: height 250ms, opacity 200ms;
            height: calc(100vh - #{$site-header-mobile-height});
        }
    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
        transform: translateX(calc(#{$site-header-sidebar-desktop-width} * 0.15));
        transition: transform 250ms, opacity 250ms, visibility 250ms;

        @media (max-width: $screen-sm-max) {
            transform: none;
        }
    }
}

.site-header-sidebar-backdrop {
    position: fixed;
    background-color: $color-supplement-black-base;
    height: calc(100vh - #{$site-header-desktop-height});
    width: 100vw;
    top: $site-header-desktop-height;
    right: 0;
    z-index: 999;

    @media (max-width: $screen-sm-max) {
        display: none;
    }

    &.is-visible {
        visibility: visible;
        opacity: 0.6;
        transition: opacity 200ms ease-in;
    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
        transition: all 200ms ease-out;
    }
}

.site-header-submenu {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    min-width: 217px;
    list-style: none;
    transition: all 0.25s ease;
    padding: $spacing-3;
    background-color: $color-supplement-white-base;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium;

    .site-header-menu-item {
        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: $site-header-submenu-link-color;

            flos-icon {
                display: inline-block;
            }

            &:hover {
                color: $site-header-submenu-link-color;

                @media (max-width: 991px) {
                    font-weight: 700;
                }
            }
        }

        .site-header-menu-item-link {
            padding: $spacing-3;
        }

        &.active a {
            background-color: $site-header-mobile-link-bg--hover;
            color: $site-header-submenu-link-color;
            flos-icon {
                stroke: $site-header-submenu-link-color;
                &.flos-link-arrow {
                    stroke: $site-header-link-arrow-color;
                }
            }
        }
    }

    &.site-header-submenu--two-col {
        padding: $spacing-5;
        @media (min-width: $screen-md) {
            display: grid !important;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: $spacing-5;
            min-width: max-content;
            left: 0;
            background-color: $color-supplement-white-base;

            li {
                grid-column-end: span 6;

                a {
                    border-bottom: none !important;
                }
            }
        }
    }
    &.site-header-submenu--search {
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid $site-header-border-color;
        padding: $spacing-5;
    }
}

#site-header-search {
    position: sticky;
    top: $site-header-desktop-height;
    background-color: $color-supplement-white-base;
    z-index: 998;
    width: 100%;
    padding: $spacing-5 0;
    border-bottom: 1px solid $site-header-border-color;
    display: none;
    &.is-open {
        display: block;
    }
}

.site-header-search-form {
    gap: $spacing-3;
    pointer-events: auto;
    opacity: 1;
    display: flex;
    width: auto;

    .flos-field {
        margin-bottom: 0;
        background-color: $site-header-search-form-input-bg-color;
    }
}
