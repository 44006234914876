.typeaheadfield {
    position: relative;
    &.is-open {
        .flos-input {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.typeaheadfield-suggestionlist {
    position: absolute;
    background-color: $color-supplement-white-base;
    display: block;
    width: 100%;
    padding: $spacing-5 $spacing-4 $spacing-5 $spacing-5;
    border: 1px solid $formfield-input-bordercolor--active;
    border-top-width: 0;
    z-index: 1001;
    border-bottom-right-radius: $formfield-input-border-radius;
    border-bottom-left-radius: $formfield-input-border-radius;
}

.typeaheadfield-suggestionlist-options {
    position: relative;
    display: block;
    left: 0;
    right: 0;
    border-top: 0;
    transition: all 0.5s;
    z-index: 2;
    max-height: 200px;
    overflow-x: auto;
    padding: 0;
    margin: 0;
    &::-webkit-scrollbar {
        width: $spacing-2;
    }
    &::-webkit-scrollbar-track {
        background-color: $color-secondary-4-base;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-primary-1-base;
    }
    &.no-suggestions {
        color: $color-secondary-4-medium;
        margin: 0;
    }
}

.typeaheadfield-suggestionlist-options-group {
    padding: 0;
    .typeaheadfield-suggestionlist-option {
        padding-left: $spacing-4;
    }
}
.typeaheadfield-suggestionlist-options-group-header {
    font-weight: 500;
    margin-bottom: $spacing-5;
}

.typeaheadfield-suggestionlist-option {
    position: relative;
    display: block;
    padding: 0 $spacing-7 0 0;
    transition: all 0.5s;
    margin: 0;
    font-size: $size-font-base;
    a {
        display: block;
        padding: $spacing-4;
        color: $typeahead-field-suggestionlist-option-color;
        font-weight: 400;
        border-radius: 4px;
        border: 1px solid transparent;
        &:hover {
            background-color: $color-secondary-2-light;
        }
        &:focus {
            outline: none !important;
            border-color: $color-secondary-2-base;
            background-color: $color-secondary-2-light;
        }
    }
}

/** Old Typeahead support */
.selectfield-typeahead {
    &-background {
        position: absolute;
        background-color: $color-supplement-white-base;
        display: block;
        width: 100%;
        padding: $spacing-5 $spacing-4 $spacing-5 $spacing-5;
        border: 1px solid $color-secondary-2-base;
        top: calc(100% - #{$spacing-2});
        z-index: 1;
        border-bottom-right-radius: $spacing-2;
        border-bottom-left-radius: $spacing-2;
    }
    &-options {
        position: relative;
        display: block;
        left: 0;
        right: 0;
        border-top: 0;
        transition: all 0.5s;
        z-index: 2;
        max-height: 200px;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        &-group {
            padding: 0;
            &-header {
                font-weight: 500;
                margin-bottom: 16px;
            }
        }
        &::-webkit-scrollbar {
            width: $spacing-2;
        }
        &::-webkit-scrollbar-track {
            background-color: $color-secondary-4-base;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-primary-1-base;
        }
        &.no-suggestions {
            color: $color-secondary-4-medium;
            margin: 0;
        }
    }
    &-option {
        position: relative;
        display: block;
        padding: 0 $spacing-7 0 0;
        transition: all 0.5s;
        margin: 0;
        font-size: $size-font-base;
        &:hover {
            background-color: $color-secondary-2-light;
        }
        &:focus {
            outline: none !important;
            border-color: $color-secondary-2-base;
            background-color: $color-secondary-2-light;
        }
    }
}
.selectfield-typeahead-options-group {
    padding: 0;
    .selectfield-typeahead-option {
        padding-left: $spacing-4;
    }
}
/** Old Typeahead support */
