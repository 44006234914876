.flos-pagination {
    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        background-color: transparent;
        display: flex;
        gap: $spacing-3;
        flex-wrap: wrap;
    }

    flos-icon {
        display: block;
    }

    li {
        display: flex;
        margin: 0;
        user-select: none;

        a,
        p,
        span {
            display: flex;
            text-align: center;
            padding: $spacing-4 $spacing-5;
            background-color: transparent;
            color: $color-primary-1-base;
            border-radius: $border-radius-small;
            border: 1px solid $pagination-border-color;
            transition: none;
            line-height: initial;
            min-width: $spacing-9;
            justify-content: center;
            align-items: center;

            &:hover,
            &:focus {
                background-color: $pagination-bg-color--hover;
                color: $pagination-color--hover;
                border: 1px solid $pagination-border-color--hover;
                outline: none !important;
            }
        }

        &.current a,
        &.current span,
        &.is-active a,
        &.is-active span,
        a:active {
            background-color: $pagination-bg-color--active;
            color: $pagination-text-color--active;
            border-color: $pagination-text-color--active;
        }

        &:first-child,
        &:last-child {
            a,
            p,
            span {
                background-color: $pagination-bg-color-arrow;
                color: $color-primary-2-base;
                border-radius: $border-radius-small;
                border: 1px solid $pagination-border-color-arrow;

                &:hover,
                &:focus {
                    background-color: $pagination-bg-color--hover;
                    color: $pagination-color--hover;
                    border: 1px solid $pagination-border-color--hover;
                }
            }
        }
    }

    &--small {
        display: inline-block;
        margin: 0;

        ul {
            & > li {
                a,
                p,
                span {
                    padding: $spacing-3;
                    min-width: $spacing-7;
                    font-size: $size-font-xxsmall;
                    margin: 0;
                }
            }

            & > li:not(:first-child, :last-child) {
                a,
                p,
                span {
                    color: $color-primary-1-base;
                    background-color: transparent;
                    border-color: $pagination-border-color;
                    cursor: not-allowed;
                }
            }
        }
    }

    ul > li.is-disabled {
        a,
        a:hover,
        p,
        p:hover,
        span {
            color: $pagination-color--disabled;
            background-color: $pagination-bg-color--disabled;
            border-color: $color-supplement-grey-base;
            cursor: $cursor-disabled;
            pointer-events: none;
        }

        flos-icon {
            stroke: $color-supplement-grey-medium;
        }
    }

    &:not(.flos-pagination--small) {
        @media (max-width: $screen-sm-max) {
            display: flex;
            align-items: center;
        }

        @media (max-width: $screen-xs-max) {
            li {
                a {
                    padding: $spacing-3;
                    min-width: $spacing-4;
                    box-sizing: content-box;

                    flos-icon {
                        width: $spacing-4 !important;
                    }
                }
            }
        }
    }
}
