.tds-Message {
    padding: 10px 15px;
    border-radius: 2px;
    margin: 15px 0;
    margin: 2vh 0 3vh;
    background-color: $color-supplement-white-base;
    border: 2px solid #777;
    color: $color-secondary-2-base;
    font-weight: 400;
    box-shadow: none;
    display: flex;
    align-items: center;

    p {
        margin: 0 0 0 20px;
    }

    @media (min-width: $screen-md) {
        padding: 25px 30px;
    }
    &--alert {
        border-color: $color-primary-1-base;
    }

    &--success {
        border-color: $color-primary-1-base;
    }
}
