.flos-pageheader {
    position: relative;
    &-row {
        position: relative;
        padding: $spacing-7 $spacing-5;
        max-width: 100%;
    }
    &-label {
        margin-top: 0;
        margin-bottom: $spacing-3;
    }
    &-title {
        margin: 0;
        word-break: break-word;
        max-width: 100%;
    }
    &-subtitle {
        font-weight: 350;
        margin-top: $spacing-3;
        margin-bottom: 0;
    }
    &-nav {
        padding-bottom: $spacing-5;
    }
    &-head-area {
        margin-bottom: $spacing-5;
    }
    &-body {
        margin-top: $spacing-7;
    }

    // classes that will take effect when there is imageUrl
    &-image {
        width: 100%;
        text-align: center;
        padding: 0 0 $spacing-7 0;
    }
    &-image-content {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    &-row--with-image {
        display: flex;
        flex-direction: column;
    }
    &-content-col--with-image {
        width: 100%;
    }

    // class variant based on `theme` prop
    @each $theme, $colors in $themes {
        &-label--#{$theme} {
            color: map-get($colors, text-color);
        }
        &-title--#{$theme} {
            color: map-get($colors, text-color--highlight);
        }
        &-subtitle--#{$theme} {
            color: map-get($colors, text-color);
        }
        &--#{$theme} {
            background-color: map-get($colors, background-color);
        }
    }

    &-head-area,
    &-body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    &--left-aligned {
        text-align: left;
    }
    &--center-aligned {
        text-align: center;
    }
    &--right-aligned {
        text-align: right;
    }
    &-head-area--left-aligned,
    &-body--left-aligned {
        align-items: flex-start;
    }
    &-head-area--center-aligned,
    &-body--center-aligned {
        align-items: center;
    }
    &-head-area--right-aligned,
    &-body--right-aligned {
        align-items: flex-end;
    }
    @media (min-width: $screen-sm) {
        &-content-col--with-image {
            padding-right: $spacing-5;
        }
    }

    @media (min-width: $screen-md) {
        &-row {
            padding: $spacing-11 $spacing-5;
        }
        &-row--with-image {
            flex-direction: row-reverse;
            justify-content: center;
        }
        &-content-col--with-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: $spacing-7;
            width: 60%;
        }
        &-image {
            width: 40%;
            text-align: right;
            padding: 0 $spacing-5 0 $spacing-5;
        }
    }

    @media (min-width: $screen-lg) {
        &-body--with-image {
            padding-right: $spacing-11;
        }
    }
}
