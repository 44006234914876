.flos-package {

    .flos-package-grid-container {
        padding: $spacing-7 0 $spacing-4 0;
        @media (max-width: $screen-sm-max) {
            padding-top: $spacing-5;
            width: 100vw;
            margin-left: -15px;

            .flos-carousel {
                gap: $spacing-5;
                padding: 0 0 $spacing-5 0;

                > div {
                    margin: -$spacing-5 0 $spacing-5 0;
                }
                > div:first-child {
                    margin-left: $spacing-3;
                }
                > div:last-child {
                    margin-right: $spacing-5;
                }
            }
        }
        @media (min-width: $screen-md) {
            .flos-carousel {
                display: contents;
                > div {
                    margin: -16px 0 0 0;
                }
            }
        }
    }

    .flos-package-info-hide {
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    .flos-package-info {

        @media (max-width: $screen-sm-max) {
            margin-bottom: $spacing-5;
            margin-right: $spacing-5;
            &.flos-package-info-no-action {
                display: none;
            }
        }

        .flos-package-info-action {
            @media (max-width: $screen-sm-max) {
                min-height: auto;
                margin: 0 $spacing-5;
            }

            min-height: 200px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;

            .flos-field {
                width: 100%;
            }
        }

        .flos-package-info-legends {
            padding-top: $spacing-5;

            @media (max-width: $screen-sm-max) {
                display: none;
            }

            .List {
                padding: 0px;
            }

            .flos-package-label {
                padding: $spacing-4 $spacing-5;
                border-bottom: 1px solid $flos-coverage-label-border-bottom-color;

                &.flos-package-label-gift {
                    border-radius: $spacing-3;
                    background: $color-primary-1-base;
                    color: $color-supplement-white-base;
                }

                p,
                h6 {
                    margin: 0;
                    padding-right: $spacing-3;
                }
            }
        }
    }

    .flos-package-details {

        @media (max-width: $screen-sm-max) {

            .flos-package-coverage-radio {
                display: none;
            }
        }

        .flos-radio {
            display: flex;
            justify-content: center;
            background-color: unset !important;
            border: unset;
            position: unset;
        }

        .flos-package-coverage {
            border: 1px solid transparent;
            margin: $spacing-5 0 0 0;
            transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            scroll-snap-align: none;
            scroll-snap-stop: normal;
            cursor: pointer;

            @media (max-width: $screen-sm-max) {
                min-width: 270px;
                > ul {
                    max-width: unset;
                }
            }

            button {
                display: none;
            }

            &.active-package {
                border-color: $color-primary-2-base;
                background-color: $color-primary-2-light;
                box-shadow: $shadow-large;
                scroll-snap-align: center;
                scroll-snap-stop: always;

                button {
                    display: unset;
                }
            }

            .flos-package-coverage-action {
                height: 200px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                text-align: center;
                align-items: center;
                padding-top: $spacing-7;

                .flos-radio>.dot {
                    position: relative;
                    top: 0;
                }

                .flos-package-coverage-helpblock {
                    position: absolute;
                    top: -$spacing-5;
                }

                @media (max-width: $screen-sm-max) {
                    height: auto;
                    padding: $spacing-7 0px;
                }
            }

            .flos-package-label {
                padding: $spacing-3 $spacing-5;

                @media (min-width: $screen-md) {
                    justify-content: center;

                    .flos-package-label-mobile {
                        display: none;
                    }
                }

                @media (max-width: $screen-sm-max) {
                    padding: $spacing-3 $spacing-3;

                    &.flos-package-label-gift {
                        border-radius: $spacing-3;
                        background: $color-primary-1-base;
                        color: $color-supplement-white-base;
                    }
                }

            }

            .flos-button {
                width: 100%;
            }
        }
    }

    .flos-package-price {
        .cvi-loading-dots {
            position: relative;
            top: 0;
        }
    }

    .flos-package-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 70px;
        justify-content: space-between;

        p>a {
            color: $flos-coverage-label-link-color;
        }

        flos-icon.is-supplement-grey-medium {
            fill: $non-cover-icon-fill;
            stroke: $color-supplement-white-base;
        }
    }
}