@use 'sass:math';

.flos-range-input {
    position: relative;
    display: inline-block;
    width: 100%;

    &-value {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        font-weight: $formfield-font-weight;

        &--disabled {
            color: $color-supplement-grey-medium;
            cursor: not-allowed;
        }
    }
    &-helptext-row {
        transform: translate3d(0, $spacing-5, 0);
    }

    &-slider {
        margin: math.div($flos-range-input-thumb-height + $spacing-4, 2) 0;
        width: 100%;
        position: relative;
    }
    &::after {
        content: '';
        display: table;
        width: 100%;
        clear: both;
    }
}

input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: $flos-range-input-slider-height;
    background: $flos-range-input-track-color;
    border-radius: 45px;
    background-image: linear-gradient($flos-range-input-progress-color, $flos-range-input-progress-color);
    background-repeat: no-repeat;
    &:disabled {
        background: $flos-range-input-track-color--disabled;
        background-image: linear-gradient($flos-range-input-progress-color--disabled, $flos-range-input-progress-color--disabled);
    }
    :focus {
        border-color: $color-secondary-2-base;
    }
}
/* Input Thumb */
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: $flos-range-input-thumb-height;
    width: $flos-range-input-thumb-height;
    border-radius: $flos-range-input-slider-height;
    border: 1px solid $flos-range-input-progress-color;
    background: $flos-range-input-thumb-color;
    position: relative;
    cursor: pointer;
    transition-property: background transform border-color border-radius;
    transition-duration: 0.15s;
    transition-timing-function: $formfield-checked-timing;
}
input[type='range']:focus::-webkit-slider-thumb:hover {
    transform: scale(0.9);
    background: darken($flos-range-input-thumb-color, 1%);
    border-color: darken($flos-range-input-progress-color, 1);
    transition-timing-function: $formfield-timing-function;
    border-radius: $flos-range-input-slider-height * 1.5;
}

input[type='range']:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
    border-color: $flos-range-input-progress-color--disabled;
}

input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: $flos-range-input-thumb-height;
    width: $flos-range-input-thumb-height;
    border-radius: $flos-range-input-slider-height;
    border: 1px solid $flos-range-input-progress-color;
    background: $flos-range-input-thumb-color;
    cursor: pointer;
    transition-property: background transform border-color border-radius;
    transition-duration: 0.15s;
    transition-timing-function: $formfield-checked-timing;
}
input[type='range']:focus::-moz-range-thumb:hover {
    transform: scale(0.9);
    background: darken($flos-range-input-thumb-color, 1%);
    border-color: darken($flos-range-input-progress-color, 1);
    transition-timing-function: $formfield-timing-function;
    border-radius: $flos-range-input-slider-height * 1.5;
}

input[type='range']:disabled::-moz-range-thumb {
    cursor: not-allowed;
    border-color: $flos-range-input-progress-color--disabled;
}

input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    height: $flos-range-input-thumb-height;
    width: $flos-range-input-thumb-height;
    border-radius: $flos-range-input-slider-height;
    border: 1px solid $flos-range-input-progress-color;
    background: $flos-range-input-thumb-color;
    cursor: pointer;
    transition-property: background transform border-color border-radius;
    transition-duration: 0.15s;
    transition-timing-function: $formfield-checked-timing;
}
input[type='range']:focus::-ms-thumb:hover {
    transform: scale(0.9);
    background: darken($flos-range-input-thumb-color, 1%);
    border-color: darken($flos-range-input-progress-color, 1);
    transition-timing-function: $formfield-timing-function;
    border-radius: $flos-range-input-slider-height * 1.5;
}

input[type='range']:disabled::-ms-thumb {
    cursor: not-allowed;
    border-color: $flos-range-input-progress-color--disabled;
}

/* Input Track */
input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

input[type='range']::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

input[type='range']:focus {
    outline: none;
}
