*,
*::before,
*::after {
    @include box-sizing(border-box);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    -ms-overflow-style: scrollbar;
    word-wrap: break-word;
    font-family: $font-family-base;
    font-size: $size-font-base;
    line-height: $size-lineheight-base;
    color: $color-primary-1-dark;
    background-color: $color-supplement-grey-light;
}

::-moz-focus-inner {
    border: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-supplement-black-base;
}

[hidden] {
    display: none !important;
}

a,
button,
[role='button'] {
    cursor: pointer;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

input[type='number'] {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

hr {
    margin: 0 0 $spacing-5;
    border: 0;
    border-top: 1px solid $color-supplement-grey-base;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for 'Skip to main content' links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate
.sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}
