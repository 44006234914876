.flos-toggle {
    position: relative;
    display: inline-block;
    width: $spacing-11;
    height: $spacing-7;
    margin-bottom: 0;
}

.flos-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.flos-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $flos-toggle-slider-bg-color;
    transition: 0.25s;
    border-radius: $spacing-7;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.25);
}

.flos-toggle-slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    border-radius: $spacing-7;
    left: 3px;
    bottom: 3px;
    background-color: $color-supplement-white-base;
    transition: 0.25s;
    box-shadow: $shadow-small;
}

input:checked + .flos-toggle-slider {
    background-color: $flos-toggle-slider-bg-color--checked;
}

input:checked + .flos-toggle-slider:before {
    transform: translateX($spacing-7);
}
