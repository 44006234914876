.Box--usp {
    @extend .Box--link;

    &:hover,
    &:focus,
    &:active {
        background: $color-secondary-4-base;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-selected {
        color: $color-secondary-2-base;

        & > .Box-header {
            color: $color-primary-1-base;
            stroke: $color-primary-1-base;
        }
    }

    &.is-selected {
        background: $color-secondary-4-base;
    }

    .Box-header {
        margin-bottom: 10px;

        a {
            color: inherit;
        }
    }

    &-placeholderLink {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
    }

    &-note {
        position: relative;
        z-index: 100;
        font-size: $size-font-xxsmall;
    }
    ul {
        list-style: none;
        padding-left: initial;
    }
}
