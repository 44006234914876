.loading-bar-root {
    position: relative;
    overflow: hidden;
    background: $color-supplement-grey-base;
    border-radius: $spacing-7;
    height: $spacing-3;
    transform: translateZ(0);
}

.loading-bar-indicator {
    background-color: $color-primary-2-base;
    border-radius: $spacing-7;
    width: 100%;
    height: 100%;
    transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}