.profile {
    hr {
        color: $color-supplement-grey-light;
    }

    .buttonrow {
        margin-top: 20px;
        @include media-match(mobile) {
            text-align: center;
        }
    }

    .btn {
        &__fixed {
            overflow: hidden;
            width: 180px;
            display: inline-block;
        }
    }

    .row {
        margin-bottom: 10px;
    }

    .form-group {
        .row {
            margin-bottom: 0;
        }
    }
    .cancellink {
        padding-left: 30px;
        @include media-match(mobile) {
            width: 180px;
            padding-left: 0;
            padding-top: 10px;
            display: inline-block;
        }
    }

    .col-xs-12 {
        &__top {
            @include media-match(mobile) {
                margin-top: 5px;
            }
        }
    }
}
