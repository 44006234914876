$breakpoints: (
    'xs': '(min-width: ' + $screen-xs + ')',
    'sm': '(min-width: ' + $screen-sm + ')',
    'md': '(min-width: ' + $screen-md + ')',
    'lg': '(min-width: ' + $screen-lg + ')',
    'xl': '(min-width: ' + $screen-xl + ')',
    'print': 'print',
);
@mixin create-mq($withContent: true , $breakpoint: null) {
    @if $breakpoint != null and map-get($breakpoints, $breakpoint) != null {
        $media-params: unquote(map-get($breakpoints, $breakpoint));
        @if $withContent {
            @content;
        }
        @media #{$media-params} {
            &\@#{$breakpoint} {
                @content;
            }
        }
    } @else {
        @if $withContent {
            @content;
        }
        @each $key, $val in $breakpoints {
            $media-params: unquote($val);
            @media #{$media-params} {
                &\@#{$key} {
                    @content;
                }
            }
        }
    }
}
