.flos-pill {
    background: $color-primary-1-base;
    border-radius: $spacing-3;
    padding: $spacing-3 $spacing-4;
    color: $color-supplement-white-base;
    display: inline-block;

    &--secondary {
        background: $color-supplement-white-base;
        border: 1px solid $color-primary-1-base;
        color: $color-primary-1-base;
    }

    &--warning {
        background: $color-supplement-warning-light;
        color: $color-supplement-warning-dark;
    }

    p {
        margin: 0px;
    }
}
