.Box--has-label {
    padding-top: 7px;
}

.Box--selectable {
    display: block;
    text-align: center;
    transition: 0.4s;
    cursor: pointer;
    font-size: $size-font-small;
    font-weight: 300;
    word-break: break-word;
    width: 100%;

    &.Box--disabled {
        background-color: $color-secondary-4-light;
        cursor: not-allowed;
        .Box-header {
            color: $color-supplement-white-base;
        }
    }

    &,
    .Box-header a {
        transition: background-color 0.2s, color 0.2s;
    }

    .Box-header {
        margin: 0;
        font-size: $size-font-small;
    }

    &:not(.Box--disabled) {
        &:focus,
        &:hover,
        &:active {
            color: $color-supplement-white-base;
            background-color: $color-primary-1-base;
            .Box-header {
                color: $color-supplement-white-base;
            }
        }

        input[type='checkbox']:checked + &,
        &.is-active:nth-child(1):not(.is-active) {
            color: $color-supplement-white-base;
            background-color: $color-secondary-2-base;
            .Box-header {
                color: $color-supplement-white-base;
            }
            &:focus,
            &:hover,
            &:active {
                background-color: $color-primary-1-base;
            }
        }
    }
}
