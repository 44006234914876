@use 'sass:math';

$stroke-width: 1px;
$field-color: $color-secondary-2-dark;
$accent-color: $color-primary-2-base;

.flos-quantity-field {
    align-items: center;
    border-radius: $spacing-2;
    border: $stroke-width solid $color-secondary-4-base;
    color: $field-color;
    display: flex;
    gap: $spacing-4;
    justify-content: center;
    margin: auto;
    padding: $spacing-5;
    transition: all 0.35ms ease-out;
    font-weight: 700;

    &:focus,
    &:hover {
        outline: none;
        border-color: $accent-color;
    }

    &-quantity {
        text-align: center;
        display: inline-block;
        width: $spacing-7; // Fit for 3 digits.
        white-space: nowrap;
    }

    &-button {
        all: unset;
        border-radius: math.div($spacing-6, 2);
        border: $stroke-width solid $accent-color;
        height: $spacing-6;
        width: $spacing-6;

        &--plus,
        &--minus {
            user-select: none;
            color: rgba(0, 0, 0, 0);
            position: relative;

            &::before,
            &::after {
                content: '';
                background-color: $field-color;
                display: inline-block;
                height: $stroke-width;
                width: $spacing-3;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &--plus::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }

        &--pressed {
            background-color: rgba($accent-color, 0.1);
        }
    }
}
