.flos-splash {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 50%;
    background-color: $color-primary-1-base;
    width: 260px;
    height: 260px;
    text-align: center;
    line-height: 21px;
    z-index: 80;
    outline: 0;
    box-shadow: 0 16px 32px 0 rgb(0 0 0 / 30%);
    color: $color-supplement-white-base;

    h3,
    p {
        margin: 0;
    }

    @media (max-width: $screen-sm-max) {
        width: 220px;
        height: 220px;
    }
}
