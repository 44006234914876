$active-color: $color-primary-2-base;
$bg-color: $color-secondary-4-base;
$dot-size: 30px;

.flos-progressbar-dots {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: center;
    width: auto;
    max-width: 100%;
    position: relative;
}

.flos-progressbar-dot {
    background-color: $bg-color;
    display: inline-block;
    height: $spacing-2;
    width: $dot-size;

    &:first-child {
        border-radius: 2px 0 0 2px;
    }
    &:last-child {
        border-radius: 0 2px 2px 0;
    }
    &--active {
        background-color: $active-color;
    }

    @media screen and (min-width: $screen-xs) {
        width: $dot-size * 2;
    }
}
