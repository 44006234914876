/*
**	Anchor links.
**	Add space to the top of '.csc-default' elements, to prevent the fixed header from overlaying the target element.
*/
.main > .csc-default {
    margin-top: -50px;
    padding-top: 50px;

    @media (min-width: $screen-sm) {
        margin-top: -90px;
        padding-top: 90px;
    }
}

.Note {
    color: $color-secondary-2-base;
    font-style: normal;

    &:hover,
    &:focus {
        color: $color-primary-1-base;
    }
}

.Serp {
    &-form {
        display: flex;

        .form-control {
            margin-right: 15px;
        }
    }

    &-results {
        list-style: none;
        padding: 0;
        margin: 2vh 0;
    }

    &Item {
        margin: 3vh 0;

        mark,
        .tx-indexedsearch-redMarkup {
            font-weight: 300;
            background: #ebebeb;
            padding: 0 2px;
        }

        &-title {
            margin-bottom: 5px;
        }

        &-extract {
            margin-bottom: 5px;
        }
    }
}

nav.main-navbar.app {
    &--ios {
        padding-top: 10px;
    }
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

.Vert-Top {
    vertical-align: top !important;
}

.positive-number {
    color: $color-supplement-success-base;
}

.negative-number {
    color: $color-supplement-error-base;
}

.front-page--brand {
    .main {
        background-color: $color-primary-2-base;
    }
}

.form-group--separator {
    border-bottom: 1px solid #ccc;
}
