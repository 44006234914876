li.tds-address-list-item.ui-menu-item.focused {
    background-color: lightgrey;
}

.ui-widget-content {
    border: 1px solid #dddddd;
    background: #ffffff;
    color: #333333;
}
.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
}
.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: 0;
}
.ui-menu .ui-menu {
    position: absolute;
}
.ui-menu .ui-menu-item {
    margin: 0;
    cursor: pointer;
    /* support: IE10, see #8844 */
    list-style-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
}
.ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px 0.4em;
}
.ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-width: 1px 0 0 0;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
    margin: -1px;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: #ffffff;
}
