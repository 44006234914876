.flos-counter {
    display: inline-block;
    width: 144px;
    height: 144px;
    border: 1px solid $color-secondary-4-base;
    border-radius: 50%;
    text-align: center;
    font-size: $size-font-xlarge;
    line-height: $size-lineheight-xxlarge;
    padding-top: $spacing-8;

    @media (max-width: $screen-sm-max) {
        width: $spacing-12;
        height: $spacing-12;
        font-size: $size-font-medium;
        line-height: $size-lineheight-large;
        padding-top: $spacing-6;
    }
}
