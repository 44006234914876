$formfield-font-weight--focus: 400;
$formfield-font-weight: 350;
$formfield-label-color: $color-primary-2-dark;
$formfield-input-color--valid: $color-secondary-4-base;
$formfield-input-color--invalid: $color-supplement-error-base;
$formfield-input-color--disabled: $formfield-input-color--valid;
$formfield-input-color: $color-primary-2-base;
$formfield-input-bgcolor--disabled: lighten($formfield-input-color--valid, 30%);
$formfield-input-bgcolor: $color-supplement-white-base;
$formfield-input-border-radius: 4px;
$formfield-input-bordercolor--active: $formfield-input-color--valid;
$formfield-input-bordercolor--disabled: $formfield-input-color--valid;
$formfield-input-bordercolor--invalid: $color-supplement-error-base;
$formfield-input-bordercolor--valid: $formfield-input-color--valid;
$formfield-input-bordercolor: $color-primary-2-light;
$formfield-timing-function: cubic-bezier(0, 0, 0.2, 1);
$formfield-transition-duration: 0.2s;
$formfield-icon-size: $spacing-5;

$formfield-checked-bgcolor: $formfield-input-bgcolor;
$formfield-checked-color: $color-primary-1-base;
$formfield-checked-timing: cubic-bezier(0.18, 0.89, 0.32, 1.28);
$formfield-checked-duration: 0.1s;
$formfield-cursor-disabled: not-allowed;

@import './form-fields';
