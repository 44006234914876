.Section {
    background-color: $color-supplement-grey-light;
    padding: $spacing-11 0 $spacing-11;

    @media (max-width: $screen-sm-max) {
        padding: $spacing-7 0 $spacing-7;
    }

    // By default highlighted section always follows not highlighted => it should have large top/bottom padding.
    // Also adds grey borders in top/bottom to make sure its predecessor and successor having visually large padding as well
    &--highlight {
        background-color: $section-highlight-bg-color;
        padding: $spacing-13 0 $spacing-13;
        border-top: $spacing-11 solid $color-supplement-grey-light;
        border-bottom: $spacing-11 solid $color-supplement-grey-light;

        @media (max-width: $screen-sm-max) {
            padding: $spacing-11 0 $spacing-11;
            border-width: $spacing-7;
        }
        &-last-item {
            border-bottom: 0;
        }
    }

    &--secondary {
        background-color: $color-supplement-white-base;
    }

    &--compact {
        padding: $spacing-7 0 $spacing-7;
        @media (max-width: $screen-sm-max) {
            padding: $spacing-5 0 $spacing-5;
        }
    }

    &--no-space-below {
        padding-bottom: 0;
    }

    &--no-space-above {
        padding-top: 0;
    }

    &--no-spacing {
        padding: 0;
    }

    &[id] {
        scroll-margin-top: 100px;
    }

    > .container {
        > :last-child {
            margin-bottom: 0;
        }
    }

    > .container > .row > [class*='col-'] > div:last-child {
        margin-bottom: 0;
    }

    /*
	**	Other variants
	*/
    // Used in Banner components in BrXm (cta-banner.ftl) and in old <Section/> component (legacy)
    &--highlight-dark {
        color: $color-supplement-white-base;
        background: $color-primary-1-base;
    }

    // used on topdanmark.dk frontpage (BrXm)
    &--spots {
        h3 {
            margin-top: 0;
        }

        ~ .Section--border {
            border-top: 0;
        }

        position: relative;
        padding: 0;

        .container {
            @media (min-width: $screen-lg) and (max-width: 1400px) {
                width: auto;
            }

            .row {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .Spot {
            padding: 50px 60px;
            padding: 7vmin 15px;
            width: 100%;

            @media (min-width: 1200px) and (max-width: 1400px) {
                .ArticleSpot-content {
                    max-width: 540px;
                }
            }

            @media (min-width: $screen-lg) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        [class*='col-'] {
            padding: 0;
            overflow: hidden;
            margin-left: 0;

            &:first-child {
                .ArticleSpot:not(.ArticleSpot--bg) {
                    padding-left: 15px;
                }

                .ArticleSpot-content {
                    margin-left: auto;
                }
            }

            &:first-child {
                .ArticleSpot--bg {
                    @media (min-width: 1400px) {
                        margin-left: 15px;
                    }
                }
            }

            &:last-child {
                .ArticleSpot--bg {
                    @media (min-width: 1400px) {
                        margin-right: 15px;
                    }
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            [class*='col-']:first-child {
                border-bottom: 1px solid #d7d7d7;
            }
        }
    }
}

.Section-header {
    margin-bottom: $spacing-7;

    > h2 {
        margin-bottom: 0;
    }

    > p {
        margin-top: $spacing-3;
        margin-bottom: 0;
    }
}

// Used in PageHeader comp.
.Section-background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
