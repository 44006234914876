@use 'sass:math';

$flos-table-color: $color-supplement-white-base !default;
$flos-table-border-color: $color-supplement-grey-base !default;
$flos-table-border-radius: $spacing-3 !default;
$flos-table-base-font-size: 16px !default;
$flos-cell-font-size: 1em !default;
$flos-cell-padding: $spacing-5 !default;
$flos-header-font-size: 0.75em !default;
$flos-table-border: 1px solid $flos-table-border-color;
$flos-table-border--invisible: 0 solid transparent;

.flos {
    &-table-container {
        border-radius: $flos-table-border-radius;
        border: $flos-table-border;
        display: inline-block;
        position: relative;
        width: 100%;
        font-size: $flos-table-base-font-size;
        font-weight: 300;
        max-width: 100%;
        background-color: $flos-table-color;
        overflow: hidden;
    }
    &-table-container:has(&-table--cell-vertical) {
        border: $flos-table-border--invisible;
        background-color: transparent;
    }

    &-table-scroller {
        overflow-x: auto;
        overflow-y: hidden;
        border-radius: $flos-table-border-radius;
    }
    &-table {
        border-collapse: collapse;

        &-row:not(:last-child) &-cell {
            border-bottom: $flos-table-border;
        }
        &-head &-row:last-child &-cell {
            border-bottom: $flos-table-border;
        }
        // providing gap between row in mobile view
        &-row::after {
            content: '';
            display: block;
            height: $flos-cell-padding;
        }
        &--wrapped &-cell {
            white-space: normal;
        }
        &-cell {
            background-color: $flos-table-color !important;
            border: $flos-table-border--invisible;
            font-size: $flos-cell-font-size;
            padding: $flos-cell-padding;
            position: relative;
            width: 100%;
            white-space: nowrap;

            &[align='left'] {
                text-align: left !important;
            }
            &[align='center'] {
                text-align: center !important;
            }
            &[align='right'] {
                text-align: right !important;
            }

            &--head {
                font-size: $flos-header-font-size;
                font-weight: 400;
                &[scope='col'] {
                    font-size: $flos-header-font-size;
                }
                &[scope='row'] {
                    font-size: $flos-cell-font-size;
                }
            }
        }

        /** <---------- Vertical styling ----------> */
        &--cell-vertical &-row:last-child &-cell {
            border-bottom: $flos-table-border;
        }
        &--cell-vertical & {
            &-row &-cell:first-child {
                border-top: $flos-table-border;
            }
            &-cell {
                display: inline-block;
                width: 100%;
                border-left: $flos-table-border;
                border-right: $flos-table-border;

                &[data-header] {
                    padding-top: $spacing-8;
                    padding-bottom: $spacing-4;

                    &::before {
                        content: attr(data-header);
                        font-size: $flos-header-font-size;
                        font-weight: 400;
                        font-size: $flos-header-font-size;
                        display: inline-block;
                        position: absolute;
                        top: $spacing-4;
                        left: $flos-cell-padding;
                    }
                }
                &:first-child {
                    border-top: $flos-table-border;
                    border-top-left-radius: $flos-table-border-radius;
                    border-top-right-radius: $flos-table-border-radius;
                }
                &:last-child {
                    border-bottom-left-radius: $flos-table-border-radius;
                    border-bottom-right-radius: $flos-table-border-radius;
                }
            }
        }
    }
}
@media (max-width: ($screen-xs - 1)) {
    .flos-table--cell-vertical {
        .flos-table-head {
            // on mobile view it will be hidden
            @include visually-hidden();
        }
    }
}
@media (min-width: $screen-xs) {
    .flos {
        &-table-container {
            border: $flos-table-border;
        }
        &-table-container:has(&-table--cell-vertical) {
            border: $flos-table-border;
        }
        &-table {
            border-collapse: collapse;
            width: 100%;

            &--wrapped {
                table-layout: fixed;
            }

            &-row::after {
                content: none;
            }
            &-body &-row:last-child &-cell {
                border-bottom: $flos-table-border--invisible;
            }
            &-cell {
                width: unset;
            }

            /** <---------- Vertical styling ----------> */
            &--cell-vertical &-row:last-child &-cell:only-child {
                border-bottom: $flos-table-border--invisible;
            }
            &--cell-vertical & {
                &-cell {
                    display: table-cell;
                    width: unset;
                    border-left: $flos-table-border--invisible;
                    border-right: $flos-table-border--invisible;

                    &:first-child {
                        border-top: $flos-table-border--invisible;
                        border-top-left-radius: unset;
                        border-top-right-radius: unset;
                    }
                    &:last-child {
                        border-bottom-left-radius: unset;
                        border-bottom-right-radius: unset;
                    }

                    &[data-header] {
                        padding-top: $flos-cell-padding;
                        padding-bottom: $flos-cell-padding;
                        &::before {
                            content: none;
                        }
                    }
                }
                &-head {
                    position: unset;
                    clip: unset;
                    padding: unset;
                    border: unset;
                    height: unset;
                    width: unset;
                    overflow: unset;
                }
            }
        }
    }
}

.flos-table-scroller {
    // Scrolling Styles
    // Firefox
    & {
        scrollbar-width: $flos-table-border-radius;
        scrollbar-color: #8c8c8c $flos-table-color;
    }

    // Chrome, Edge and Safari
    &::-webkit-scrollbar {
        height: $flos-table-border-radius;
        width: $flos-table-border-radius;
    }
    &::-webkit-scrollbar-track {
        background-color: $flos-table-color;
        border-bottom-left-radius: $flos-table-border-radius;
        border-bottom-right-radius: $flos-table-border-radius;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: $flos-table-color;
    }

    &::-webkit-scrollbar-track:active {
        background-color: $flos-table-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-medium;
        border-radius: math.div($flos-table-border-radius, 2);
        border: math.div($flos-table-border-radius, 4) solid $flos-table-color;
        transition: background-color 0.5s ease-out;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $color-supplement-grey-dark;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: $color-supplement-grey-dark;
    }
}
.flos-table-container--is-grey {
    background-color: $color-supplement-grey-light;
    .flos-table-cell {
        background-color: $color-supplement-grey-light !important;
    }
    .flos-table-scroller {
        & {
            scrollbar-color: #8c8c8c $color-supplement-grey-light;
        }
        &::-webkit-scrollbar-track {
            background-color: $color-supplement-grey-light;
        }

        &::-webkit-scrollbar-track:hover {
            background-color: $color-supplement-grey-light;
        }

        &::-webkit-scrollbar-track:active {
            background-color: $color-supplement-grey-light;
        }

        &::-webkit-scrollbar-thumb {
            border: math.div($flos-table-border-radius, 4) solid $color-supplement-grey-light;
        }
    }
}
