.tds-Coverage {
    padding: 0;
    box-shadow: none;
    margin: 12px 0;

    a:focus {
        outline: 0 !important;
        outline-style: none;
        -moz-outline-style: none;
    }
}

.tds-Coverage-header {
    color: $color-supplement-white-base;
    background-color: $color-primary-1-base;
    transition: all 0.2s;

    box-shadow: none;
    padding: 16px 40px 16px 16px;
    position: relative;
    display: table;
    width: 100%;

    &.collapsed {
        background-color: $color-supplement-white-base;
        border-bottom: 1px solid $color-primary-1-base;
        color: $color-primary-1-base;

        .tds-Coverage-subtitle {
            color: $color-secondary-2-base;
        }

        &::before,
        &::after {
            background: $color-primary-1-base;
        }

        &::after {
            transform: scale(1);
        }
        &:hover,
        &:active,
        &:focus {
            background-color: $color-secondary-4-base;
            color: $color-primary-1-base;
        }
    }
    &:hover,
    &:active,
    &:focus {
        color: $color-supplement-white-base;
        background-color: $color-primary-1-base;
    }

    p {
        margin-bottom: 0;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        background: $color-supplement-white-base;
    }

    &::before {
        width: 16px;
        height: 2px;
        right: 24px;
        top: 24px;
    }

    &::after {
        width: 2px;
        height: 16px;
        top: 17px;
        right: 31px;
        transition: transform 0.3s;
        transform: scale(0);
    }
}

.tds-Coverage-title {
    opacity: 1;
    font-size: $size-font-small;
    line-height: $size-lineheight-medium;
    font-weight: normal;
    word-break: break-word;
    text-transform: uppercase;
    &--alt {
        margin-top: 30px;
    }
}

.tds-Coverage-subtitle {
    text-align: left;
    font-size: $size-font-small;
    opacity: 1;
}

.tds-Coverage-titlegroup {
    padding: 0;
}

.tds-Coverage-content {
    color: $color-primary-1-base;
    font-weight: 400;
    p {
        color: $color-secondary-2-base;
        max-width: none;
        margin-left: -30px;
    }

    .tds-Coverage-list:first-of-type {
        border-bottom: 1px dotted $color-secondary-2-base;
        padding-left: 26px;
        margin-left: -26px;
    }
}

.Box .tds-Coverage-content .Section {
    padding-left: 46px;
    padding-top: 20px;
    padding-right: 52px;
    background-color: #fff;
}

.tds-Coverage-list {
    list-style: none;
    padding: 15px 15px 15px 0;
    color: $color-secondary-2-base;

    @media (min-width: $screen-md) {
        padding: 15px 20px 15px 0;
    }
    &item {
        position: relative;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.tds-Coverage-icon {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    border: 1px solid transparent;
    position: absolute;
    top: 12px;
    transform: translate(-50%, -50%);
    margin-left: -16px;
    svg {
        stroke-width: 1.5px;
    }
    &--included {
        stroke: $color-supplement-success-base;
    }

    &--excluded {
        stroke: $color-supplement-error-base;
    }
}
/**
	Overriding styles for the inline coverage item component
*/
.tds-Coverage {
    $tdsCoverage: &;
    &--inline {
        #{$tdsCoverage}-header {
            border-radius: 0;
            border-bottom: 1px solid $color-primary-1-base;
            min-height: inherit;
            &.collapsed {
                border-radius: 0;
                box-shadow: none;
                &:hover,
                &:active {
                    color: $color-primary-1-base;
                    background-color: $color-secondary-4-base;
                }
            }

            &:focus {
                outline: 0 !important;
                outline-style: none;
                -moz-outline-style: none;
            }
        }

        #{$tdsCoverage}-icon {
            width: 20px;
            height: 20px;
            &:after {
                width: 11px;
                height: 11px;
            }
            & ~ #{$tdsCoverage}-titlegroup {
                padding-left: 35px;
            }
        }
        #{$tdsCoverage}-content {
            font-size: $size-font-small;
            padding: 20px 40px 20px 20px;
            border: none;
            .Section--alt {
                padding: 5px 10px;
                margin-bottom: 20px;
            }
            p {
                margin-left: 0;
            }
        }
        #{$tdsCoverage}-list {
            padding: 10px 15px 5px 0;
            margin-bottom: 0;
            font-size: $size-font-small;
            &item {
                margin-bottom: 8px;
            }
        }
        #{$tdsCoverage}-listitem {
            flos-icon {
                stroke-width: 1.5px;
                &[shape='check'] {
                    stroke: $color-supplement-success-base;
                }
                &[shape='circle-x'] {
                    stroke: $color-supplement-error-base;
                }
            }
        }
    }
}
