@use 'sass:math';
// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns($i: 1, $list: '.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}') {
    @for $i from (1 + 1) through $grid-columns {
        $list: '#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}';
    }
    #{$list} {
        position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-right: floor(math.div($grid-gutter-width, 2));
        padding-left: ceil(math.div($grid-gutter-width, 2));
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: '.col-#{$class}-#{$i}') {
    @for $i from (1 + 1) through $grid-columns {
        $list: '#{$list}, .col-#{$class}-#{$i}';
    }
    #{$list} {
        float: left;
    }
}

@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(math.div($index, $grid-columns));
        }
    }
    @if ($type == offset) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(math.div($index, $grid-columns));
        }
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

// Create grid for specific class
@mixin make-grid($class) {
    @include float-grid-columns($class);
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, offset);
}
