.tooltip-labeled {
    display: flex;
    align-items: center;
    gap: $spacing-5;

    &-text {
        margin-bottom: 0;
        color: $tooltip-labeled-label-color;
    }

    &-button {
        background-color: transparent;
        padding: 0;
        border: none;
    }
}
