.flos-custom-select {
    &-trigger {
        background: $flos-custom-select-button-background-color;
        border-color: #cad7e0;
        border-radius: $spacing-2;
        border-style: solid;
        border-width: 1px;
        color: $flos-custom-select-button-text-color;
        font-weight: 400;
        margin: 0;
        padding: $spacing-6 $spacing-5 $spacing-3 $spacing-5;
        transition: border-color 0.2s cubic-bezier(0, 0, 0.2, 1);
        width: 100%;
        position: relative;
        text-align: left;

        display: flex;
        gap: $spacing-3;
        justify-content: space-between;

        &[data-state='open'] {
            border-color: $flos-custom-select-inner-border-color;
            background: $color-supplement-white-base;

            & ~ flos-icon[shape='arrow-down'] {
                transform: translate3d(0, -50%, 0) rotate(180deg);            
            }

        }
        &[disabled] {
            padding: $spacing-6 $spacing-5 $spacing-3 $spacing-5;
            background-color: $color-supplement-grey-light;

        }
        &:hover {
            background: $flos-custom-select-button-background-color--hover;
            color: $flos-custom-select-button-text-color;
        }
        &--no-label {
            padding: calc($spacing-4 - 1px) calc($spacing-5 - 1px)
        }
    }

    &-outer {
        width: var(--radix-select-trigger-width);
        max-height: var(--radix-select-content-available-height);
        border: 1px solid $flos-custom-select-inner-border-color;
        background: $color-supplement-white-base;
        padding: $spacing-5;
        z-index: 1000;

        &[data-side='bottom'] {
            margin-top: -3px;
            border-top: 0;
            border-radius: 0 0 $border-radius-medium $border-radius-medium;
            box-shadow: $shadow-large;
            clip-path: inset(0 -20px -40px -20px);
        }

        &[data-side='top'] {
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            border-bottom: 0;
            margin-bottom: -3px;
            box-shadow: $shadow-large;
            clip-path: inset(-20px -20px 0 -20px);
        }

    }

    &-item {
        border-radius: $border-radius-small;
        padding: $spacing-3;
        margin-bottom: 0;
        cursor: pointer;
        outline: none;
        font-size: $size-font-xsmall;
        font-weight: 400;
        line-height: $size-lineheight-medium;
        text-align: left;
        font-family: $font-family-base;
        letter-spacing: normal;
        text-transform: none;

        &:hover {
            background-color: $color-secondary-2-light;
        }

        &:active,
        &:focus,
        &[aria-selected='true'] {
            color: $flos-custom-select-list-text-color--selected;
        }
    }

    &-scroll-area {
        max-height: 400px;
        overflow: hidden;

        &-viewport {
            width: 100%;
            max-height: 400px;
        }
        &-scrollbar {
            width: $spacing-3;
            padding: 5px 2px;
            background-color: $color-supplement-grey-base;
        }
        &-thumb {
            background: $color-primary-1-base;
            border-radius: $spacing-3;            
        }
    }
}