.flos-consent-iframe {
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    width: 100%;
    border: 2px dashed $color-secondary-4-base;
    background-color: #fff;
    border-radius: $spacing-3;

    & > iframe,
    & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    & iframe {
        display: none;
        box-shadow: $shadow-small;
        border-radius: $spacing-3;
    }

    & .msg {
        text-align: center;
        font-size: $size-font-small;
        font-weight: 700;
        max-width: 450px;
        padding-top: 18%;
        z-index: 100;

        & p {
            max-width: none;
            padding: 0 15px;
        }
    }

    &.in {
        border: 0;
        & .msg {
            display: none;
        }
        & iframe {
            display: block;
        }
    }
}

/* Special rules for Hero elements */
.Hero--bg-dark .flos-consent-iframe .msg,
.Hero--bg-primary-alt .flos-consent-iframe .msg,
.Hero--bg-default .flos-consent-iframe .msg {
    color: white;
}
