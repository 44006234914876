.flos-flow-menu {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-snap-type: x mandatory;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.15));

    a:focus {
        outline: none !important;
    }

    & > a.flos-flow-menu-item {
        width: 100%;
        position: relative;
        background-color: $color-supplement-white-base;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: $spacing-5;
        margin-bottom: $spacing-5;
        text-align: center;
        padding: 10px;

        max-height: 100px;
        max-width: 117px;
        min-height: 100px;
        min-width: 117px;

        @media (min-width: 992px) {
            max-height: 120px;
            max-width: 184px;
            min-height: 120px;
            min-width: 184px;
        }

        clip-path: polygon(
            /* upper left corner start */ 0px 5px,
            1px 5px,
            1px 3px,
            2px 3px,
            2px 2px,
            3px 2px,
            3px 1px,
            4px 1px,
            5px 1px,
            5px 0px,
            /* upper left corner end */ /* upper right corner start */ calc(100% - 12px) 0px,
            calc(100% - 12px) 1px,
            calc(100% - 10px) 1px,
            calc(100% - 10px) 2px,
            calc(100% - 9px) 2px,
            calc(100% - 9px) 3px,
            calc(100% - 8px) 3px,
            calc(100% - 8px) 4px,
            calc(100% - 8px) 5px,
            calc(100% - 7px) 5px,
            /* upper right corner end */ calc(100% - 7px) calc(50% - 10px),
            100% 50%,
            calc(100% - 7px) calc(50% + 10px),
            /* lower right corner start */ calc(100% - 7px) calc(100% - 5px),
            calc(100% - 8px) calc(100% - 5px),
            calc(100% - 8px) calc(100% - 3px),
            calc(100% - 9px) calc(100% - 3px),
            calc(100% - 9px) calc(100% - 2px),
            calc(100% - 10px) calc(100% - 2px),
            calc(100% - 10px) calc(100% - 1px),
            calc(100% - 11px) calc(100% - 1px),
            calc(100% - 12px) calc(100% - 1px),
            calc(100% - 12px) 100%,
            /* lower right corner end */ /* lower left corner start */ 5px 100%,
            5px calc(100% - 1px),
            3px calc(100% - 1px),
            3px calc(100% - 2px),
            2px calc(100% - 2px),
            2px calc(100% - 3px),
            1px calc(100% - 3px),
            1px calc(100% - 4px),
            1px calc(100% - 5px),
            0px calc(100% - 5px),
            /* lower left corner end */ 0% calc(50% + 10px),
            7px 50%,
            0% calc(50% - 10px)
        );

        &:first-child {
            clip-path: polygon(
                /* upper left corner start */ 0px 5px,
                1px 5px,
                1px 3px,
                2px 3px,
                2px 2px,
                3px 2px,
                3px 1px,
                4px 1px,
                5px 1px,
                5px 0px,
                /* upper left corner end */ /* upper right corner start */ calc(100% - 12px) 0px,
                calc(100% - 12px) 1px,
                calc(100% - 10px) 1px,
                calc(100% - 10px) 2px,
                calc(100% - 9px) 2px,
                calc(100% - 9px) 3px,
                calc(100% - 8px) 3px,
                calc(100% - 8px) 4px,
                calc(100% - 8px) 5px,
                calc(100% - 7px) 5px,
                /* upper right corner end */ calc(100% - 7px) calc(50% - 10px),
                100% 50%,
                calc(100% - 7px) calc(50% + 10px),
                /* lower right corner start */ calc(100% - 7px) calc(100% - 5px),
                calc(100% - 8px) calc(100% - 5px),
                calc(100% - 8px) calc(100% - 3px),
                calc(100% - 9px) calc(100% - 3px),
                calc(100% - 9px) calc(100% - 2px),
                calc(100% - 10px) calc(100% - 2px),
                calc(100% - 10px) calc(100% - 1px),
                calc(100% - 11px) calc(100% - 1px),
                calc(100% - 12px) calc(100% - 1px),
                calc(100% - 12px) 100%,
                /* lower right corner end */ /* lower left corner start */ 5px 100%,
                5px calc(100% - 1px),
                3px calc(100% - 1px),
                3px calc(100% - 2px),
                2px calc(100% - 2px),
                2px calc(100% - 3px),
                1px calc(100% - 3px),
                1px calc(100% - 4px),
                1px calc(100% - 5px),
                0px calc(100% - 5px),
                /* lower left corner end */
            );
        }

        &:last-child {
            clip-path: polygon(
                /* upper left corner start */ 0px 5px,
                1px 5px,
                1px 3px,
                2px 3px,
                2px 2px,
                3px 2px,
                3px 1px,
                4px 1px,
                5px 1px,
                5px 0px,
                /* upper left corner end */ /* upper right corner start */ calc(100% - 12px) 0px,
                calc(100% - 12px) 1px,
                calc(100% - 10px) 1px,
                calc(100% - 10px) 2px,
                calc(100% - 9px) 2px,
                calc(100% - 9px) 3px,
                calc(100% - 8px) 3px,
                calc(100% - 8px) 4px,
                calc(100% - 8px) 5px,
                calc(100% - 7px) 5px,
                /* upper right corner end */ /* lower right corner start */ calc(100% - 7px) calc(100% - 5px),
                calc(100% - 8px) calc(100% - 5px),
                calc(100% - 8px) calc(100% - 3px),
                calc(100% - 9px) calc(100% - 3px),
                calc(100% - 9px) calc(100% - 2px),
                calc(100% - 10px) calc(100% - 2px),
                calc(100% - 10px) calc(100% - 1px),
                calc(100% - 11px) calc(100% - 1px),
                calc(100% - 12px) calc(100% - 1px),
                calc(100% - 12px) 100%,
                /* lower right corner end */ /* lower left corner start */ 5px 100%,
                5px calc(100% - 1px),
                3px calc(100% - 1px),
                3px calc(100% - 2px),
                2px calc(100% - 2px),
                2px calc(100% - 3px),
                1px calc(100% - 3px),
                1px calc(100% - 4px),
                1px calc(100% - 5px),
                0px calc(100% - 5px),
                /* lower left corner end */ 0% calc(50% + 10px),
                7px 50%,
                0% calc(50% - 10px)
            );
        }

        &:not(.disabled, .dimmed) {
            scroll-snap-align: center;
        }

        flos-icon {
            margin-right: 0;
            margin-bottom: 10px;
            flex-shrink: 0;
            flex-grow: 0;
            stroke: $color-primary-1-base;
        }

        p {
            flex-grow: 1;
            display: flex;
            align-items: center;
            font-weight: 400;
            margin: 0;
        }

        &:hover {
            background-color: $color-primary-2-light;
        }

        &.finished {
            flos-icon {
                stroke: $color-supplement-white-base;
                fill: $color-supplement-success-base;
            }
        }

        &.dimmed {
            color: $flow-menu-dimmed-color;
        }

        &.disabled {
            background-color: $flow-menu-disabled-bg;
            pointer-events: none;
            color: $flow-menu-disabled-color;
            flos-icon {
                stroke: $flow-menu-disabled-color;
            }
        }
    }
}
