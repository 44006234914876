@import 'mixins';

html {
    font-size: 10px;
    font-weight: 300;
}

p {
    margin: 0 0 $spacing-3;

    @media (min-width: $screen-sm) {
        margin: 0 0 $spacing-5;
    }

    max-width: 700px;
}

p,
li,
dt,
dd {

    &.strong,
    strong {
        font-weight: 700;
    }

    // S1 Normal
    &.small,
    small {
        font-size: $size-font-xxsmall;
        line-height: $size-lineheight-small;
        font-weight: 500;

        &.strong,
        strong {
            font-weight: 700;
        }
    }

    &.large {
        font-size: $size-font-small;
        line-height: $size-lineheight-large;
    }
}

.paragraph--large {
    font-size: $size-font-small;
    line-height: $size-lineheight-large;
}

.paragraph--small {
    font-size: $size-font-xxsmall;
    line-height: $size-lineheight-small;
    font-weight: 400;
}

// Headers

.display,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 $spacing-5;

    &.is-compact {
        margin: 0;
    }
}

.display {
    font-size: $size-font-xgiant;
    line-height: $size-lineheight-xgiant;
    font-weight: 700;

    @media (max-width: $screen-sm-max) {
        font-size: $size-font-xxlarge;
        line-height: $size-lineheight-xxlarge;
    }
}

h1,
.h1 {
    font-size: $size-font-xxlarge;
    line-height: $size-lineheight-giant;
    font-weight: 500;

    @include create-mq (false) {
        font-size: $size-font-xxlarge !important;
        line-height: $size-lineheight-giant !important;
        font-weight: 500 !important;
    }

    @media (max-width: $screen-sm-max) {
        font-size: $size-font-large;
        line-height: $size-lineheight-xlarge;
    }
}

h2,
.h2 {
    font-size: $size-font-xlarge;
    line-height: $size-lineheight-xxlarge;
    font-weight: 300;

    @include create-mq (false) {
        font-size: $size-font-xlarge !important;
        line-height: $size-lineheight-xxlarge !important;
        font-weight: 300 !important;
    }

    @media (max-width: $screen-sm-max) {
        font-size: $size-font-large;
        line-height: $size-lineheight-xlarge;
    }
}

h3,
.h3 {
    font-size: $size-font-medium;
    line-height: $size-lineheight-large;
    font-weight: 500;

    @include create-mq (false) {
        font-size: $size-font-medium !important;
        line-height: $size-lineheight-large !important;
        font-weight: 500 !important;
    }
}

h4,
.h4 {
    font-size: $size-font-small;
    line-height: $size-lineheight-large;
    font-weight: 500;

    @include create-mq (false) {
        font-size: $size-font-small !important;
        line-height: $size-lineheight-large !important;
        font-weight: 500 !important;
    }
}

h5,
.h5 {
    font-family: 'Interstate Bold Condensed', Interstate, sans-serif;
    font-size: $size-font-xsmall;
    line-height: $size-lineheight-base;
    font-weight: 700;
    letter-spacing: 0.12rem;
    text-transform: uppercase;

    @include create-mq (false) {
        font-family: 'Interstate Bold Condensed', Interstate, sans-serif ;
        font-size: $size-font-xsmall ;
        line-height: $size-lineheight-base ;
        font-weight: 700 ;
        letter-spacing: 0.12rem ;
        text-transform: uppercase ;
    }

    @media (max-width: $screen-sm-max) {
        font-size: $size-font-xxsmall;
    }
}

h6,
.h6 {
    font-size: $size-font-xsmall;
    line-height: $size-lineheight-base;
    font-weight: 500;

    @include create-mq (false) {
        font-size: $size-font-xsmall !important;
        line-height: $size-lineheight-base !important;
        font-weight: 500 !important;
    }
}