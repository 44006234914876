.Car-banner {
    overflow: hidden;
    padding: 40px 0 40px;
    margin-bottom: 0;
    margin-top: 0;
    &:hover {
        background-color: #fff;
    }
    .icon-car-container {
        position: relative;
        overflow: hidden;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 250px;
            margin-bottom: -130px;
        }
        .icon-car {
            fill: $color-primary-2-base;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin: -40px auto -20px;
            }
        }
    }
}

.Car-btn-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 14vmin;
    text-align: right;
    .btn {
        background-color: #414141;
        &:active {
            background-color: #414141 !important;
        }
    }
    &-mobile {
        position: absolute;
        right: 15px;
        margin-top: 15px;
        .Coverage-icon {
            background: #414141;
            width: 40px;
            height: 40px;
            &:after {
                background: none;
            }
            .link-icon {
                display: block;
                font-size: $size-font-small;
                width: 20px;
                height: 20px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
                color: #fff;
            }
        }
        .btn-round {
            border-radius: 50%;
        }
    }
}
