.card {
    display: flex;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-small;
    flex-direction: row;
    background-color: $color-supplement-white-base;
    color: $color-primary-1-base;
    gap: $spacing-5;

    &-image {
        width: 50%;
        padding: 0 $spacing-5 $spacing-5 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: $border-radius-medium 0;
        }
    }

    &-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        padding: $spacing-9;

        .card-label {
            margin-bottom: $spacing-3;
        }

        .h2.card-title {
            @media (min-width: $screen-md) {
                line-height: 48px;
            }
        }

        .card-text {
            margin-bottom: $spacing-7;
        }

        /** When no image on Card */
        &.card-no-image {
            width: 100%;
            > div {
                text-align: center;
                p {
                    max-width: 100%;
                }
            }
        }
    }

    &:not(.card--small):not(.card--medium) {
        @media (min-width: $screen-md) {
            img {
                min-height: 464px;
            }
        }
    }

    /** mobile */
    @media (max-width: $screen-sm-max) {
        flex-direction: column;
        gap: 0;

        .card-image {
            width: 100%;
            padding-bottom: 0;
            padding-right: $spacing-5;
            img {
                min-height: 208px;
            }
        }
        .card-content {
            width: 100%;
            padding: $spacing-5;

            .card-text {
                margin-bottom: $spacing-7;
            }
        }
    }

    /** Image directions applicable only in big screen */
    @media (min-width: $screen-md) {
        &--top-right {
            flex-direction: row-reverse;

            .card-image {
                padding: 0 0 $spacing-5 $spacing-5;
                img {
                    border-radius: 0 $border-radius-medium;
                }
            }
        }
    }

    /** Variants */
    &.card--small,
    &.card--medium {
        flex-direction: column;
        gap: 0;

        .card-content {
            width: 100%;
            justify-content: space-between;

            .card-label {
                margin: 0;
            }

            .card-action {
                * {
                    justify-content: flex-end;
                }
            }

            /** When no image on Card */
            &.card-no-image {
                > div {
                    text-align: left;
                }
            }
        }
        .card-image {
            width: 100%;
            min-height: 208px;
            padding-bottom: 0;
        }
    }

    &.card--small {
        .card-content {
            padding: $spacing-5;

            .card-text {
                margin-bottom: $spacing-7;
                @media (min-width: $screen-md) {
                    padding: $spacing-5;
                    margin-bottom: $spacing-5;
                }
            }
        }
    }

    &.card--medium {
        .card-content {
            padding: $spacing-5;
            @media (min-width: $screen-md) {
                padding: $spacing-7;
            }
            .card-text {
                margin-bottom: $spacing-7;
                @media (min-width: $screen-md) {
                    padding: $spacing-5;
                    margin-bottom: $spacing-5;
                }
            }

            /** When no image on Card */
            &.card-no-image {
                > div {
                    text-align: center;
                }
            }
        }
    }

    /** Themes */
    &--indigo,
    &--primary {
        background-color: $color-primary-1-base;
        color: $color-secondary-4-base;

        .card-title {
            color: $color-supplement-white-base;
        }
        .flos-button--secondary {
            color: $color-supplement-white-base;
        }
    }

    &--ice,
    &--secondary {
        background-color: $color-secondary-4-base;
        color: $color-primary-1-dark;

        .card-title {
            color: $color-primary-1-dark;
        }
    }

    &--transparent {
        box-shadow: none;
        background-color: transparent;
        color: $color-primary-1-dark;
        h2 {
            color: $color-primary-1-dark;
        }
        .card-image {
            img {
                border-radius: $border-radius-medium;
            }
        }
    }
}
