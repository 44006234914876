fieldset {
    &.is-required {
        legend::after {
            content: ' *';
            color: $color-supplement-error-base;
        }
    }
}
.input-list {
    display: flex;
    .checkbox,
    .radio {
        margin-right: 30px;
    }
    &--vertical {
        flex-direction: column;
    }
}
