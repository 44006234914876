// This is kept here untill all fields are using new design
.form-group {
    input {
        background-color: $color-supplement-white-base;
        border: none;
        border-bottom: 1px solid $color-primary-1-base;
    }

    &.has-success .form-control {
        border-color: $color-supplement-success-base;
    }

    &:focus,
    &hover {
        box-shadow: none;
    }

    &.has-error .form-control {
        &,
        &:focus,
        &:hover {
            border-color: $color-supplement-error-base;
        }
    }
    .form-control[disabled] {
        background-color: $color-secondary-4-base;
        opacity: 1;
    }
}
