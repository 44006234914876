@use 'sass:map';
@use 'sass:meta';

$offsets: (
    's': $spacing-3,
    'm': $spacing-5,
    'l': $spacing-7,
    'xl': (
        'mobile': $spacing-7,
        'desktop': $spacing-9,
    ),
    'xxl': (
        'mobile': $spacing-7,
        'desktop': $spacing-9,
    ),
);

.flos-box {
    padding: $spacing-5;
    border-radius: $border-radius-medium;
    background: $color-supplement-white-base;
    box-shadow: $shadow-small;
    position: relative;
    border: 1px solid $color-supplement-white-base;
    transition: all 250ms ease-out;

    &--flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: $spacing-5;
        width: 100%;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-title {
        margin-bottom: 0;
    }

    &-action-area {
        align-self: flex-end;
    }

    &-tooltip {
        align-self: flex-end;
    }

    &-offset {
        @each $name, $v in $offsets {
            &-#{$name} {
                &-top-right {
                    @if meta.type-of($v) == 'map' {
                        top: map.get($v, 'mobile');
                        right: map.get($v, 'mobile');
                        @media (min-width: $screen-md) {
                            top: map.get($v, 'desktop');
                            right: map.get($v, 'desktop');
                        }
                    } @else {
                        top: $v;
                        right: $v;
                    }
                }
                &-bottom-right {
                    @if meta.type-of($v) == 'map' {
                        bottom: map.get($v, 'mobile');
                        right: map.get($v, 'mobile');
                        @media (min-width: $screen-md) {
                            bottom: map.get($v, 'desktop');
                            right: map.get($v, 'desktop');
                        }
                    } @else {
                        bottom: $v;
                        right: $v;
                    }
                }
                &-top-left {
                    @if meta.type-of($v) == 'map' {
                        top: map.get($v, 'mobile');
                        left: map.get($v, 'mobile');
                        @media (min-width: $screen-md) {
                            top: map.get($v, 'desktop');
                            left: map.get($v, 'desktop');
                        }
                    } @else {
                        top: $v;
                        left: $v;
                    }
                }
            }
        }
    }

    &:not(.flos-box--has-title) .flos-box-tooltip {
        position: absolute;
    }

    // themes
    &--theme {
        &-highlight,
        &-brand {
            background-color: $color-secondary-2-light;
            border: 1px solid $color-primary-2-base;
        }
        &-transparent {
            background-color: transparent;
            border: 1px solid $color-secondary-4-base;
        }
        &-secondary {
            background-color: $color-supplement-grey-light;
            border: 1px solid $color-supplement-grey-light;
        }
    }

    &--center-children {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        .flos-box-title,
        .flos-box-action-area,
        .flos-box-tooltip {
            position: absolute;
        }
    }

    &--min-height {
        &-150 {
            min-height: 150px;
            @media (min-width: $screen-md) {
                min-height: 200px;
            }
        }
    }
}
