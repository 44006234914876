.flos-badge {
    width: $spacing-6;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: $size-font-xxsmall;
    border: 2px solid $flos-badge-background-color;
    display: inline-block;
    color: $color-supplement-white-base;
    background-color: $flos-badge-background-color;

    // sizes
    &--small {
        width: 20px;
        height: 20px;
        line-height: 16px;
        text-align: center;
        font-size: $size-font-xxsmall;
    }

    // themes
    &--primary {
        border-color: $flos-badge-background-color--primary;
        background-color: $flos-badge-background-color--primary;
    }
}
