.Loading {
    display: inline-block;
    min-width: 36px;
    max-width: 30vmin;
    padding: 10px;
    line-height: 0;
    border-radius: 50%;
    border: 1px solid $color-primary-2-base;
    background-color: $color-supplement-white-base;
    z-index: 1000;

    &-img {
        border-radius: 100%;
        max-width: 100%;
    }

    &--abs {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}
