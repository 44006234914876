.dialog {
    background-color: $color-primary-1-base;
    display: none;
    padding: $spacing-5;
    position: absolute;
    z-index: 999;
    color: $color-supplement-white-base;
    width: 250px;

    @media (min-width: $screen-md) {
        width: 314px;
    }

    .close {
        position: absolute;
        top: 9px;
        right: 15px;
        display: block;
        margin: 0 0 20px;
        color: #75bee9;
        opacity: 1 !important;
        font-weight: normal;
        font-size: 14px;
        text-shadow: none;
        font-family: sans-serif, Verdana, Tahoma;
        min-width: fit-content;
        &:hover {
            cursor: pointer;
            background: none;
        }
        .ico-delete {
            margin-right: 5px;
            font-size: 20px;
            margin-top: -4px;
            position: absolute;
            margin-left: -20px;
        }
    }

    .info-more {
        margin: 10px 0 0;
    }

    .info-extended {
        display: none;
        margin-top: 5px;
    }

    &:not(.dialog-icon) {
        &::after {
            width: 13px;
            height: 13px;
            content: '';
            top: 18px;
            position: absolute;
            border: 10px solid transparent;
        }

        &.pos-left::after {
            left: 100%;
            border-left-color: $color-primary-1-base;
        }

        &.pos-right::after {
            right: 100%;
            border-right-color: $color-primary-1-base;
        }

        &.pos-top::after {
            left: 50%;
            transform: translateX(-50%);
            top: -18px;
            border-bottom-color: $color-primary-1-base;
        }

        &.pos-bottom::after {
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            border-top-color: $color-primary-1-base;
        }
    }
    &.has-close {
        padding-top: 40px !important;
    }
}
