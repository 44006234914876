.flos-uploadfield {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: $spacing-5;

    &-label {
        align-items: center;
        font-size: $size-font-xxsmall;
        display: flex;
        font-weight: 500;
        justify-content: space-between;
        line-height: $size-lineheight-small;
        margin-bottom: $spacing-3;
        width: 100%;
        cursor: pointer;
    }
    &-button {
        background-color: transparent;
        border: 1px solid $upload-field-cta-color;
        color: $upload-field-text-color;
        &.icon-only {
            max-width: fit-content;
        }
        &:not(:disabled) flos-icon {
            stroke: $upload-field-cta-color;
        }
        &:hover,
        &:focus {
            background-color: transparent;
            border: 1px solid $upload-field-cta-color;
            color: $upload-field-text-color;
        }
    }

    &-filelist {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        list-style-type: none;
        margin-bottom: 0;
        max-height: 400px;
        overflow: auto;
        padding: 0;
        width: 100%;
        border: 1px solid $upload-field-file-border-color;
        padding: $spacing-5 $spacing-5 $spacing-3 $spacing-5;
        border-radius: $spacing-3;

        &--overflow {
            padding-right: $spacing-4;
        }
    }

    &-file {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: $spacing-3;
        margin: 0;
        padding: $spacing-3 0;
        position: relative;
        width: 100%;

        &--disabled {
            color: $color-supplement-grey-medium;

            flos-icon {
                stroke: $color-supplement-grey-medium;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $upload-field-file-border-color;
        }

        .cvi-loading-dots--abs {
            margin-left: -$spacing-3;
        }
        &label {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex: 1;
            font-size: $size-font-xxsmall;
            font-weight: 500;
            gap: $spacing-3;
            line-height: $size-lineheight-small;
            overflow: hidden;

            &.has-error {
                color: $color-supplement-error-base;
            }
        }
        &name {
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &-btn {
        color: inherit;
        min-width: unset;
        width: auto;
        background-color: transparent;
        &:hover,
        &:focus,
        &:disabled {
            background-color: transparent;
        }

        &--delete {
            color: $color-supplement-error-base;
        }
    }
    &-errorlist {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: $spacing-3;
    }

    .message-close-button flos-icon {
        width: $spacing-5 !important;
        height: $spacing-5 !important;
        stroke: $upload-field-file-item-border-color--error;
    }

    &-description {
        background: $color-supplement-warning-light;
        border-color: $color-supplement-warning-medium;
        border-radius: $spacing-3;
        color: $color-supplement-warning-dark;
        font-size: $size-font-xxsmall;
        line-height: $size-lineheight-small;
        margin-bottom: 0;
        padding: $spacing-5;
    }
}
