
.flos-list-box-list {
    .flos-list-box:not(:last-child) {
        margin-bottom: $spacing-5;
    }
}

.flos-list-box {
    padding: $spacing-5 0;
    background: $color-supplement-white-base;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-small;
    border: 1px solid $color-supplement-white-base;

    &--expanded {
        &:not(.flos-list-box--ecommerce) {
            padding-bottom: 0;
        }
    }

    &--primary {
        &.flos-list-box--has-action-area {
            &:hover {
                border-color: $color-primary-2-base;
            }

            .flos-list-box-header {
                cursor: pointer;
            }
        }

        &:hover {
            cursor: default;
        }
    }

    &--secondary {
        border: 1px dashed $color-supplement-grey-medium;
        border-radius: 8px;
        background: $flos-list-box-secondary-bg;

        &.flos-list-box--has-action-area {
            &:hover {
                border-color: $color-primary-2-base;
            }

            .flos-list-box-header {
                cursor: pointer;
            }
        }

        &:hover {
            cursor: default;
        }

        .flos-list-box-info {
            background-color: $flos-list-box-info-bg;
        }
    }

    &--transparent {
        border-color: transparent;
        background: transparent;
        box-shadow: none;

        .flos-list-box-header {
            padding: 0;
        }
    }

    &--disabled {
        border: 1px solid $color-secondary-4-base;
        background: $color-supplement-grey-light;
        box-shadow: none;
        color: $color-supplement-grey-dark;
    }

    &-header {
        padding: 0 $spacing-5;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }



    &-description {
        padding: 0 $spacing-5 $spacing-5 $spacing-5;
        max-width: 732px;
    }

    &-subtitle {
        padding: 0;
        margin: 0;
    }

    &-icon-wrap {
        display: flex;
        align-items: center;
        gap: $spacing-5;
    }

    &-title {

        p,
        h3,
        h4 {
            margin-bottom: 0;
        }
    }

    &--has-description &-header-area.expanded,
    &--has-subtitle {
        &:not(.flos-list-box--ecommerce) {
            padding-bottom: $spacing-5;
        }

    }

    &-action {
        display: flex;
        gap: $spacing-5;

        .flos-field {
            margin: 0;
        }
    }

    &-info {
        display: flex;
        gap: $spacing-5;
        align-items: center;
        padding: $spacing-4 $spacing-5;
        border: 1px solid $color-supplement-grey-base;
        border-radius: $border-radius-medium;

        p,
        h6,
        .h6 {
            white-space: nowrap;
            display: inline;
            margin: 0;
        }
    }

    &-addon-content {
        padding: $spacing-5 $spacing-5 0 $spacing-5;

        >.Box {
            padding: 0;
        }
    }

    &-action-area--mobile {
        padding: 0 $spacing-5;
        display: none;
    }

    &-accordion-icon {
        flos-icon {
            transition-duration: 250ms;
            stroke: $accordion-icon-stroke;
        }

        >.flos-button {
            padding: $spacing-3;
            width: unset;
            margin: 0;
        }

        &.expanded {
            flos-icon {
                transform: rotate(180deg);
            }
        }
    }

    &-children-content {
        max-height: 0;
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;

        &--expanded {
            max-height: 200vh;
            overflow: auto;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .flos-list-box-header {
        flex-direction: column;
        row-gap: $spacing-5;

        >div {
            width: 100%;
        }
    }

    .flos-list-box-title {

        h3,
        .h3,
        h4,
        .h4 {
            font-size: $size-font-xsmall;
            line-height: $size-lineheight-medium;
        }
    }

    .flos-list-box-icon-wrap {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
    }

    .flos-list-box-action {
        flex-direction: column;
        row-gap: $spacing-3;
    }

    .flos-list-box-addon-content {
        padding-top: $spacing-3;
    }

    // transparent
    .flos-list-box--transparent {
        .flos-list-box-header {
            flex-direction: row;

            >div {
                width: auto;
            }
        }

        .flos-list-box-icon-wrap {
            flex-direction: row;
            width: auto;
            justify-content: flex-start;
        }

        .flos-list-box-action {
            flex-direction: row;
        }
    }
}

.flos-list-box--ecommerce {

    padding: $spacing-5 0 0;

    &:not(.flos-list-box--disabled) {
        border: none;
    }

    &:not(.flos-list-box--has-children) {
        padding-bottom: $spacing-7;
    }

    .flos-list-box-header {
        display: block;
        padding: $spacing-5 $spacing-7 0;

        @media (max-width: $screen-sm-max) {
            padding: $spacing-5 $spacing-5 0;
        }

        p {
            max-width: none;
        }
    }

    .flos-list-box-subtitle {
        margin-bottom: $spacing-5;
    }

    .flos-list-box-addon-content {
        padding: 0 $spacing-7 0;

        @media (max-width: $screen-sm-max) {
            padding: 0 $spacing-5 0;
        }
    }

    .flos-list-box-icon-wrap {
        display: block;
    }

    .flos-list-box-accordion {
        margin-top: $spacing-5;
        padding-bottom: $spacing-5;


        &:hover {
            cursor: pointer;
        }

        &.expanded {
            background-color: $color-primary-2-light;
            transition: background-color 0.1s linear;
        }

        &:not(.expanded) {
            transition: background-color 0.1s linear;
            transition-delay: 0.2s;
        }
    }

    .flos-list-box-accordion-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-3;

    }

    .flos-list-box-children-content {
        background-color: $color-primary-2-light;
        border-bottom-left-radius: $border-radius-medium;
        border-bottom-right-radius: $border-radius-medium;
    }

    .flos-list-box-children-content--ecommerce {
        padding: $spacing-5 $spacing-7 $spacing-7;


        @media (max-width: $screen-sm-max) {
            padding: $spacing-5 $spacing-5 $spacing-7;
        }

    }

}