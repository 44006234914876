.box-shadow--2dp {
    box-shadow: none;
}

.box-shadow--3dp {
    box-shadow: none;
}

.box-shadow--4dp {
    box-shadow: none;
}

.box-shadow--6dp {
    box-shadow: none;
}

.box-shadow--8dp {
    box-shadow: none;
}

.box-shadow--16dp {
    box-shadow: none;
}

.Box--shadow {
    box-shadow: none;
    padding: 15px 20px;
    background-color: $color-supplement-white-base;

    & .vcenter {
        display: inline-block;
        vertical-align: middle;
        float: none;

        &:last-child {
            margin-left: -8px;
            text-align: right;
        }
        @media (max-width: $screen-xs-max) {
            &:last-child {
                margin-left: 0;
                text-align: left;
            }
        }
    }
}
