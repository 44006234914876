.Box--wrapper {
    border-bottom: 1px dashed $color-secondary-4-base;
    &-no-border {
        border-bottom: none;
    }

    .Box {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.Box--group {
    display: grid;
    gap: $spacing-7;
    grid-template-columns: repeat(12, 1fr);

    @media (max-width: $screen-sm-max) {
        gap: $spacing-5;
        grid-template-columns: 1fr;
        & > div,
        a {
            grid-column: 1 / span 1;
        }
    }

    > div {
        border-radius: $border-radius-medium;
        box-shadow: $shadow-small;
    }

    > div[data-label] {
        margin-top: $spacing-7;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @media (min-width: $screen-md) {
            margin-top: 0;
        }
    }

    > * {
        &:not(:only-child):not(:nth-last-child(2n):first-child):not(:nth-last-child(2n):first-child ~ *) {
            -ms-grid-column-end: span 4;
            grid-column-end: span 4;
        }
        &:not(:only-child):not(:nth-last-child(3):first-child):not(:nth-last-child(3):first-child ~ *),
        &:nth-last-child(1):first-child {
            @media (min-width: $screen-md) {
                -ms-grid-column-end: span 6;
                grid-column-end: span 6;
            }
        }
    }

    &-compact {
        gap: 0;

        .Box {
            box-shadow: none;
        }

        > div {
            box-shadow: none;

            @media (max-width: $screen-sm-max) {
                &:not(:last-child) {
                    border-bottom-width: 0 !important;
                }
                &:not(:first-child):not(:last-child) {
                    border-radius: 0 !important;
                }
                &:first-child {
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                &:last-child {
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }
            }

            @media (min-width: $screen-md) {
                // border
                &:not(:last-child):not(:nth-last-child(2n + 1):first-child ~ *:nth-last-child(2)):not(:nth-last-child(2n):first-child
                        ~ *:nth-last-child(2)):not(:nth-last-child(3):first-child):not(:nth-last-child(2):first-child) {
                    border-bottom-width: 0 !important;
                }
                &:not(:last-child):not(:nth-last-child(2n + 1):first-child:not(:nth-last-child(3):first-child) ~ *:nth-last-child(2n)):not(:nth-last-child(2n):first-child
                        ~ *:nth-last-child(2n-1)) {
                    border-right-width: 0 !important;
                }

                // border radius
                &:not(:first-child) {
                    border-top-left-radius: 0 !important;
                }
                &:not(:only-child):not(:nth-last-child(3):first-child ~ *:last-child):not(:nth-last-child(2n):first-child ~ *:nth-child(2)):not(:nth-last-child(2n
                            + 1):first-child:not(:nth-last-child(3):first-child)
                        ~ *:nth-child(2)) {
                    border-top-right-radius: 0 !important;
                }
                &:not(:only-child):not(:nth-last-child(2n + 1):first-child ~ *:last-child):not(:nth-last-child(2n):first-child ~ *:last-child):not(:nth-last-child(2n
                            + 1):first-child:not(:nth-last-child(3):first-child)
                        ~ *:nth-last-child(2)) {
                    border-bottom-right-radius: 0 !important;
                }
                &:not(:only-child):not(:nth-last-child(3):first-child):not(:nth-last-child(2n):first-child
                        ~ *:nth-last-child(2)):not(:nth-last-child(2):first-child):not(:nth-last-child(2n + 1):first-child:not(:nth-last-child(3):first-child) ~ *:last-child) {
                    border-bottom-left-radius: 0 !important;
                }
            }
        }
    }
}

.Box {
    display: block;
    width: 100%;
    position: relative;
    padding: $spacing-5;
    color: $color-primary-1-dark;
    background-color: #fff;

    &--indent-small {
        > div {
            @media (min-width: $screen-md) {
                width: auto;
                margin-left: $spacing-9;
            }
        }
    }

    &--indent-large {
        > div {
            @media (min-width: $screen-md) {
                width: auto;
                margin-left: $spacing-12;
            }
        }
    }

    &--auto-width {
        width: auto;
        margin-left: auto;
    }

    &-content {
        .btn:last-child {
            margin-top: 20px;
        }
    }

    &:hover {
        .Box-header {
            &::before,
            &::after {
                margin-left: 5px;
            }
        }
    }

    // Last paragraph in Box shouldn't have margin, otherwise the top and bottom padding are not the same (UX requirement)
    p:last-child {
        margin: 0;
    }

    h2 {
        color: $color-secondary-2-base;

        &.Box--simple {
            color: $color-primary-1-base;
        }
    }

    &.has-border {
        border-bottom: 1px dashed $color-supplement-grey-base;
    }

    &[data-label] {
        margin-top: $spacing-7;
        &:before {
            @extend h5;
            color: white;
            position: absolute;
            width: 100%;
            text-align: center;
            top: -$spacing-7;
            left: 0;
            padding: $spacing-2 $spacing-7;
            white-space: nowrap;
            background-color: $color-secondary-3-base;
            content: attr(data-label);
            border-top-left-radius: $border-radius-medium;
            border-top-right-radius: $border-radius-medium;
        }
    }
}

.Box--cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Box--align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-5;
}

.Box--has-icon {
    display: flex;

    flos-icon,
    .icon {
        margin-right: $spacing-5;
    }

    &--large {
        @media (min-width: $screen-md) {
            display: flex;
            margin-left: initial;
        }
    }
}

.Box--indigo {
    background-color: $color-primary-1-base;
    color: $color-supplement-white-base;
    & > * {
        color: $color-supplement-white-base;
    }
}

.Box-header {
    hyphens: auto;
    word-break: break-word;

    &--large {
        font-size: $size-font-base;
        font-weight: 700;

        @media (min-width: $screen-sm) {
            font-size: $size-font-large;
        }
    }

    &--small {
        font-size: $size-font-small;
    }

    &--fat {
        font-weight: 700;
    }
}

/*
**	Clickable link box.
*/

.Box--link {
    display: block; // Can be used on A elements.
    width: 100%;
    margin: $spacing-5 0;

    .Box-header {
        margin-bottom: 0;
    }

    &.is-active,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        background: $color-secondary-4-medium;
    }

    .Note {
        font-size: $size-font-xxsmall;
    }

    &.is-active .tds-Icon {
        color: #fff !important;
    }
}

.Box--info {
    background-color: $color-supplement-grey-base;
}

.Box--spot {
    background-color: $color-secondary-4-base;

    // New icon styles
    &__header {
        display: table;

        &__icon {
            display: table-cell;
            vertical-align: middle;
            padding-right: 5px;
        }

        &__title {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .Box-header {
        &--fat {
            font-weight: 700;
        }
    }

    @media (min-width: $screen-md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media (max-width: $screen-sm-max) {
        margin-bottom: $spacing-5;
    }
}

.Box-label {
    font-size: $size-font-small;
}

.Box--image {
    padding: 0.1px; // fixes collapse issue.

    .Box-header {
        margin: 20px 30px;
    }

    > img {
        width: 100%;
        border-radius: 0;
        position: relative;
    }

    .Box-body {
        padding-left: 35px;
        margin: -15px 30px 25px;
    }
}

.Box--image-caption {
    padding: 20px;
}

.Box--media {
    margin: $spacing-5 0;
    padding: 0.1px;
}

.Box--sub-box {
    padding-left: 0;
    padding-right: 0;
}

.Box--bg {
    position: relative;
    color: $color-supplement-white-base;
    background-size: cover;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 100;
        border-radius: $border-radius-small;
        background-color: change-color(#414141, $alpha: 0.7);
        transition: background 0.3s;
    }
}
.BoxRollup {
    display: block;
    min-height: 180px;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $color-secondary-2-base;
    color: $color-supplement-white-base;
    background-size: cover;

    &:hover,
    &:active,
    &:focus {
        background-color: $color-primary-1-base;
        color: #fff;
    }

    .Box-header {
        color: $color-supplement-white-base;
    }

    @media (min-width: $screen-md) {
        min-height: 210px;
    }

    .Box-header {
        margin-bottom: 25px;
    }

    .Box-header,
    .Box-body {
        position: relative;
        z-index: 150;
        max-width: 500px;
    }
}

.Box--ice {
    padding: 16px;
    background-color: $color-secondary-4-light;
    margin: 0;
    &.has-border {
        border-bottom: 1px dashed $color-primary-1-base;
    }
}

.Box--ice-300 {
    background-color: $color-secondary-4-light;
}
