//============================================
// Coop specific variables
//============================================

// todo: Missing font token for 18px - do we need it? used lots in mobile views
$font-size-m: 18px;

// todo: Refactor to use token - hard because of bootstrap and its calculation
$font-size-base: 16px;

$font-family-base: Coop, 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
