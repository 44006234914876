.tooltip-icon {
    display: inline-block;
    align-items: center;
    margin-left: $spacing-5;

    &-button {
        background-color: transparent;
        padding: 0;
        border: none;
    }
}
