$navbar-sticky-height: 60px;

.ProcessBar-container {
    display: none;
    position: sticky;
    z-index: 999;
    top: 60px;
    transition: 350ms height;

    &--mobile-visible {
        display: block;
    }

    @media (min-width: $screen-md) {
        display: block;

        .ProcessBar {
            top: $navbar-sticky-height;
        }
    }

    @media (min-width: $screen-sm) {
        display: block;
    }
}

.ProcessBar {
    overflow: hidden;
    width: 100%;
    color: $color-supplement-white-base;
    //box-shadow: inset 0 6px 3px rgba(0, 0, 0, .2);
    transform: translate3d(0, 0, 0); // Safari fix.
    background: $color-primary-1-base;

    a {
        color: inherit;
    }

    .site-header--mit {
        top: 30px;
    }

    &.is-sticky {
        top: 62px;
        position: fixed;

        &.has-switch-back {
            top: 90px;
        }
        @media (max-width: $screen-md) {
            top: 52px;
            &.has-switch-back {
                top: 102px;
            }
        }
    }

    .no-csspositionsticky & {
        top: 0;
        position: absolute;

        &.is-sticky {
            position: fixed;
            top: 50px;

            @media (min-width: $screen-md) {
                top: $navbar-sticky-height;
            }
        }
    }

    .VerifyRow {
        &-submit {
            text-align: right;
            height: $navbar-sticky-height;
            line-height: $navbar-sticky-height;

            #anmeldSkadeTop {
                margin-top: -4px;
            }
        }

        &-back {
            text-align: left;
            height: $navbar-sticky-height;
            line-height: $navbar-sticky-height;

            span,
            a {
                font-weight: 400;
            }
        }
    }

    .btn-primary {
        background-color: $color-secondary-2-base;
        &:hover {
            background-color: $color-primary-1-base;
        }
    }

    .StepNav {
        $nav-divider: '%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2056%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M13%2028L0%200h1l13%2028L1%2056H0l13-28z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E';

        &-item {
            color: $color-supplement-white-base;
            background: url(#{$svg-data-uri-prefix},#{$nav-divider}) no-repeat right / 14px 56px;
            margin-bottom: 0;

            flos-icon {
                stroke: $color-supplement-white-base;
            }

            &::before {
                border-width: 0;
            }

            &.is-completed {
                &::before {
                    background-color: transparent;
                    background-image: none;
                    position: absolute;
                }

                flos-icon {
                    display: inline-block;
                    margin-right: 10px;
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
}
