.ArticleSpot {
    border-bottom: 2px solid $color-secondary-4-base;
    &-header {
        margin-bottom: 20px;
    }

    &-body {
        margin: 20px 0 30px;
        max-width: 520px;
    }

    &--bg {
        background-size: cover;
        position: relative;
        color: $color-supplement-white-base;

        .ArticleSpot-header {
            color: $color-supplement-white-base;
        }

        @media (min-width: $screen-md) {
            padding-left: 40px;
            padding-right: 40px;
        }

        > * {
            position: relative;
            z-index: 100;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(65, 65, 65, 0.6);
        }
    }
}
