.site-header-segments {
    position: relative;
    z-index: 999;
    padding: $spacing-3 0;
    border-bottom: 1px solid $color-supplement-grey-base;
    background-color: $site-header-bg;

    a {
        color: $site-header-link-color;
        &:hover {
            color: $site-header-link-color--hover;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: $spacing-7;
        justify-content: space-evenly;
    }

    li {
        margin: 0;
        line-height: $size-lineheight-small;

        &.active a {
            color: $site-header-link-color--hover;
        }
    }
    @media (min-width: $screen-md) {
        ul {
            justify-content: center;
        }
    }
}
