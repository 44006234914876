.Section {
    // used in hybridapps eg. car-mileage-receipt-app (old cvi), car-mileage-reading-receipt-app (old cvi), alfred-vehicle-additional-info-receipt-app-2020 (new cvi)
    > .container {
        //For vertical spacing inside .Section .container
        > .Section-block {
            margin-bottom: 6vmin;

            &:last-child {
                margin-bottom: 0;
            }
            > .Section-header:first-child {
                margin-top: -0.2em; //Offset by line-height above upper X (estimate), so that section padding reaches the actual letter instead of line-height
            }
            > :last-child {
                margin-bottom: 0;
            }
        }
    }

    // Used to handle filtering of visible insurance categories on topdanmark.dk/forsikringer
    &--filtered {
        &.is-hidden {
            display: none !important;
        }
    }

    // Used in hybridapps and on two-col-template .ftl in BrXm to handle bootstrap grid 2 cols
    &--split {
        .container > .row > [class*='col-'] {
            padding-bottom: $spacing-9;

            @media (min-width: $screen-md) {
                padding-bottom: 0;
            }
        }
    }
}

// Special layout for section offset in the content area - used in blog-page.ftl
.Section-offset {
    &-container {
        width: 100%;
        position: relative;

        & .Section:first-child {
            padding-top: 0;
        }
    }

    &-top {
        width: 100%;
        position: absolute;
        height: 14vmin;
        top: 0;
        z-index: 0;
        background-color: $color-secondary-4-base;
    }

    &-bottom {
        width: 100%;
        position: absolute;
        height: 8vmin;
        bottom: 0;
        z-index: -1;
        background-color: $color-secondary-4-base;
    }
}
