$light-grey: #aeaeae;
$gray-border: #e4e4e4;
$iphone5-width: 568px;
$iphone5-portrait-width: 360px;
$mobile-landscape-max-height: 380px;

.flos-ResponsiveCoverage {
    padding: 0;
    margin-top: 0;
    overflow: hidden;
    transition: background 0.4s, color 0.4s;
    background-color: $color-supplement-white-base;

    .flos-ResponsiveCoverage-action {
        display: flex;
        > div {
            &.standard {
                align-self: center;
            }
        }

        &--xs {
            margin-bottom: 15px;
        }
        .action-sub-text {
            padding-top: 5px;
            text-align: right;
        }
    }

    &--uncovered {
        &.Box {
            background: none;
            border: 1px dashed $gray-border;
            &.expanded {
                background-color: $color-supplement-white-base;
                border: 1px solid $gray-border;
            }
        }
        .flos-ResponsiveCoverage-header {
            background-color: transparent !important;
        }
        .flos-ResponsiveCoverage-action {
            background: none;
        }
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.flos-ResponsiveCoverage-header {
    color: $color-primary-1-base;
    transition: all 0.2s;
    border-radius: $border-radius-small $border-radius-small 0 0;
    box-shadow: none;
    padding: $spacing-5;
    position: relative;
    display: table;
    width: 100%;
    min-height: 88px;

    .default-warning {
        display: block;
    }

    @media (max-width: $screen-xs-max) {
        padding: 0;
        .default-warning {
            display: none;
        }
    }
    @media (max-width: $screen-xs-max) and (max-height: $mobile-landscape-max-height) {
        padding: 0;
        .default-warning {
            display: none;
        }
    }

    &--uncovered {
        background: #eee;
        border-radius: $border-radius-small;
        &::before,
        &::after {
            background: #333;
        }
        &::after {
            transform: scale(1);
        }
    }

    &--covered {
        border-radius: $border-radius-small;
        background: $color-supplement-white-base;
        &::before,
        &::after {
            background: #333;
        }
        &::after {
            transform: scale(1);
        }
    }

    &.collapsed-panel {
        &::before,
        &::after {
            background: #333;
        }
        &::after {
            transform: scale(1);
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 20px;
        background: $color-primary-1-base;
    }
}

.flos-ResponsiveCoverage-title {
    font-size: $size-font-small;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.15;
    font-weight: 700;
    hyphens: auto;
    word-break: break-word;
    &.no-sub {
        margin-bottom: 15px;
    }
    @media (max-width: $screen-xs-max) {
        margin-top: 5px;
        margin-bottom: 15px;
    }
}

.flos-ResponsiveCoverage-subtitle {
    font-size: $size-font-base;
    font-weight: 400;
    margin-bottom: 8px !important;
    @media (max-width: $screen-xs-max) {
        margin-top: 10px !important;
    }
}

.flos-ResponsiveCoverage-action {
    margin-top: 2px;
    .action {
        font-size: $size-font-base;
        .flos-button,
        .cvi-button {
            margin-right: 0;
        }
        .action-text {
            display: block;
            text-align: right;
            font-size: $size-font-base;
            color: #707070 !important;
        }
        &.link {
            .action-text {
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    text-align: left;
                }
            }
        }
        &.standard {
            padding: 10px 0;
            &.active {
                display: table-cell;
                vertical-align: middle;
                padding: 0;
                @media (max-width: $screen-xs-max) {
                    padding: 10px 0;
                    height: auto !important;
                }
            }
            .action-text {
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
    &--static {
        align-self: center;
    }
    .mobile-warning {
        display: none;
    }
    @media (max-width: $screen-xs-max) and (max-height: $mobile-landscape-max-height) {
        text-align: left;
        width: 100%;
        padding: 12px;
        .mobile-warning {
            display: block;
            width: 100%;
            float: left;
        }
    }
    @media (max-width: $screen-xs-max) {
        text-align: left;
        width: 100%;
        padding: 12px;
        .mobile-warning {
            display: block;
            width: 100%;
            float: left;
        }

        .action-sub-text {
            text-align: right;
            width: auto;
        }
    }
    &--space-between {
        justify-content: space-between;
        &-center {
            align-items: center;
        }
    }
    &--expire {
        display: flex;
        justify-content: space-between;
        .action {
            float: left;
        }

        .action-sub-text {
            text-align: right;
            width: 100%;
            align-self: center;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}

.action-sub-text {
    font-size: $size-font-xxsmall;
    margin-top: 1px;
}

.flos-ResponsiveCoverage-wrapper {
    .flos-ResponsiveCoverage-links {
        a {
            margin: 0 30px 0 0;
            font-size: $size-font-base;
        }
        font-weight: 400;

        @media (max-width: $screen-xs-max) {
            margin-bottom: 14px;
            margin-right: 20px;

            &-xs {
                margin-left: 12px;
                padding-left: 0;
            }
        }
    }

    &--space-between {
        .flos-ResponsiveCoverage-links {
            padding-left: 0;

            @media (max-width: $screen-xs-max) {
                padding-left: 0;
                margin-left: 12px;
            }
        }
    }
}

.flos-ResponsiveCoverage-titlegroup {
    flex: 1 0 0%;
    flex-grow: 1;
    @media (max-width: $iphone5-width) {
        width: 67%;
    }
    @media (max-width: $iphone5-portrait-width) {
        width: 100%;
    }
    @media (max-width: $screen-xs-max) {
        width: 100%;
        padding: 12px 12px 0 12px;
    }
    @media (max-width: $screen-xs-max) and (max-height: $mobile-landscape-max-height) {
        padding: 15px 15px 0 60px;
    }
}

.flos-ResponsiveCoverage-wrapper {
    overflow: hidden;
    display: flex;
    &.active {
        /*
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 15px;
        */
        @media (max-width: $screen-xs-max) {
            padding-bottom: 0;
        }
    }
    &--space-between {
        justify-content: space-between;
    }
    &--valign-center {
        align-items: center;
    }
    @media (max-width: $screen-xs-max) {
        display: block;
    }
}

.flos-ResponsiveCoverage-icon {
    align-self: flex-start;
    width: 36px;
    height: 36px;
    position: absolute;
    transform: translateY(-50%);
    margin-top: 18px;
    @media (max-width: $screen-xs-max) {
        width: 30px;
        height: 30px;
        margin: 28px 0 15px 12px;
    }
    @media (max-width: $screen-xs-max) and (max-height: $mobile-landscape-max-height) {
        margin: 28px 0 0 12px;
    }

    svg {
        stroke-width: 1.5px;
    }
    &--included {
        stroke: $color-supplement-success-base;
    }

    &--excluded {
        stroke: $color-supplement-error-base;
    }

    .flos-ResponsiveCoverage-list & {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        border-width: 1px;
        margin-top: 12px;
        &::after {
            width: 10px;
            height: 10px;
        }
        .flos-ResponsiveCoverage-icon {
            margin: 20px;
        }
    }
}

.flos-ResponsiveCoverage-content {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0 0 2px 2px;
    color: $color-secondary-2-base;
    font-weight: 400;
    .Section {
        .title {
            font-size: $size-font-small;
        }
    }
}

.flos-ResponsiveCoverage-list {
    list-style: none;
    padding: 20px 15px;
    background-color: #fff;
    margin: 10px 0;
    float: none;
    &item {
        padding-left: 30px;
        position: relative;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
        @media (max-width: $screen-xs-max) {
            .flos-ResponsiveCoverage-icon {
                margin: 12px 0 15px 0;
            }
        }
    }
}

.a-disabled {
    pointer-events: none;
    cursor: default;
}

.flos-ResponsiveCoverage-attention {
    background-color: $color-secondary-1-base;
    color: $color-supplement-white-base;
    display: block;
    width: calc(100% + 39px);
    margin: 0 -20px;
    padding: 2px 40px;
    font-size: $size-font-xxsmall;
    @media (max-width: $screen-xs) {
        margin: 12px 0 -12px -12px;
    }
}

.flos-ResponsiveCoverage-attention-mobile {
    font-size: $size-font-xxsmall;
    color: $color-supplement-white-base;
    background: $color-secondary-1-base;
    padding-left: 12px;
    padding-bottom: 7px;
    margin-top: -5px;
}
