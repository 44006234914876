// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
    font-weight: 400;
    color: $color-primary-2-base;
    border-radius: 0;

    &,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
        @include box-shadow(none);
    }
    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }
    &:hover,
    &:focus {
        color: $color-primary-2-base;
        text-decoration: none;
        background-color: transparent;
    }
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: $button-disabled-color;
            text-decoration: none;
        }
    }
}

.btn-block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
    &.btn-block {
        width: 100%;
    }
}

.btn-next {
    @extend .btn-wide;
    @extend .btn-lg;
    @extend .btn-default;

    margin: 25px 0;

    @media (max-width: $screen-sm-max) {
        position: relative;
        left: 11%;
        transform: translateX(-50%);

        @media (max-width: $screen-xs-max) {
            left: 83px;
        }
    }

    @media (min-width: $screen-sm) {
        margin: 5vmin 0;
    }
}

.btn-tall {
    background-color: $color-supplement-white-base;
    border: 1px solid $color-secondary-4-base;
    height: 120px;
    color: $color-supplement-grey-dark;
    &:hover,
    &.active,
    &:active,
    &:focus {
        box-shadow: none !important;
        background-color: $color-supplement-grey-light;
        color: $color-supplement-grey-dark;
    }
    h4 {
        margin: 0 !important;
    }
    &.active:before {
        content: '';
        position: absolute;
        left: 45%;
        bottom: -15px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color-secondary-4-base;
        clear: both;
    }
    &.active:after {
        content: '';
        position: absolute;
        left: 45%;
        bottom: -14px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $color-supplement-grey-light;
        clear: both;
    }
}

.btn-cancel,
.btn-cancel:visited,
.btn-cancel:active,
.btn-cancel:hover {
    box-shadow: none !important;
    background-color: $color-supplement-white-base;
    color: $color-primary-2-base;
}

.btn-cancel:hover {
    text-decoration: underline;
}

.btn-centered {
    @media (max-width: $screen-xs-max) {
        display: block;
        margin-right: auto;
        margin-left: auto;
        float: none;
        width: 80%;
        display: block !important;
        white-space: normal;
        margin-top: 15px; // add some vertical spacing between buttons
    }
}

.Section-navigation {
    &--button:first-child {
        margin-right: 10px;
    }
    &--button {
        padding-left: 25px;
        padding-right: 25px;
    }
    &--back,
    &--start {
        min-width: 182px;
        display: inline-block;
        margin: 0;
        text-align: center;
        font-weight: bold;
        &:focus {
            color: $color-primary-2-base;
        }
        &.focus {
            color: $color-primary-2-base;
        }
        &:hover {
            color: $color-primary-2-base;
        }
    }
    &--start {
        min-width: 160px;
    }
}

.btn--action-remove-link {
    color: #3f7a99;
    background: none;
    font-weight: bold;
    border: 2px solid #3f7a99;
    &:focus {
        color: #3f7a99;
    }
    &:hover {
        color: #3f7a99;
    }
}

.button-disabled {
    background-color: $color-supplement-grey-dark !important;
    cursor: pointer !important;
}

.btn-pill {
    display: inline-block;
    border-radius: 25px;
    text-align: center;
    margin: 10px 16px 10px 0;
    min-height: 40px;
    padding: 2px 14px;
    background-color: $color-supplement-white-base;
    border: 2px solid transparent;
    outline: 0;

    label {
        font-size: $size-font-base;
        font-weight: 200;
        color: $color-supplement-grey-dark;
        white-space: nowrap;
        padding: 10px 25px;
        box-sizing: border-box;
        margin: 0;
        cursor: pointer;
    }

    .tds-Icon {
        transition: all 0.3s;
        width: 0;
        margin-right: 0;
        margin-bottom: -4px;
    }

    &:hover {
        background-color: $color-secondary-2-base;
        color: $color-supplement-white-base;
    }

    &.is-active {
        background-color: $color-primary-1-base;
        color: $color-supplement-white-base;
        box-shadow: none;
        label {
            cursor: default;
        }
    }
}

.btn-group .btn-pill:first-child {
    margin-left: 0;
}
