// this css is deprecated - can be deleted when .datepick no longer in use
.datepick {
    background: $color-supplement-white-base;
    box-shadow: none;
    padding: 20px 20px 15px;
    border-radius: 3px;
    position: relative;

    // Arrow
    &::before,
    &::after {
        position: absolute;
        content: '';
        background: $color-supplement-white-base;
    }

    &::before {
        width: 20px;
        height: 20px;
        top: 25px;
        left: -8px;
        border-radius: 3px;
        transform: rotate(45deg);
    }

    &::after {
        left: 0;
        top: 15px;
        content: '';
        width: 20px;
        height: 55px;
    }

    &-nav {
        position: relative;
        text-align: center;
        margin-bottom: 20px;

        .datepick-cmd {
            position: absolute;
            top: 2px;
            padding: 0 10px;
            font-size: 19px;
            transition: transform 0.1s;

            &-prev {
                left: -8px;
                &:active {
                    transform: translateX(-2px);
                }
            }

            &-next {
                right: -8px;
                &:active {
                    transform: translateX(2px);
                }
            }
        }

        .datepick-month-year {
            position: relative;
            appearance: none;
            padding: 5px 25px 5px 5px;
            font-size: 14px;
            transition: border 0.2s;
            border: 1px solid $color-supplement-grey-base;
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;
            background-size: 14px 8px;
            @extend .ico-arrow-down-blue;

            &::-ms-expand {
                display: none;
            }

            &:hover,
            &:focus {
                border-color: darken($color-supplement-grey-base, 10%);
            }

            .no-appearance & {
                padding: 5px;
                background: none;
            }
        }
    }

    &-month {
        width: 235px;
        font-size: 14px;

        a,
        span {
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: block;
            text-align: center;
            border-radius: 50%;
            transition: background 0.15s;
        }

        td,
        th {
            padding: 2px;
        }

        tr {
            > :first-child {
                padding-left: 0;
            }

            > :last-child {
                padding-right: 0;
            }
        }

        thead {
            span {
                font-weight: 600;
            }
        }

        tbody {
            span {
                color: $color-secondary-4-base;
            }

            a {
                color: $color-primary-1-base;

                &:hover {
                    background: $color-supplement-grey-base;
                }

                &:active {
                    background: darken($color-supplement-grey-base, 5%);
                }
            }

            .datepick-weekend {
                font-weight: 600;
            }
        }

        .datepick-highlight {
            color: $color-supplement-white-base;
            font-weight: 500;
            background: $color-primary-2-base !important;
        }
    }

    &-weekend {
        font-weight: 500;
    }
}

@media (max-width: $screen-xs-max) {
    .date-input-xs {
        width: 100%;
        max-width: 100%;
    }
}

.select2-container .select2-choice .select2-arrow b {
    transform: rotate(90deg);
    position: relative;
    top: -3px;
    &:before {
        content: '>';
        font-family: Interstate, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
}

.datepick-month-header .select2-container a.select2-choice {
    line-height: 14px;
    box-shadow: none;

    .select2-arrow {
        top: 4px;
    }
}
