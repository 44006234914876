// this styling is deprecated and can be removed when ListBox is no longer in use by any app
.list-box {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px dashed $color-secondary-4-base;
    &--no-border {
        border-bottom: none;
    }
    @media (max-width: $screen-md) {
        flex-direction: column;
        & > .Box.Box--has-icon--large {
            align-items: center;
        }
    }
    & > .Box {
        margin: 0;
        &.Box-remove-padding {
            padding: 0;
        }
        .icon {
            flex-shrink: 0;
        }
        & + .Box {
            // Added this to be able to center RadioControGroup in actionArea - couldn't figure out a more elegant way.
            .form-group {
                margin-bottom: 0;
            }
            @media (min-width: $screen-md) {
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
        @media (min-width: $screen-md) {
            display: flex;
            align-items: flex-start;
        }
    }
    .Box--has-icon:not(.Box--has-icon--large) .icon {
        align-self: flex-start;
    }
}
.list-box--list {
    & > .list-box {
        margin-bottom: 30px;
        &.children-exist {
            margin-bottom: 0;
        }
    }
    & > .Box--wrapper {
        margin-top: -30px;
        margin-bottom: 30px;
        &.children-exist {
            margin-top: 0;
        }
    }
}

.flos-listbox {
    background-color: white;
    padding: 20px 20px;
    display: flex;
    border-bottom: 1px dashed $color-secondary-4-base;
    &--right {
        justify-content: flex-end;
    }

    @media (max-width: $screen-sm-max) {
        flex-wrap: wrap;
    }
}
