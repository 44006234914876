@use 'sass:math';

@media (min-width: 600px) {
    .row--equal-xs {
        @include equalflex();
    }
}

@media (min-width: $screen-sm) {
    .row--equal-sm {
        @include equalflex();
    }
}

@media (min-width: $screen-md) {
    .row--equal-md {
        @include equalflex();
    }
}

.row--collapse-xs {
    @media (max-width: $screen-xs-max) {
        margin-left: math.div(-$grid-gutter-width, 2) !important;
        margin-right: math.div(-$grid-gutter-width, 2) !important;

        > [class*='col-'] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.ButtonRow {
    //padding-top: 10px;
    margin: 10px 0;

    .btn {
        margin: 5px 20px 5px 0;
        max-width: 255px;

        &:last-child {
            margin-right: 0;
            //margin-bottom: 0;
        }

        @media (min-width: $screen-sm) {
            min-width: 160px;
        }
    }

    @media (max-width: $screen-xs-max) {
        display: flex;

        .btn {
            flex: 1 1 auto;
            max-width: 60%;
            min-width: 160px;
        }
    }

    &--stack {
        @media (max-width: 460px) {
            flex-flow: row wrap;

            .btn {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                max-width: 100%;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.overflow-ellipsis {
    @include overflow-ellipsis();
    max-width: 100%;
}

.display-inline-block {
    display: inline-block;
}

.voice {
    &-loud {
        font-size: $size-font-small;
        font-weight: bold;
    }
    &-noicy {
        font-size: $size-font-small;
    }
}

.u-has-icon-before {
    flos-icon {
        margin-right: $spacing-3;
    }
}
.u-has-icon-after {
    flos-icon {
        margin-left: $spacing-3;
    }
}

@media (min-width: 1201px) {
    .u-has-icon-before {
        flos-icon {
            margin-right: 15px;
        }
    }
    .u-has-icon-after {
        flos-icon {
            margin-left: 15px;
        }
    }
}

/**
 * Primary text colors - use when the prototype diverts from general typography colors.
 */
.u-color-secondary-2-base,
.u-color-brand {
    color: $color-secondary-2-base !important;
}

.u-color-secondary-4-medium {
    color: $color-secondary-4-medium !important;
}
.u-color-secondary-4-base {
    color: $color-secondary-4-base !important;
}

.u-color-primary-2-base,
.u-color-logo {
    color: $color-primary-2-base !important;
}
.u-color-primary-2-dark {
    color: $color-primary-2-dark !important;
}
.u-color-primary-1-base,
.u-color-indigo {
    color: $color-primary-1-base !important;
}
.u-color-supplement-white-base,
.u-color-white {
    color: $color-supplement-white-base !important;
}
.u-color-supplement-grey-medium,
.u-color-dimmed,
.u-color-grey-700 {
    color: $color-supplement-grey-medium !important;
}

.u-color-supplement-grey-dark {
    color: $color-supplement-grey-dark !important;
}

.u-color-supplement-success-base {
    color: $color-supplement-success-base !important;
}
.u-color-supplement-success-dark {
    color: $color-supplement-success-dark !important;
}
.u-color-supplement-error-base {
    color: $color-supplement-error-base !important;
}
.u-color-supplement-error-dark {
    color: $color-supplement-error-dark !important;
}

/**
 * Primary background colors - use when the prototype diverts from general typography colors.
 */

.u-bg-primary-2-light {
    background-color: $color-primary-2-light !important;
}
.u-bg-secondary-2-base,
.u-bg-brand {
    background-color: $color-secondary-2-base !important;
}
.u-bg-secondary-2-light,
.u-bg-brand-05,
.u-bg-brand-10 {
    background-color: $color-secondary-2-light !important;
}
.u-bg-secondary-2-dark {
    background-color: $color-secondary-2-dark !important;
}
.u-bg-primary-2-base,
.u-bg-logo {
    background-color: $color-primary-2-base !important;
}
.u-bg-primary-1-base,
.u-bg-indigo {
    background-color: $color-primary-1-base !important;
}
.u-bg-secondary-4-base,
.u-bg-ice {
    background-color: $color-secondary-4-base !important;
}
.u-bg-secondary-4-light,
.u-bg-ice-200 {
    background-color: $color-secondary-4-light !important;
}
.u-bg-supplement-grey-light,
.u-bg-grey-200 {
    background-color: $color-supplement-grey-light !important;
}
.u-bg-supplement-grey-base {
    background-color: $color-supplement-grey-base !important;
}
.u-bg-supplement-white-base,
.u-bg-white {
    background-color: $color-supplement-white-base !important;
}
.u-bg-supplement-warning-base,
.u-bg-yellow {
    background-color: $color-supplement-warning-base !important;
}
.u-bg-supplement-success-base {
    background-color: $color-supplement-success-base !important;
}
.u-bg-supplement-success-light {
    background-color: $color-supplement-success-light !important;
}
.u-bg-supplement-error-light {
    background-color: $color-supplement-error-light !important;
}
.u-bg-transparent {
    background-color: transparent !important;
}

// Text Alignment
// Utilities for controlling the alignment of text.
// Flex box utilities
.u-switch-order {
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-md) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
}

.u-flex-box {
    display: flex;
    gap: $spacing-5 $spacing-7;
    flex-wrap: wrap;

    &--nowrap {
        flex-wrap: nowrap;
    }

    &--gap-small {
        gap: $spacing-5 $spacing-5;
    }

    &--gap-xsmall {
        gap: $spacing-3 $spacing-3;
    }

    &--no-gap {
        gap: 0 !important;
    }

    &--row {
        flex-direction: row;
    }

    &--col {
        flex-direction: column;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--center {
        align-items: center;
    }

    &--justify-center {
        justify-content: center;
    }

    &--flex-end {
        justify-content: flex-end;
    }

    &--flex-end-mobile-center {
        justify-content: flex-end;
        align-items: baseline;
    }
    &--end {
        display: flex;
        gap: $spacing-5 $spacing-7;
        justify-content: flex-end;
    }

    @media (min-width: $screen-md) {
        flex-wrap: nowrap;
        &--align-center-desktop {
            align-items: center;
        }
    }
    @media (max-width: $screen-sm-max) {
        &--flex-end-mobile-center {
            justify-content: center;
        }
        &--justify-center-mobile {
            justify-content: center;
        }
        &--direction-column-mobile {
            flex-direction: column;
        }
        &--end {
            flex-wrap: wrap;
        }
    }

    flos-icon {
        flex-shrink: 0;
    }
}

.u-justify-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: $spacing-3;
    @media (min-width: $screen-md) {
        gap: $spacing-7;
    }

    .Box:nth-of-type(2) {
        @media (min-width: $screen-md) {
            width: initial;
        }
    }

    @media (min-width: $screen-md) {
        flex-wrap: unset;
    }
}

.u-full-width-sm {
    @media (max-width: $screen-sm-max) {
        width: 100%;
    }
}

/**
 * Primary spacing inset (padding)
 */
.u-spacing-inset-none {
    padding: 0 !important;
}

.u-spacing-inset-xs {
    padding: $spacing-2 !important;
}

.u-spacing-inset-s {
    padding: $spacing-3 !important;
}

.u-spacing-inset-sm {
    padding: $spacing-4 !important;
}
.u-spacing-inset-m {
    padding: $spacing-5 !important;

    &--squish {
        padding: $spacing-3 $spacing-5 !important;
    }

    &--squish-max {
        padding: 0 $spacing-5 !important;
    }

    &--stretch {
        padding: $spacing-5 $spacing-3 !important;
    }

    &--stretch-max {
        padding: $spacing-5 0 !important;
    }

    &--top {
        padding: $spacing-5 0 0 !important;
    }
}

.u-spacing-inset-l {
    padding: $spacing-5 !important;
    @media (min-width: $screen-md) {
        padding: $spacing-7 !important;
    }
    &--squish {
        padding: $spacing-5 !important;
        @media (min-width: $screen-md) {
            padding: $spacing-5 $spacing-7 !important;
        }
    }

    &--squish-max {
        padding: 0 $spacing-5 !important;
        @media (min-width: $screen-md) {
            padding: 0 $spacing-7 !important;
        }
    }

    &--stretch {
        padding: $spacing-5 !important;
        @media (min-width: $screen-md) {
            padding: $spacing-7 $spacing-5 !important;
        }
    }

    &--stretch-max {
        padding: $spacing-5 0 !important;
        @media (min-width: $screen-md) {
            padding: $spacing-7 0 !important;
        }
    }
}

.u-spacing-inset-xl {
    padding: $spacing-5 !important;
    @media (min-width: $screen-md) {
        padding: $spacing-9 !important;
    }

    &--squish {
        padding: $spacing-7 $spacing-5 !important;
        @media (min-width: $screen-md) {
            padding: $spacing-9 $spacing-7 !important;
        }
    }
}

.u-spacing-inset-xxl {
    padding: $spacing-5 !important;
    @media (min-width: $screen-md) {
        padding: $spacing-11 !important;
    }
}

.u-spacing-inset-squish-m {
    padding: $spacing-3 $spacing-5 !important;
}
/**
 * Primary row spacing stack (margin)
 */

.u-spacing-stack-none {
    margin-bottom: 0 !important;
}

.u-spacing-stack-xs {
    margin-bottom: $spacing-2 !important;
}

.u-spacing-stack-s {
    margin-bottom: $spacing-3 !important;
}

.u-spacing-stack-sm {
    margin-bottom: $spacing-4 !important;
}

.u-spacing-stack-m {
    margin-bottom: $spacing-5 !important;
}

.u-spacing-stack-l {
    margin-bottom: $spacing-7 !important;
}

.u-spacing-stack-xl {
    margin-bottom: $spacing-6 !important;
    @media (min-width: $screen-md) {
        margin-bottom: $spacing-9 !important;
    }
}

.u-spacing-stack-xxl {
    margin-bottom: $spacing-7 !important;
    @media (min-width: $screen-md) {
        margin-bottom: $spacing-11 !important;
    }
}

// borders
.u-border-none {
    border: 0px !important;
}
.u-border-secondary-4-base,
.u-border-ice {
    border: 1px solid $color-secondary-4-base !important;
}

.u-border-secondary-2-light {
    border: 1px solid $color-secondary-2-light !important;
}

.u-border-supplement-grey-base {
    border: 1px solid $color-supplement-grey-base !important;

    &--top {
        border-top: 1px solid $color-supplement-grey-base !important;
    }

    &--bottom {
        border-bottom: 1px solid $color-supplement-grey-base !important;
    }
}

.u-border-supplement-grey-light {
    border: 1px solid $color-supplement-grey-light !important;

    &--top {
        border-top: 1px solid $color-supplement-grey-light !important;
    }

    &--bottom {
        border-bottom: 1px solid $color-supplement-grey-light !important;
    }
}

.u-border-top-secondary-4-base {
    border-top: 1px solid $color-secondary-4-base !important;
}

.u-border-primary-1-base {
    border: 1px solid $color-primary-1-base !important;

    &--top {
        border-top: 1px solid $color-primary-1-base !important;
    }

    &--bottom {
        border-bottom: 1px solid $color-primary-1-base !important;
    }
}

.u-border-primary-2-medium {
    border: 1px solid $color-primary-2-medium !important;
}

.u-border-top-gradient-m--white {
    border-top: 16px solid transparent !important;
    mask-image: linear-gradient(to top, $color-supplement-white-base calc(100% - 16px), transparent);
}

.u-border-dashed {
    border-style: dashed !important;
}

// border-radius
.u-border-radius-none {
    border-radius: 0 !important;
}

.u-border-radius-s {
    border-radius: $border-radius-small !important;
}

.u-border-radius-m {
    border-radius: $border-radius-medium !important;
}

.u-border-radius-l {
    border-radius: $border-radius-large !important;
}

// shadows
.u-shadow-none {
    box-shadow: none !important;
}

.u-shadow-s {
    box-shadow: $shadow-small !important;
}

.u-shadow-m {
    box-shadow: $shadow-medium !important;
}

.u-shadow-l {
    box-shadow: $shadow-large !important;
}

// dots
.u-dot {
    height: $spacing-3;
    width: $spacing-3;
    background-color: $color-supplement-error-base;
    border-radius: 50%;
    display: inline-block;
}

.u-scrollbar {
    scrollbar-color: $color-primary-1-base $color-supplement-grey-base;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $spacing-2;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-primary-1-base;
    }

    &::-webkit-scrollbar-track {
        background: $color-supplement-grey-base;
    }
}

// grids
.u-scrollable-row {
    display: flex;
    gap: $spacing-3;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
        height: $spacing-3;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
        border-radius: $border-radius-small;
    }

    /* browser decide if x is ok to scroll and show bars on, y hidden */
    overflow: auto hidden;
    /* prevent scroll chaining on x scroll */
    overscroll-behavior-x: contain;
    /* scrolling should snap children on x */
    scroll-snap-type: x mandatory;

    @media (any-hover: none) {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    > * {
        margin: 0 0 $spacing-3 0;
    }
}

.u-custom-scroll-bar {
    &::-webkit-scrollbar {
        width: $spacing-3;
    }
    &::-webkit-scrollbar-track {
        background-color: $color-supplement-grey-base;
        border-radius: $spacing-3;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-secondary-4-base;
        border-radius: $spacing-3;
    }
}

.u-floating-cta {
    position: fixed;
    bottom: $spacing-5;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;

    @media (min-width: $screen-md) {
        bottom: $spacing-7;
        right: $spacing-7;
        left: auto;
        transform: none;
    }
}
