//
// Responsive: Utility classes
// --------------------------------------------------

// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: https://getbootstrap.com/docs/3.4/getting-started/#support-ie10-width
// Source: https://timkadlec.com/2013/01/windows-phone-8-and-device-width/
// Source: https://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

$spacings-map: (
    'none': 0,
    2: $spacing-2,
    3: $spacing-3,
    4: $spacing-4,
    5: $spacing-5,
    6: $spacing-6,
    7: $spacing-7,
    8: $spacing-8,
    9: $spacing-9,
    10: $spacing-10,
    11: $spacing-11,
    12: $spacing-12,
    13: $spacing-13,
);
$directions: top, right, bottom, left;

// Visibility utilities
// Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0

@include responsive-invisibility('.visible-xs');
@include responsive-invisibility('.visible-sm');
@include responsive-invisibility('.visible-md');
@include responsive-invisibility('.visible-lg');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: $screen-xs-max) {
    @include responsive-visibility('.visible-xs');
}

.visible-xs-block {
    @media (max-width: $screen-xs-max) {
        display: block !important;
    }
}

.visible-xs-inline {
    @media (max-width: $screen-xs-max) {
        display: inline !important;
    }
}

.visible-xs-inline-block {
    @media (max-width: $screen-xs-max) {
        display: inline-block !important;
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility('.visible-sm');
}

.visible-sm-block {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        display: block !important;
    }
}

.visible-sm-inline {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        display: inline !important;
    }
}

.visible-sm-inline-block {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        display: inline-block !important;
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility('.visible-md');
}

.visible-md-block {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        display: block !important;
    }
}

.visible-md-inline {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        display: inline !important;
    }
}

.visible-md-inline-block {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        display: inline-block !important;
    }
}

@media (min-width: $screen-lg) {
    @include responsive-visibility('.visible-lg');
}

.visible-lg-block {
    @media (min-width: $screen-lg) {
        display: block !important;
    }
}

.visible-lg-inline {
    @media (min-width: $screen-lg) {
        display: inline !important;
    }
}

.visible-lg-inline-block {
    @media (min-width: $screen-lg) {
        display: inline-block !important;
    }
}

@media (max-width: $screen-xs-max) {
    @include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg) {
    @include responsive-invisibility('.hidden-lg');
}

// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0

@include responsive-invisibility('.visible-print');

@media print {
    @include responsive-visibility('.visible-print');
}

.visible-print-block {
    display: none !important;

    @media print {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;

    @media print {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;

    @media print {
        display: inline-block !important;
    }
}

@media print {
    @include responsive-invisibility('.hidden-print');
}

@mixin visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
}
.u-visually-hidden {
    @include visually-hidden();
}

// display
$display-properties: none, block, inline-block, flex, inline-flex, grid, inline-grid, table, inline-table, table-row-group;
@each $property in $display-properties {
    .u-display-#{$property} {
        @include create-mq {
            display: $property !important;
        }
    }
}

// flex direction
$flex-directions: row, row-reverse, column, column-reverse;
@each $direction in $flex-directions {
    .u-flex-#{$direction} {
        @include create-mq {
            flex-direction: $direction !important;
        }
    }
}

// gap
@each $size, $value in $spacings-map {
    .u-gap-#{$size} {
        @include create-mq {
            gap: $value !important;
        }
    }
    .u-gap-x-#{$size} {
        @include create-mq {
            column-gap: $value !important;
        }
    }
    .u-gap-y-#{$size} {
        @include create-mq {
            row-gap: $value !important;
        }
    }
}

// flex justify content
$flex-justify-content-map: (
    'normal': normal,
    'start': flex-start,
    'end': flex-end,
    'center': center,
    'between': space-between,
    'around': space-around,
    'evenly': space-evenly,
    'stretch': stretch,
);
@each $key, $value in $flex-justify-content-map {
    .u-flex-justify-#{$key} {
        @include create-mq {
            justify-content: $value !important;
        }
    }
}

// flex align items
$flex-align-items-map: (
    'start': flex-start,
    'end': flex-end,
    'center': center,
    'baseline': baseline,
    'stretch': stretch,
);
@each $key, $value in $flex-align-items-map {
    .u-flex-items-#{$key} {
        @include create-mq {
            align-items: $value !important;
        }
    }
}

// paddings and margins
@each $size, $value in $spacings-map {
    .u-spacing-stack-#{$size} {
        @include create-mq {
            margin: $value !important;
        }
    }
    .u-spacing-inset-#{$size} {
        @include create-mq {
            padding: $value !important;
        }
    }
    @each $direction in $directions {
        .u-spacing-stack-#{$direction}-#{$size} {
            @include create-mq {
                margin-#{$direction}: $value !important;
            }
        }
        .u-spacing-inset-#{$direction}-#{$size} {
            @include create-mq {
                padding-#{$direction}: $value !important;
            }
        }
    }
}

// borders
@each $direction in $directions {
    .u-border-#{$direction}-none {
        @include create-mq {
            border-#{$direction}: none !important;
        }
    }
}
@each $direction in $directions {
    .u-border-#{$direction}-supplement-grey-base {
        @include create-mq {
            border-#{$direction}: 1px solid $color-supplement-grey-base !important;
        }
    }
}

// text-alignment
$text-alignments: left, center, right, justify, start, end;
@each $alignment in $text-alignments {
    .u-text-#{$alignment} {
        @include create-mq() {
            text-align: $alignment !important;
        }
    }
}

// white-space
$white-spaces: normal, nowrap, pre, pre-line, pre-wrap, break-spaces;
@each $prop in $white-spaces {
    .u-whitespace-#{$prop} {
        @include create-mq() {
            white-space: $prop !important;
        }
    }
}

// table-layout
$table-layouts: auto, fixed;
@each $prop in $table-layouts {
    .u-table-#{$prop} {
        @include create-mq() {
            table-layout: $prop !important;
        }
    }
}
