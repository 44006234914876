$StepNav-height: 56px;
$StepNav-height-sticky: 36px;

.StepNav {
    //$icon-tick-white: "%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M7%2014.7L1.1%209.5l1.8-2.1%203.5%203.2%206.1-8.9%202.3%201.5%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E";
    $nav-divider: '%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2056%22%3E%3Cpath%20fill%3D%22%23676767%22%20fill-rule%3D%22evenodd%22%20d%3D%22M13%2028L0%200h1l13%2028L1%2056H0l13-28z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E';
    $counter: 'stepnav';

    display: flex;
    padding: 0;
    margin: 0;
    color: $color-secondary-4-base;
    font-weight: 500;
    list-style: none;
    white-space: nowrap;
    font-size: $size-font-base;
    align-items: center;
    counter-reset: #{$counter};

    height: $StepNav-height;
    line-height: $StepNav-height;

    li {
        font-size: $size-font-base;
    }

    .is-animated & {
        transition: height 0.4s, line-height 0.4s;
    }

    .is-sticky & {
        height: $StepNav-height-sticky;
        line-height: $StepNav-height-sticky;
        font-size: $size-font-xxsmall;

        .StepNav-item {
            font-size: $size-font-xxsmall;
            text-transform: uppercase;

            &::before {
                line-height: 19px;
                height: 20px;
                width: 20px;
                background-size: 12px auto;
            }
        }
    }

    .no-flexbox & {
        .StepNav-item {
            width: 33%;
        }
    }

    a,
    span {
        color: inherit;
        line-height: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    &-item {
        display: inline-block;
        position: relative;
        cursor: default;
        overflow: hidden;
        padding-right: 30px;
        color: $color-supplement-grey-dark;
        text-overflow: ellipsis;
        text-align: center;
        flex: 1 1 auto;
        background: url(#{$svg-data-uri-prefix},#{$nav-divider}) no-repeat right / 14px 56px;

        .is-animated & {
            transition: color 0.2s;
        }

        &::before {
            display: inline-block;
            width: 30px;
            height: 30px;
            counter-increment: stepnav;
            content: counter(#{$counter});
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
            text-align: center;
            line-height: 27px;
            margin-right: 10px;

            .is-animated & {
                transition: width 0.3s, height 0.3s, line-height 0.3s;
            }
        }

        &:last-child {
            margin-right: 0;
            background: none;
        }

        &:hover,
        &:active {
            //color: $color-supplement-white-base;
        }

        &.is-active {
            color: $color-supplement-white-base;
        }

        &.is-completed {
            color: $color-supplement-white-base;

            &::before {
                content: '\a0'; // Needed for line-height to work.
                border-color: transparent;
                background-color: $color-supplement-success-base;
                background-repeat: no-repeat;
                background-position: 50% center;
                background-size: 16px auto;
                @extend .ico-tick-white;
            }
        }
    }
}
