a.Box {
    &:hover {
        background-color: $color-secondary-4-light;
    }

    &:active {
        background-color: $color-secondary-4-base;
    }

    &.is-highlighted {
        background-color: $color-secondary-4-light;
        &:hover {
            background-color: $color-secondary-4-base;
        }
    }

    &.Box--indigo:hover {
        background-color: $color-secondary-2-base;
    }

    p {
        font-weight: 300;
    }

    h4 {
        margin-bottom: 0;
    }
}
.Box--row {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;

    @media (max-width: $screen-sm) {
        flex-direction: column;
    }
    & > .Box {
        margin: 0;
    }

    .Box {
        display: flex;

        & > flos-icon {
            align-self: center;
            flex-shrink: 0;
            margin-right: $spacing-5;
        }

        &:first-child:nth-last-child(1) {
            justify-content: center;
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ .Box:first-child {
            justify-content: flex-end;
        }
    }
}
