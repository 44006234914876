.site-footer {
    background: $footer-bg;
    padding-top: $spacing-9;

    @media (min-width: $screen-md) {
        padding-top: $spacing-12;
    }

    .flos-grid {
        gap: 32px;
        @media (min-width: $screen-md) {
            gap: 32px;
        }
    }

    h5 {
        color: $color-supplement-white-base;
        position: relative;
        margin-bottom: $spacing-5;
        padding: 0 $spacing-5 $spacing-5 $spacing-5;
        border-bottom: 1px solid $footer-link-color;
    }

    ul {
        list-style: none;
        padding: 0 $spacing-5;
        margin: 0;
    }

    li {
        margin: 0;
        padding: 0;
        line-height: $size-lineheight-medium;
        margin-bottom: $spacing-5;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: block;
        }
    }
    .card {
        // We use Card's in the footer content on Mit - this it to make sure they are equal height.
        height: 100%;
    }
}
.site-footer-content {
    p:last-child {
        margin-bottom: 0;
    }
}
.ContactBar {
    display: flex;
    flex-direction: column;
    gap: $spacing-7;
    justify-content: space-between;
    font-size: $size-font-xsmall;
    padding-top: $spacing-7;
    padding-bottom: $spacing-7;
    margin-top: $spacing-9;
    border-top: 1px solid $footer-link-color;

    @media (min-width: $screen-sm) {
        flex-direction: row;
        align-items: center;
    }

    @media (min-width: $screen-md) {
        margin-top: $spacing-12;
    }

    &-address {
        font-style: normal;
        margin-bottom: 0;
        font-size: $size-font-xxsmall;
        line-height: $size-lineheight-small;
        color: $color-supplement-white-base;
        font-weight: 500;

        span {
            display: block;
            margin-right: $spacing-4;
        }
    }

    &-links {
        line-height: $size-lineheight-small;
        margin-bottom: 0;
        order: -1;

        a {
            margin-bottom: $spacing-5;
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: $screen-sm) {
            margin-left: auto;
            order: 0;
            a {
                display: inline-block;
                margin: 0 0 0 $spacing-6;
            }
        }
    }
}
.site-footer-link {
    display: block;
    font-weight: 300;
    font-size: $size-font-xsmall;
    line-height: 18px;
    color: $footer-link-color;
    &:hover {
        color: $footer-link-color;
        text-decoration: underline;
    }
}
