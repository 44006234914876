// The rest of the radio styling is in the radi0 component folder
.checkbox,
.radio {
    color: $color-secondary-2-dark;
    font-weight: 300;
    user-select: none;
    cursor: pointer;
    display: flex;

    border: 1px solid $color-primary-2-light;
    border-radius: 4px;
    padding: $spacing-3 10px;

    & > .dot {
        position: relative;
        margin-right: $spacing-5;

        border: 1px solid $color-secondary-2-base;
        background-color: transparent;
        transition: border 0.2s;
        svg {
            stroke: transparent;
        }
    }

    div {
        p.error-text {
            font-size: $size-font-xxsmall;
            color: $color-supplement-error-base;
            margin-top: -6px;
            margin-bottom: 0;
        }
        p small {
            font-size: $size-font-xsmall;
            font-weight: 300;
        }
    }
}

.checkbox {
    &.has-error {
        & > .dot {
            border-color: $color-supplement-error-base;
            background-color: $color-supplement-error-base;
        }
    }
    &.has-success {
        & > .dot {
            border-color: $color-supplement-success-base;
            background-color: $color-supplement-success-base;
        }
    }

    &.is-disabled {
        & > .dot {
            border-color: $color-supplement-grey-dark;
            background-color: $color-supplement-grey-dark;
        }
    }
}

input[type='checkbox']:checked + .checkbox {
    & > .dot svg {
        stroke: $color-secondary-2-base;
    }

    &.has-error,
    &.has-success,
    &.is-disabled {
        & > .dot {
            svg {
                stroke: $color-supplement-white-base;
            }
        }
    }
}

label {
    &.radio,
    &.checkbox {
        border: 1px solid $color-secondary-4-base;
        border-radius: 2px;
        font-weight: 400;
        padding: $spacing-5;

        &:hover {
            background-color: $color-secondary-2-light;
            border-color: $color-secondary-2-light;
            color: $color-primary-1-base;
        }

        &:focus,
        &.active {
            background-color: $color-secondary-2-light;
            border-color: $color-primary-2-medium;
            color: $color-primary-1-base;
        }

        &.has-error {
            border-color: $color-supplement-error-light;
        }

        &.is-required::after {
            content: '*';
            margin-left: 2px;
            background-color: transparent;
        }

        &.is-disabled {
            color: $color-supplement-grey-dark;
            border-color: $color-supplement-grey-medium;
        }
    }
}

// Old markup fix
.checkbox {
    flos-icon {
        display: none;
    }

    &::before {
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        margin-right: $spacing-5;
        vertical-align: 0;
        content: '';
        border: 1px solid $color-secondary-2-base;
        background-color: transparent;
        transition: 0.2s;
        flex-shrink: 0;
        background-clip: content-box;
        padding: 1px;
    }

    &:hover::before {
        border-color: $color-secondary-2-base;
    }

    &.has-error {
        &::before {
            border-color: $color-secondary-2-base !important;
        }
    }
    &.has-success {
        &::before {
            border-color: $color-secondary-2-base !important;
        }
    }
    &.is-disabled {
        &::before {
            border-color: $color-supplement-grey-dark !important;
        }
    }
}

input[type='checkbox']:checked + .checkbox {
    background-color: $color-secondary-2-light;
    border-color: $color-primary-2-medium;
    color: $color-primary-1-base;
    &::before {
        @extend .ico-tick-white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: $spacing-5 $spacing-5;
        background-color: $color-secondary-2-base;
    }
    &.has-error {
        &::before {
            background-color: $color-secondary-2-base !important;
            border-color: $color-secondary-2-base !important;
            @extend .ico-tick-white;
        }
    }
    &.has-success {
        &::before {
            background-color: $color-secondary-2-base !important;
            border-color: $color-secondary-2-base !important;
            @extend .ico-tick-white;
        }
    }
    &.is-disabled {
        &::before {
            background-color: $color-supplement-grey-dark !important;
            border-color: $color-supplement-grey-dark !important;
            @extend .ico-tick-white;
        }
    }
}

.checkbox {
    &::after {
        width: $spacing-5;
        height: $spacing-5;
        top: 6px;
        left: 2px;
        background: $color-supplement-white-base;
    }

    &--is-required &-label {
        &::after {
            content: '\00a0*';
            // color: $color-supplement-error-base;
            font-size: 16px;
            line-height: 1;
        }
    }

    input[type='checkbox']:checked + &::after,
    input[type='checkbox']:checked + &::after {
        opacity: 0;
    }
    flos-icon {
        margin-left: 10px;
        margin-bottom: 5px;
    }
}
