.Callout {
    padding: $spacing-6;
    margin-bottom: $spacing-6;
    background-color: $color-supplement-white-base;

    &:first-of-type {
        margin-bottom: 0;
    }

    .btn {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    &--highlighted {
        background-color: $color-secondary-2-base;
        color: $color-supplement-white-base;
        a {
            color: $color-supplement-white-base;
        }
    }

    &--highlighted--blue {
        background-color: $color-primary-1-base;
        color: $color-supplement-white-base;
        a {
            color: $color-supplement-white-base;
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    &--white-box {
        background-color: $color-supplement-white-base;
    }
}
