.PageHeader {
    color: $color-primary-1-base;
    background: $color-secondary-4-base;
    border-top-color: $color-primary-2-base;
    position: relative;

    &[id] {
        scroll-margin-top: 100px;
    }

    &-content {
        position: relative;
        padding-top: $spacing-11;
        padding-bottom: $spacing-11;
        @media (max-width: $screen-sm-max) {
            padding: $spacing-7 $spacing-5;
        }
    }

    &-header {
        margin: 0;
        word-break: break-word;
        max-width: 100%;
    }

    &-body {
        margin-top: 3vmin;

        :last-child {
            margin-bottom: 0;
        }

        p.bodytext {
            color: $color-supplement-white-base;
        }
    }

    &-nav {
        padding-bottom: $spacing-5;
    }
}
.PageHeader-body .Box {
    min-height: 144px;
}
