.streamer {
    background-color: $color-secondary-4-base;
    color: $color-primary-1-dark;
    padding: $spacing-13 0;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-image {
        width: 50%;
        padding-left: $spacing-13;

        img {
            min-height: 368px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: $border-radius-medium;
        }
    }

    &-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .streamer-label {
            margin-bottom: $spacing-3;
        }

        .h2.streamer-title {
            @media (min-width: $screen-md) {
                line-height: 48px;
            }
        }

        .streamer-text {
            margin-bottom: $spacing-7;

            :last-child {
                margin-bottom: 0;
            }
        }

        .streamer-action {
            display: flex;
            flex-wrap: wrap;
            gap: $spacing-5;
            align-content: space-around;
            .flos-button {
                margin: 0;
            }
        }

        /** When no image on streamer */
        &.streamer-no-image {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: $spacing-7;

            .streamer-text {
                margin-bottom: 0;
            }

            .streamer-action {
            }

            &.streamer-no-text {
                justify-content: space-around;
                .streamer-text {
                    @media (min-width: $screen-md) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    /** mobile */
    @media (max-width: $screen-sm-max) {
        padding: 0;

        .container {
            flex-direction: column;
            flex-direction: column-reverse;
            padding-left: 0;
            padding-right: 0;
        }

        .streamer-image {
            width: 100%;
            padding: 0 0 0 $spacing-5;
            img {
                min-height: 208px;
                border-radius: 0 0 0 $border-radius-medium;
            }
        }
        .streamer-content {
            width: 100%;
            padding: $spacing-7 $spacing-5;

            .streamer-text {
                margin-bottom: $spacing-7;
            }

            /** When no image on streamer */
            &.streamer-no-image {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                gap: 0;

                .streamer-text {
                    text-align: center;
                    * {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    /** Image directions applicable only on desktop */
    @media (min-width: $screen-md) {
        &--left {
            .container {
                flex-direction: row-reverse;
            }

            .streamer-image {
                padding-left: 0;
                padding-right: $spacing-13;
            }
        }
    }

    /** Themes */
    &--primary {
        background-color: $color-primary-1-base;
        color: $color-supplement-white-base;

        .flos-button--secondary {
            color: $color-supplement-white-base;
        }
    }

    &--white {
        background-color: $color-supplement-white-base;
    }

    /** Sizes */
    &--small {
        padding: $spacing-7 0;

        @media (max-width: $screen-sm-max) {
            padding: 0;
        }
    }
}
