.Box--ksp {
    margin: $spacing-5 0;

    &__container {
        &.has-banner {
            @media (max-width: $screen-xs-max) {
                margin-top: 20px;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 20px;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    &__header {
        display: table;
        min-height: 40px;

        h4 {
            display: table-cell;
            vertical-align: top;

            @media (min-width: $screen-sm) {
                vertical-align: middle;
            }

            @media (max-width: $screen-xs-max) {
                vertical-align: middle;
                margin-bottom: 0 !important;
            }
        }
    }

    &__content {
        @extend .clearfix;
        display: block;
        padding: 15px 0 40px 0;

        @media (max-width: $screen-xs-max) {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 30px;
        }
    }

    &__icon ~ &__header {
        padding-left: 60px;

        @media (max-width: $screen-xs-max) {
            padding-left: 0;
        }
    }

    &__tag {
        top: -15px;
        right: 30px;
        display: table;
        text-align: center;
        color: $color-secondary-2-base;
        position: absolute;
        height: 26px;
        z-index: 10;
        width: 154px;

        @media (max-width: $screen-xs-max) {
            left: calc(50% - 99px);
            right: inherit;
        }

        span {
            display: table-cell;
            text-transform: uppercase;
            white-space: nowrap;
            vertical-align: middle;
            background-color: $color-primary-1-base;
            font-weight: bold;
            font-size: $size-font-base;
            padding: 5px 10px 5px 10px;
        }
    }
}
