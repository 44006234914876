.flos-table {
    width: 100%;

    caption,
    tr,
    th {
        background-color: inherit;
    }

    tr {
        &:last-child {
            border-bottom: 1px !important; //to make sure it works if a dashed table is inside a solid border table
        }
    }

    &--white {
        caption,
        th,
        td {
            background-color: $color-supplement-white-base;
        }
    }

    &--dashed {
        caption,
        tr,
        th {
            border-bottom: 1px dashed $color-secondary-2-medium !important; //to make sure it works if a dashed table is inside a solid border table
        }
    }

    &--solid {
        caption,
        tr,
        th {
            border-bottom: 1px solid $color-supplement-grey-base;
        }
    }

    tr {
        &:last-child {
            border-bottom: 1px;
        }
    }

    caption,
    td,
    th {
        text-align: left;
        padding: $spacing-5;
    }

    &--loose {
        caption,
        td,
        th {
            padding: $spacing-7;
        }
        @media (max-width: $screen-sm-max) {
            caption,
            td,
            th {
                padding: $spacing-5;
            }
            table,
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            td {
                border-bottom: 1px solid $color-secondary-4-base;
                &:last-child {
                    border-bottom: 1px;
                }
            }

            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            td {
                text-align: right;
            }

            caption {
                margin-bottom: $spacing-5;
                border-bottom: none;
            }

            tr {
                border-bottom: $spacing-5 solid transparent;
            }

            caption,
            tr {
                td:first-child {
                    text-align: left;
                    font-size: $size-font-small;
                    line-height: $size-lineheight-large;
                    font-weight: 500;
                }
            }
        }
    }

    &--align-edges {
        caption,
        td,
        th {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (max-width: $screen-sm-max) {
        td[data-label] {
            /* Behave  like a "row" */
            position: relative;
            padding-left: 50%;
            &:first-child {
                padding-left: $spacing-5;
            }
        }

        td[data-label]:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: $spacing-5;
            left: $spacing-5;
            padding-right: $spacing-5;
            white-space: nowrap;
            content: attr(data-label);
            font-weight: 500;
        }
    }
}
.basictable {
    background-color: $color-supplement-white-base;
    margin-bottom: $spacing-6;
    th {
        font-size: $size-font-small;
    }
    td {
        font-size: $size-font-base;
        &.half-width {
            display: inline-block;
            width: 50%;
            &:last-child {
                padding-left: 0;
            }
        }
    }
    td,
    th {
        font-weight: normal;
        border-bottom: 1px dashed $color-supplement-grey-base;
        padding: $spacing-5;
        text-align: right;
        &:first-child {
            text-align: left;
        }
    }
    @media (max-width: $screen-sm-max) {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr {
            border-bottom: 1px dashed $color-supplement-grey-base;
        }
        td {
            /* Behave  like a "row" */
            border: none;
            position: relative;
            padding-left: 50%;
            &:first-child {
                padding-left: 16px;
            }
        }
        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 16px;
            left: 16px;
            padding-right: 10px;
            white-space: nowrap;
            content: attr(data-label);
        }
    }
}

.contenttable {
    $padding-x-contenttable-cell: 20px;
    $padding-y-contenttable-cell: 14px;
    $padding-x-contenttable-cell-mobile: 15px;
    $padding-y-contenttable-cell-mobile: 10px;
    $border-color: #e0e0e0 !default;
    $border-color-trans: adjust_color(#e0e0e0, $alpha: -0.5);
    $col-bg: #cad7e0;
    $col-bg-dark: $color-secondary-2-base;
    $col-bs-gray-dark: $color-supplement-grey-dark;
    $col-bg-even: $color-supplement-grey-light !default;
    $col-bg-odd: $color-supplement-white-base !default;
    $tfoot-bg: $col-bg !default;

    position: relative;
    border-bottom: 1px solid $color-secondary-2-base;
    table-layout: fixed;

    &--center {
        margin-left: auto;
        margin-right: auto;
    }

    &--full {
        width: 100%;
    }

    // Table fixed width so overflow-ellipsis class will work on the table content
    &--ellipsis {
        table-layout: fixed;
    }

    &--rowheader {
        tbody {
            @media (min-width: 767px) {
                tr:first-child {
                    line-height: 4px;
                }
            }

            tr {
                &:first-child td {
                    color: $color-supplement-white-base !important;
                    background: $color-primary-1-base !important;
                }

                &:nth-child(even) {
                    td {
                        background: $color-supplement-grey-base;
                    }
                }

                &:nth-child(odd):not(:first-child) {
                    td {
                        background: #f9f9f9;
                    }
                }

                @media (max-width: 767px) {
                    &:not(:first-child) td:first-child {
                        background: $col-bg !important;
                    }

                    &:nth-child(even) {
                        td:not(:first-child) {
                            background: $color-supplement-grey-base;
                        }
                    }

                    &:nth-child(odd):not(:first-child) {
                        td:not(:first-child) {
                            background: #f9f9f9;
                        }
                    }
                }
            }
        }
    }

    &--simple {
        width: 100%;

        thead tr th {
            border: none !important;
            font-weight: 900;
            text-align: left;

            &.topdkinsurancedetails_option {
                border-right: none !important;
            }

            &.topdkinsurancedetails_option-sum {
                text-align: right;
            }
        }
    }

    //Vertical borders
    tbody tr:first-child td,
    tbody tr:first-child th,
    tfoot tr:first-child td,
    tfoot tr:first-child th,
    tr + tr td,
    tr + tr th {
        border-top: none;
    }

    //Horizontal borders
    td,
    th {
        + td {
            border-left: 1px dashed $color-secondary-2-base;
        }

        + th {
            border-left: none;
        }
    }

    th,
    tfoot th,
    tfoot td {
        + th,
        + td {
            border-left-color: $border-color-trans;
        }
    }

    caption {
        color: $color-supplement-white-base;
        text-align: left;
        background: $col-bg-dark;
    }

    caption,
    th,
    td {
        padding: $padding-y-contenttable-cell-mobile $padding-x-contenttable-cell-mobile;
        font-weight: 400;

        &:empty {
            padding: 0;

            + td,
            + th {
                border-top: none;
            }
        }
    }

    thead {
        th {
            color: $color-primary-1-base;
            text-align: left;
            background: $col-bg;
            text-transform: uppercase;
        }

        tr:first-child th {
            &:first-child {
                border-top-left-radius: 0;
            }

            &:last-child {
                border-top-right-radius: 0;
            }
        }
    }

    tbody {
        color: $color-secondary-2-base;

        th,
        td {
            text-align: left;
            font-weight: 300;
            background: $col-bg-even;
        }

        tr {
            &.caption {
                th,
                td {
                    background-color: $color-secondary-2-base;
                    border-top: none;
                    color: $color-supplement-white-base;

                    &:first-child {
                        border-top-left-radius: 0;
                    }

                    &:last-child {
                        border-top-right-radius: 0;
                    }
                }
            }

            &.header {
                th,
                td {
                    color: white;
                    background-color: rgba(63, 122, 153, 0.75);
                }
            }

            &.strong {
                th,
                td {
                    background-color: yellow;
                }
            }
        }

        tr.tsgroup th,
        tr.tsgroup td {
            background-color: $col-bs-gray-dark !important;
            color: $color-supplement-white-base;
            padding-bottom: 3px;
            padding-top: 3px;
            border-left: $col-bs-gray-dark;
        }

        @media (max-width: 767px) {
            tr.tsgroup th {
                margin-top: 5px;
            }

            tr.tsgroup td {
                display: none;
            }
        }
    }

    tfoot {
        td {
            background: $tfoot-bg;
            color: $color-primary-1-base;
        }
    }

    &__media {
        @media (min-width: $screen-md) {
            display: table;
            width: 100%;

            &__media-body {
                display: table-cell;
                width: auto;
            }

            &__media-right {
                display: table-cell;
                width: 1%;

                &--middle {
                    vertical-align: middle;
                }
            }
        }
    }

    &__cta-link {
        display: inline-block;
        white-space: nowrap;

        @media (min-width: $screen-md) {
            margin-left: 40px;
        }
    }

    &__mobile-th {
        display: none;
        color: $color-primary-1-base;
    }

    @media (min-width: $screen-sm) {
        /*
		*	Column content classes.
		*/
        .col {
            &-nowrap {
                white-space: nowrap;
            }

            &-number {
                text-align: right;
                white-space: nowrap;
            }
        }

        caption,
        th,
        td {
            padding: $padding-y-contenttable-cell $padding-x-contenttable-cell;
        }

        thead {
            tr:first-child th,
            tr:last-child th {
                background-color: $col-bg;
            }
        }

        tbody {
            tr {
                th {
                    color: $color-primary-1-base;
                    background: $col-bg;
                }

                &:nth-child(odd) {
                    td {
                        background: $col-bg-odd;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        width: 100%;

        td,
        th {
            display: block;

            //Cells are stacked, so no need for left border
            border-left: none !important;

            &:first-child {
                background-color: $color-secondary-4-base !important;
                color: $color-secondary-2-base !important;
                border-top: none !important;

                + td,
                + th {
                    border-top: none;
                }
            }
        }

        thead {
            tr {
                display: none;

                &:first-child:not(:last-child) {
                    display: block;

                    th {
                        background: $col-bg;

                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }

        tbody {
            tr:not(:first-child) {
                td:first-child,
                th:first-child {
                    margin-top: 0;
                }
            }

            td:nth-child(odd) {
                background: $color-supplement-grey-light;
            }

            td,
            th {
                + th,
                + td {
                    background-color: $color-supplement-white-base;
                }
            }

            th {
                color: $color-primary-1-base;
                background: $col-bg;
            }

            td {
                &:first-child {
                    color: $color-supplement-white-base;
                    background: $col-bg;
                }

            }
            td[data-header] {
                padding-left: 50%;
                position: relative;
                &::before {
                    content: attr(data-header);
                    position: absolute;
                    left: 0;
                    width: 50%;
                    padding-left: 15px;
                    color: $color-primary-1-base;
                    font-weight: 400;
                    font-size: $size-font-xxsmall;
                }
            }
        }

        tfoot {
            td:first-child,
            th:first-child {
                margin-top: 5px;
            }

            tr:nth-child(odd) td:not(:first-child) {
                background: $color-primary-1-base;
                color: #fff;
            }
        }

        &__mobile-th {
            display: block;
            text-transform: uppercase;
        }
    }

    .contenttable__expander {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        background-color: transparent;

        .ico {
            background-color: transparent;
        }

        .contenttable__expander__link {
            display: block;
            text-align: center;
            padding: $padding-y-contenttable-cell-mobile $padding-x-contenttable-cell-mobile;
            background: linear-gradient(to bottom, rgba($col-bg-odd, 0.5) 0%, rgba($col-bg-odd, 1) 75%);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            @media (min-width: $screen-sm) {
                padding: $padding-y-contenttable-cell $padding-x-contenttable-cell;
            }

            .ico {
                width: 14px;
                height: 8px;
                margin-right: 3px;
            }
        }

        &.contenttable__expander--expanded {
            display: none;
        }
    }

    &__list {
        list-style: none;
        margin: (-$padding-y-contenttable-cell-mobile) (-$padding-x-contenttable-cell-mobile);
        padding: 0;

        @media (min-width: $screen-sm) {
            margin: (-$padding-y-contenttable-cell) (-$padding-x-contenttable-cell);
        }

        &__item {
            padding: $padding-y-contenttable-cell-mobile $padding-x-contenttable-cell-mobile;

            @media (min-width: $screen-sm) {
                padding: $padding-y-contenttable-cell $padding-x-contenttable-cell;
            }
        }

        &__item + &__item {
            border-top: 1px solid $border-color;
        }
    }

    &--machineinsurance {
        tr {
            td:nth-child(5) {
                white-space: nowrap;
            }
        }

        @include media-match(mobile) {
            tr {
                td {
                    &:nth-child(5) {
                        text-align: left !important;
                    }
                }
            }

            tr.remark-text {
                .contenttable {
                    &__mobile-th {
                        display: none !important;
                    }
                }
            }
        }

        @include media-match(tablet, up) {
            td,
            th {
                vertical-align: top;
            }
        }

        @include media-match(desktop, up) {
            th,
            td {
                &:nth-child(1) {
                    width: 240px;
                }

                &:nth-child(2) {
                    width: 240px;
                }

                &:nth-child(3) {
                    width: 220px;
                }

                &:nth-child(4) {
                    width: 240px;
                }

                &:nth-child(5) {
                    width: 170px;
                }
            }
        }
    }
}

table.table {
    margin: 20px 0;

    &.table-striped {
        & > tbody > tr {
            &:nth-of-type(odd) {
                background-color: $color-supplement-white-base;
            }

            &:nth-of-type(even) {
                background-color: $color-supplement-grey-light;
            }
        }
    }

    &.table--dark {
        thead tr th {
            background-color: $color-secondary-2-base !important;
        }

        a {
            color: $color-secondary-2-base;
        }
    }

    thead tr {
        line-height: 30px !important;

        th {
            font-size: $size-font-small;
            font-weight: normal;
            border-bottom: 1px solid $color-supplement-grey-light;

            &:first-child {
                border-right: 1px solid $color-supplement-grey-light !important;
            }
        }
    }

    tbody tr td {
        font-size: $size-font-small;
        font-weight: normal;
        line-height: 30px !important;

        &:first-child {
            border-right: 1px solid $color-supplement-grey-light;
        }

        border-bottom: 1px solid $color-supplement-grey-light;
        border-top: 0 !important;

        a {
            font-weight: bold;
        }
    }
}

.receipttable-wrapper {
    background: $color-supplement-white-base;
    padding: 0;

    .check {
        &:before {
            content: '\2714';
        }
    }

    .cross {
        &:before {
            content: '\2716';
        }
    }

    .list-group {
        border-top: 0;
        border-bottom: 0;

        .list-group-item {
            &:first-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &:last-child {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .tablewrapper {
        border: 1px solid $color-supplement-grey-light;
        padding: 0;

        .receipttable {
            width: 100%;

            caption {
                font-weight: 700;
                font-size: $size-font-small;
                padding: 20px 15px;
            }

            thead {
                tr {
                    th {
                        text-align: right;
                        padding: 20px;
                        font-size: $size-font-base;
                    }
                }
            }

            tbody {
                tr {
                    border-top: 1px solid $color-supplement-grey-light;

                    td:first-child {
                        text-align: left;
                        max-width: 300px;
                    }

                    td {
                        text-align: right;
                        padding: 20px;
                    }
                }
            }
        }
    }
}
