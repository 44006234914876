.Box--promo {
    height: 190px;
    display: block;
    min-width: 190px;
    cursor: pointer;
    transition: background 0.4s, color 0.4s;
    margin: $spacing-5 0;

    @media (max-width: $screen-xs-max) {
        height: 160px;
        margin-left: $spacing-5;
        margin-right: $spacing-5;
        width: calc(100% - (#{$spacing-5} * 2));
    }

    &:hover {
        background-color: $color-secondary-4-base;
    }

    &.is-active {
        background-color: $color-secondary-2-base;
        color: $color-supplement-white-base;
        .Box-header {
            color: $color-supplement-white-base;
        }
        box-shadow: none;
    }

    &_tag {
        display: table;
        position: absolute;
        text-align: center;
        top: -$spacing-5;
        height: 26px;
        left: calc(50% - 76px);
        width: 154px;
        z-index: 10;
        background-color: $color-primary-1-base;

        span {
            display: table-cell;
            text-transform: uppercase;
            vertical-align: middle;
            color: $color-primary-2-base;
            font-size: $size-font-base;
            font-weight: bold;
            padding: 5px;
        }
    }

    &_container {
        display: table;
        width: 100%;
        height: 100%;

        h4 {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            line-height: $size-lineheight-xlarge;
        }
    }
}
