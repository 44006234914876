@mixin item-width($width: 1) {
  -ms-grid-column-end: span $width;
  grid-column-end: span $width;
}
@mixin item-position($position: 1) {
  -ms-grid-column-start: $position;
  grid-column-start: $position;
}

@mixin item-row-position($position: 1) {
  -ms-grid-row-start: $position;
  grid-row-start: $position;
}

.flos-grid {
  display: grid;
  gap: $spacing-5;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: $screen-sm-max) {
    grid-template-columns: 1fr;
    gap: $spacing-7;
    & > div {
      grid-column: 1 / span 1;
    }
  }
}
.flos-grid--span {
  @media (min-width: $screen-md) {
    @for $i from 1 through 12 {
      &-#{$i} {
        @include item-width($width: #{$i});
      }
    }
  }
}
.flos-grid--col-start {
  @media (min-width: $screen-md) {
    @for $i from 1 through 12 {
      &-#{$i} {
        @include item-position($position: #{$i});
      }
    }
  }
}

.flos-grid--row-start-mobile {
  @media (max-width: $screen-sm-max) {
    @for $i from 1 through 12 {
      &-#{$i} {
        @include item-row-position($position: #{$i});
      }
    }
  }
}

.flos-grid--gap {
  @for $i from 2 through 12 {
    &-#{$i} {
      gap: map-get(
                      (
                              2: $spacing-2,
                              3: $spacing-3,
                              4: $spacing-4,
                              5: $spacing-5,
                              6: $spacing-6,
                              7: $spacing-7,
                              8: $spacing-8,
                              9: $spacing-9,
                              10: $spacing-10,
                              11: $spacing-11,
                              12: $spacing-12,
                      ),
                      $i
      );
    }
  }
  &-none {
    gap: 0;
  }
  &-row-none {
    row-gap: 0;
  }
}
.flos-grid--two-col {
  & > div:nth-child(1) {
    grid-column: 1 / span 7;
  }
  & > div:nth-child(2) {
    grid-column: 9 / span 4;
  }
  @media (max-width: $screen-sm-max) {
    & > div:nth-child(1) {
      grid-column: 1 / span 12;
    }
    & > div:nth-child(2) {
      grid-column: 1 / span 12;
    }
  }
}
.flos-grid--narrow {
  & > div:nth-child(1) {
    grid-column: 3 / span 8;
  }
  @media (max-width: $screen-sm-max) {
    & > div:nth-child(1) {
      grid-column: 1 / span 12;
    }
  }
}

.flos-grid-area-title {
  grid-area: title;
  align-self: center;
}
.flos-grid-area-right {
  grid-area: right;
}
.flos-grid-area-content {
  grid-area: content;
}

.flos-grid--right-and-under {
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas:
        'title flex'
        'content content';

  @media (max-width: $screen-sm-max) {
    gap: $spacing-5;
    grid-template-columns: auto;
    grid-template-areas:
            'title'
            'content'
            'right';
  }
}