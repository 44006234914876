@import 'mixins/responsive-visibility';
@import 'mixins/vendor-prefixes';

// Layout
@import 'mixins/clearfix';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@function strip-units($value) {
    @return $value / ($value * 0 + 1);
}

@mixin rem($px) {
    font-size: strip-units($px) + px;
    font-size: (strip-units($px) / 10) + rem; // stylelint-disable-line declaration-block-no-duplicate-properties
}

@mixin overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// TODO: move to _variables-shared.scss when old template is out
$break-small: 768px;
$break-medium: 992px;
$break-large: 1200px;

@mixin media-match($media, $greedy: no) {
    @if $media == mobile {
        @if $greedy != no {
            @error 'Invalid range';
        }
        @media only screen and (max-width: $break-small - 1) {
            @content;
        }
    } @else if $media == mobile-landscape {
        @if $greedy != no {
            @error 'Invalid range';
        }
        @media only screen and (max-width: $break-small - 1) and (orientation: landscape) {
            @content;
        }
    } @else if $media == tablet {
        @if $greedy == up {
            @media only screen and (min-width: $break-small) {
                @content;
            }
        }
        @if $greedy == down {
            @media only screen and (max-width: $break-medium - 1) {
                @content;
            }
        }
        @if $greedy == no {
            @media only screen and (min-width: $break-small) and (max-width: $break-medium - 1) {
                @content;
            }
        }
    } @else if $media == tablet-landscape {
        @if $greedy == up {
            @media only screen and (min-width: $break-small) and (orientation: landscape) {
                @content;
            }
        }
        @if $greedy == down {
            @media only screen and (max-width: $break-medium - 1) and (orientation: landscape) {
                @content;
            }
        }
        @if $greedy == no {
            @media only screen and (min-width: $break-small) and (max-width: $break-medium - 1) and (orientation: landscape) {
                @content;
            }
        }
    } @else if $media == desktop {
        @if $greedy == up {
            @media only screen and (min-width: $break-medium) {
                @content;
            }
        }
        @if $greedy == down {
            @media only screen and (max-width: $break-large - 1) {
                @content;
            }
        }
        @if $greedy == no {
            @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
                @content;
            }
        }
    } @else if $media == desktop-landscape {
        @if $greedy == up {
            @media only screen and (min-width: $break-medium) and (orientation: landscape) {
                @content;
            }
        }
        @if $greedy == down {
            @media only screen and (max-width: $break-large - 1) and (orientation: landscape) {
                @content;
            }
        }
        @if $greedy == no {
            @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) and (orientation: landscape) {
                @content;
            }
        }
    } @else if $media == tv {
        @if $greedy != no {
            @error 'Invalid range';
        }
        @media only screen and (min-width: $break-large) {
            @content;
        }
    } @else if $media == tv-landscape {
        @if $greedy != no {
            @error 'Invalid range';
        }
        @media only screen and (min-width: $break-large) and (orientation: landscape) {
            @content;
        }
    }
}

@mixin equalflex() {
    display: flex;
    flex-flow: wrap;

    > [class*='col-'] {
        margin-left: -0.5px;
        display: flex;
        align-content: flex-start;

        > .csc-default {
            display: flex;
            width: 100%;
        }
    }
}

$z-index: (
    site-header: 1000,
    breadcrumb: 900,
);

@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
    z-index: z-index($key);
}
