@use 'sass:math';

$circle: $spacing-6;
$dotSize: math.div($circle, 2.7);

.flos-radio-field {
    display: flex;
    gap: $spacing-3;
    flex-wrap: wrap;
    > .flos-radio {
        flex-grow: 1;
    }
    &--stacked {
        flex-direction: column;
    }

    @media (max-width: $screen-md-max) {
        flex-direction: column;
    }
}

.flos-radio {
    border: 1px solid $formfield-input-bordercolor;
    border-radius: $formfield-input-border-radius;
    font-weight: $formfield-font-weight;
    padding: $spacing-5;
    user-select: none;
    cursor: pointer;
    color: $formfield-input-color;
    position: relative;
    display: inline-block;

    transition-property: border-color, background-color;
    transition-duration: $formfield-checked-duration;
    transition-timing-function: $formfield-timing-function;

    & .dot + div,
    .flos-field & .dot + div {
        margin-left: $spacing-8;
    }
    & > div > p {
        &:nth-child(1) {
            font-weight: $formfield-font-weight--focus;
        }
        &:nth-child(2) {
            margin-top: $spacing-5;
        }
    }

    .has-error & {
        border-color: $formfield-input-color--invalid;
    }

    .dot {
        padding: 3px;
        border-radius: $circle;
        height: $circle;
        width: $circle;
        padding: $spacing-2;
        top: $spacing-5;
    }

    & > .dot {
        position: absolute;
        border: 1px solid $formfield-checked-color;
        background-color: transparent;
        transition: border 0.2s;
        &::before {
            content: '';
            background-color: $formfield-checked-color;
            transform: translate3d(-50%, -50%, 0) scale(0);
            position: absolute;
            left: 50%;
            top: 50%;
            height: 18px;
            width: 18px;
            border-radius: 18px;
            transition-property: transform;
            transition-duration: 0.1s;
            transition-timing-function: $formfield-timing-function;
        }
    }

    &:hover:not(.is-disabled) {
        border-color: $formfield-checked-color;
    }

    &.is-disabled {
        border-color: $flos-radio-bordercolor--disabled;
        color: $flos-radio-color--disabled;
        cursor: not-allowed;
        pointer-events: none;

        & > .dot {
            border-color: $flos-radio-color--disabled;
        }
    }
}

input[type='radio'] + .flos-radio {
    margin: 0;
}
input[type='radio'] {
    & + .flos-button {
        cursor: pointer;
        color: $formfield-input-color;
        border: 1px solid $formfield-input-bordercolor;
        background-color: transparent;
        position: relative;
        &:hover,
        &:focus,
        &.active {
            color: $formfield-input-color;
            z-index: 1;
            border-color: $formfield-checked-color;
            background-color: transparent;
        }

        &.is-disabled {
            border-color: $flos-radio-bordercolor--disabled;
            color: $flos-radio-color--disabled;
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    &:checked + .flos-button {
        background-color: $formfield-checked-bgcolor;
        border-color: $formfield-checked-color;

        &:hover,
        &:focus,
        &.active {
            background-color: $formfield-checked-bgcolor;
        }
    }
}

input[type='radio']:checked {
    & + .flos-radio {
        background-color: $formfield-checked-bgcolor;
        border-color: $formfield-checked-color;

        &.has-success {
            background-color: $flos-radio-bgcolor--active;
            border-color: $formfield-checked-color;
        }

        & > .dot::before {
            background-color: $formfield-checked-color;
            transform: translate3d(-50%, -50%, 0) scale(1);
            transition-timing-function: $formfield-checked-timing;
        }

        &.is-disabled {
            background-color: $flos-radio-bgcolor--disabled;
            border-color: $flos-radio-color--disabled;
            color: $flos-radio-color--disabled;
            cursor: not-allowed;
            & > .dot {
                border-color: $flos-radio-color--disabled;
                &::before {
                    background-color: $flos-radio-color--disabled;
                }
            }
        }
    }
}

.radio-group-item {
    &:first-child .flos-button,
    &:first-child .cvi-button {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
    }
    &:last-child .flos-button,
    &:last-child .cvi-button {
        margin-right: 0;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
    }
}
