.flos-ResponsiveCoverage {
    .Box--adv__body {
        &:before {
            margin: 0 !important;
        }

        .Section {
            .definition-wrapper {
                padding: 0;
            }

            .list-unstyled {
                padding: 20px 15px;
                list-style-position: inside;

                li {
                    &:before {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        background-size: 9px;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin-right: 10px;
                        border-radius: 100%;
                        position: absolute;
                        margin-top: 5px;
                    }

                    span {
                        position: relative;
                        left: 25px;

                        @media (max-width: $screen-xs) {
                            display: block;
                            width: 262px;
                            word-wrap: break-word;
                        }

                        @media (max-width: 320px) {
                            display: block;
                            width: 220px;
                            word-wrap: break-word;
                        }

                        @media (max-height: 568px) {
                            display: block;
                            width: 92%;
                            word-wrap: break-word;
                        }
                    }
                }
            }

            .checked-pills {
                color: $color-primary-1-base;
                display: inline-block;
                padding: 10px 15px;
                margin: 5px;
                background-color: $color-secondary-4-base;
                cursor: default;
                border: none;
                border-radius: $border-radius-small;
                flos-icon {
                    margin-right: 10px;
                    vertical-align: text-bottom;
                }
            }

            .list-unstyled {
                &.notcovering {
                    li {
                        &:before {
                            @extend .ico-cross-stroked;
                            background-color: rgba($color-supplement-error-base, 0.45);
                            border: 1px solid $color-supplement-error-base;
                        }
                    }
                }

                &.covering {
                    li {
                        &:before {
                            @extend .ico-tick-stroked;
                            background-color: rgba($color-supplement-success-base, 0.45);
                            border: 1px solid $color-supplement-success-base;
                        }
                    }
                }
            }
        }
    }

    .Section--low {
        padding: 10px 20px !important;
        background-color: #fff;
        .checked-pills-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: $size-font-small;

            .checked-pills {
                white-space: nowrap;
                margin-left: 0;
                margin-right: 8px;

                &:last-of-type {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .link-coverage-pills {
                display: inline-block;
                white-space: nowrap;
                margin-left: auto;

                span {
                    margin-left: 10px;
                }
            }
        }
    }

    .flos-ResponsiveCoverage-price {
        margin-top: -7px;
        display: flex;
        height: 50px;
        > div {
            align-self: center;
        }

        &--xs {
            margin-bottom: 15px;
        }
    }
    .tds-Icon--flos-responsive {
        margin-top: -9px;
    }

    .flos-ResponsiveCoverage-titlegroup--icon {
        padding-left: 0;
        @media (max-width: $screen-xs-max) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .flos-ResponsiveProduct-subtitle,
    .flos-ResponsiveProduct-links {
        margin-left: 0;
    }
}

.a-disabled {
    pointer-events: none;
    cursor: default;
}

.tds-icon--accordion {
    margin-right: 20px;
}
