section {
    .flos-image-banner.container {
        padding: 0;
        width: auto;
    }
}

.flos-image-banner {
    background-color: transparent;

    &[id] {
        scroll-margin-top: 130px;
    }

    @media (min-width: $screen-sm) {
        height: 592px;
    }
}

.flos-image-banner-container {
    position: relative;
}

.flos-image-banner-content {
    position: relative;
    background-color: $color-supplement-white-base;
    margin-bottom: 272px;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-7 $spacing-5 $spacing-11;
    z-index: 0;

    @media (min-width: $screen-sm) {
        position: absolute;
        right: 0;
        top: $spacing-7;
        width: 695px;
        height: 560px;
        padding: $spacing-11;
        z-index: 10;

        @media (max-width: $screen-sm-max) {
            width: 440px;
            padding: $spacing-7;
        }
    }
}

.flos-image-banner-text {
    margin-bottom: $spacing-7;

    *:last-child {
        margin-bottom: 0;
    }
}

.flos-image-banner-title {
    margin-bottom: $spacing-3;
}

.flos-image-banner-subtitle {
    margin-bottom: $spacing-7;
}

.flos-image-banner-action {
    .flos-button-group .flos-button {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

.flos-image-banner-image-container {
    position: absolute;
    bottom: -272px;
    width: auto;
    height: 308px;

    @media (min-width: $screen-sm) {
        bottom: auto;
        left: 0;
        width: 572px;
        height: 512px;
    }

    @media (max-width: $screen-xs-max) {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 $spacing-5;
    }
}

.flos-image-banner-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-medium;
}
