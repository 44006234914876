$button-primary-bg: $color-secondary-1-dark;
$button-primary-color: $color-supplement-white-base;
$button-primary-hover-bg: $color-supplement-success-base;
$button-primary-active-bg: $color-supplement-success-base;
$button-primary-active-color: $color-supplement-white-base;
$button-primary-padding: $spacing-4 $spacing-7;
$button-primary-padding-large: $spacing-5 $spacing-7;
$button-primary-padding-small: $spacing-2 $spacing-5;

$button-disabled-bg: $color-primary-2-light;
$button-disabled-color: $color-secondary-4-base;

$button-secondary-bg: $color-primary-1-base;
$button-secondary-color: $color-supplement-white-base;
$button-secondary-hover-bg: $color-secondary-2-dark;
$button-secondary-hover-color: $color-supplement-white-base;
$button-secondary-hover-border: none;
$button-secondary-active-bg: $color-secondary-2-dark;
$button-secondary-active-color: $color-supplement-white-base;
$button-secondary-disabled-bg: $button-disabled-bg;
$button-secondary-disabled-border: $button-secondary-disabled-bg;

$button-secondary-border: none;
$button-secondary-padding: $spacing-4 $spacing-7;
$button-secondary-padding-large: $spacing-5 $spacing-7;
$button-secondary-padding-small: $spacing-2 $spacing-5;

$button-ghost-bg: $button-secondary-bg;
$button-ghost-color: $color-primary-2-base;
$button-ghost-padding: $spacing-4 0;
$button-ghost-padding-small: $spacing-3 0;

@import './button';
