.flow-menu {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-snap-type: x mandatory;

    a:focus {
        outline: none !important;
    }

    & > .Box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 100px;
        max-width: 117px;
        min-height: 100px;
        min-width: 117px;
        margin-right: 3px;
        margin-top: $spacing-5;
        margin-bottom: $spacing-5;
        text-align: center;
        padding: 10px;

        @media (min-width: 992px) {
            max-height: 125px;
            max-width: 170px;
            min-height: 125px;
            min-width: 170px;
            margin-right: 5px;
        }

        &:not(.disabled, .dimmed) {
            scroll-snap-align: center;
        }

        flos-icon {
            padding-bottom: 5px;
            margin-right: 0;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            flex-grow: 0;
            stroke: $color-primary-1-base;
            @media (min-width: 992px) {
                width: 65px;
                height: 65px;
            }
        }

        p {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }

        &:hover {
            background-color: $color-supplement-white-base;
        }

        &:not(:last-child) {
            &::after {
                z-index: 999;
                width: 0;
                height: 0;
                content: '';
                top: 50%;
                position: absolute;
                border: 21px solid transparent;
                margin-top: -21px;
                left: 100%;
                border-left-color: #fff;
                border-left-width: 10px;

                @media (min-width: 992px) {
                    margin-top: -26px;
                    border-width: 25px;
                    border-left-width: 15px;
                }
            }
        }

        &:not(:first-child) {
            &::before {
                width: 0;
                height: 0;
                content: '';
                top: 50%;
                margin-top: -21px;
                position: absolute;
                border: 21px solid transparent;
                left: 0;
                border-left-color: $color-supplement-grey-base;
                border-left-width: 10px;

                @media (min-width: 992px) {
                    margin-top: -26px;
                    border-width: 25px;
                    border-left-width: 15px;
                }
            }
        }

        &.dimmed {
            background-color: $color-supplement-grey-light;
            color: $color-primary-1-base;

            &::after {
                border-left-color: $color-supplement-grey-light;
            }
            &:hover {
                background-color: $color-secondary-2-light;
                &::after {
                    border-left-color: $color-secondary-2-light;
                }
            }
        }
        &.disabled {
            pointer-events: none;
            color: $color-secondary-4-base;

            flos-icon {
                stroke: $color-secondary-4-base;
            }
        }
    }
}
