@use 'sass:math';

.container {
    @include container-fixed;

    @media (min-width: $screen-lg) {
        width: $container-lg;
    }

    &-no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    &--edge-to-edge-touch-device {
        @media (any-hover: none) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// ! .col-(xs|sm|md|lg)-(offset-)?#{$i}
// ! might be deprecated along with bootstrap please stop uasing it.
.row {
    @include make-row;
}
@include make-grid-columns;
@include make-grid(xs);
@media (min-width: $screen-sm) {
    @include make-grid(sm);
}
@media (min-width: $screen-md) {
    @include make-grid(md);
}
@media (min-width: $screen-lg) {
    @include make-grid(lg);
}
// !

.row--narrow {
    $narrow-gutter: math.div($grid-gutter-width, 4);

    margin-left: -$narrow-gutter;
    margin-right: -$narrow-gutter;

    [class*='col-'] {
        padding-left: $narrow-gutter;
        padding-right: $narrow-gutter;
        margin-bottom: $narrow-gutter;

        @media (max-width: $screen-sm-max) {
            //margin-bottom: math.div($grid-gutter-width, 2);
        }
    }

    .Box {
        margin-top: math.div($narrow-gutter, 2) !important;
        margin-bottom: math.div($narrow-gutter, 2) !important;
    }

    @media (max-width: 600px) {
        [class*='col-xs-'] {
            width: 100%;
        }
    }
}

.row--equal {
    &-md-col,
    &-sm-col,
    &-lg-col {
        &-5 {
            width: percentage(math.div(1, 5));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-6 {
            width: percentage(math.div(1, 6));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-7 {
            width: percentage(math.div(1, 7));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-8 {
            width: percentage(math.div(1, 8));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-9 {
            width: percentage(math.div(1, 9));
        }
    }
}

@media (min-width: 600px) {
    .row--equal-xs {
        @include equalflex();
    }
}

@media (min-width: $screen-sm) {
    .row--equal-sm {
        @include equalflex();
    }
}

@media (min-width: $screen-md) {
    .row--equal-md {
        @include equalflex();
    }
}

.row--collapse-xs {
    @media (max-width: $screen-xs-max) {
        margin-left: math.div(-$grid-gutter-width, 2) !important;
        margin-right: math.div(-$grid-gutter-width, 2) !important;

        > [class*='col-'] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
