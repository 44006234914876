//** Popover maximum width
$popover-max-width: 300px !default;
//** Popover arrow width
$popover-arrow-width: 6px !default;

[data-toggle='popover'] {
    cursor: pointer;
}

.popover {
    z-index: 100;
    font-size: $size-font-xxsmall;
    border-radius: 3px;
    max-width: $popover-max-width;
    font-weight: 300;

    &.top {
        margin-top: -($popover-arrow-width + 4px);
    }
    &.right {
        margin-left: ($popover-arrow-width + 4px);
    }
    &.bottom {
        margin-top: ($popover-arrow-width + 4px);
    }
    &.left {
        margin-left: -($popover-arrow-width + 4px);
    }

    .popover-content {
        padding: 15px 10px 15px 15px;

        *:first-child {
            //Pad first-child right to account for close button and label
            padding-right: 50px;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
        display: block;
        margin: 0 0 20px;
        color: $color-primary-2-base;
        opacity: 1 !important;
        font-weight: normal;
        font-size: $size-font-xxsmall;
        text-shadow: none;
        &:hover {
            cursor: pointer;
        }
        .ico-delete {
            margin-right: 5px;
            font-size: $size-font-small;
            margin-top: -4px;
            position: absolute;
            margin-left: -20px;
        }
    }

    h4 {
        font-size: $size-font-small;
        line-height: 20px;
        font-weight: 400;
    }

    a {
        display: block;
        margin: 0 0 20px;
        color: $color-primary-2-base;

        span {
            margin-right: 2px;
        }
    }

    b {
        font-weight: 500;
        font-size: $size-font-base;
    }
}

// Any .popover immediately preceded by [data-toggle='popover'][data-theme='dark']
// Any .popover immediately preceded by .popover-theme-dark
[data-toggle='popover'][data-theme='dark'] + .popover,
.popover-theme-dark {
    $popover-background: rgba(7, 10, 28, 90%);
    $popover-arrow-color: $popover-background;
    $popover-arrow-outer-color: transparent;
    $popover-border-color: $popover-background;

    background-color: $popover-background;
    color: $color-primary-2-base;
    border-color: $popover-border-color;
    & .popover-title {
        background-color: transparent;
        border-bottom: none;
    }

    &.top > .arrow {
        border-top-color: $popover-arrow-outer-color;

        &:after {
            bottom: 0;
            border-top-color: $popover-arrow-color;
        }
    }

    &.right > .arrow {
        border-right-color: $popover-arrow-outer-color;

        &:after {
            left: 0;
            border-right-color: $popover-arrow-color;
        }
    }

    &.bottom > .arrow {
        border-bottom-color: $popover-arrow-outer-color;

        &:after {
            top: 0;
            border-bottom-color: $popover-arrow-color;
        }
    }

    &.left > .arrow {
        border-left-color: $popover-arrow-outer-color;

        &:after {
            right: 0;
            border-left-color: $popover-arrow-color;
        }
    }
}

/**
*	Popover sizes.
*/

// data-size='small'
[data-toggle='popover'][data-size='small'] + .popover,
.popover-size-small {
    max-width: 200px;
}

// data-size='large'
[data-toggle='popover'][data-size='large'] + .popover,
.popover-size-large {
    max-width: 370px;
}

// login button dropdown style (Lillian)
.LoginPopover {
    .popover-title {
        background-color: #fff;
        border-bottom-width: 0;
    }
    .popover {
        .close {
            display: none;
        }
    }
    .popover-content {
        *:first-child {
            //Pad first-child right without close button should be 100% width
            padding-right: 0;
        }
    }
    &-link {
        font-weight: 300 !important;
        font-size: $size-font-xxsmall !important;
        display: block !important;
        white-space: nowrap;
        margin-bottom: 10px !important;
        padding: 0 !important;
        :hover {
            color: $color-supplement-grey-dark;
        }
    }
}
