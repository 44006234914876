.tds-BoxList {
    margin-bottom: 4vh;
    &.Box {
        @media (min-width: $screen-sm) {
            padding: $spacing-5;
        }
    }
}

.tds-BoxList-item {
    margin: $spacing-5 0;
    position: relative;
    .tds-BoxList--button & {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--nosub {
        padding: 30px;

        .tds-BoxList-status {
            top: 17px;

            .status {
                padding: 8px 12px;
                display: block;
            }
        }

        .tds-BoxList-icon {
            position: absolute;
            top: 10px;
        }
    }
}

.tds-BoxList-titlegroup {
    .tds-BoxList--icon & {
        margin-left: 65px;
    }

    .tds-BoxList--button & {
    }
}

.tds-BoxList-icon {
    float: left;
    width: 55px;
    height: 55px;
}

.tds-BoxList-title {
    font-size: $size-font-small;
    line-height: $size-lineheight-base;
    font-weight: 300;
    margin-bottom: 8px;
}

.tds-BoxList-subtitle {
    font-size: $size-font-base;
    margin-bottom: 0;
}

.tds-BoxList-status {
    position: absolute;
    right: 20px;
    top: 20px;
}
