$formfield-cursor-disabled: not-allowed !default;

// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
    // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
    // so we reset that to ensure it behaves more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359.
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    border: 0;
}

textarea.form-control {
    min-height: 122px;
    resize: vertical;
}

// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

input[type='search'] {
    // Override content-box in Normalize (* isn't specific enough)
    @include box-sizing(border-box);

    // Search inputs in iOS
    //
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    -webkit-appearance: none;
    appearance: none;
}

// Position radios and checkboxes better
input[type='radio'],
input[type='checkbox'] {
    margin: 4px 0 0;
    line-height: normal;

    // Apply same disabled cursor tweak as for inputs
    // Some special care is needed because <label>s don't inherit their parent's `cursor`.
    //
    // Note: Neither radios nor checkboxes can be readonly.
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
        cursor: $formfield-cursor-disabled;
    }
}

input[type='file'] {
    display: block;
}

// Make range inputs behave like textual form controls
input[type='range'] {
    display: block;
    width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}

// Adjust output element
output {
    display: block;
    padding-top: ($spacing-3 + 1);
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-primary-1-base;
}

.form-control {
    // ! "This classes might deprecate soon along with bootstrap, please stop using.";
    border-radius: 0;
    display: block;
    width: 100%;
    padding: 10px 16px 9px;
    line-height: $line-height-base;
    color: $color-primary-1-base;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
}
select.form-control {
    position: relative;
    border: 1px solid $color-primary-2-base;
    border-radius: $border-radius-small;
    background-color: $color-supplement-grey-light;
    font-weight: 400;

    &:not([multiple]) {
        appearance: none;
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;
        //background-size: 14px 8px;
        height: 100%;
        @extend .ico-arrow-down-blue;
    }

    &::-ms-expand {
        display: none;
    }
}
input[type='file'].form-control {
    height: auto;
    padding: 5px;
    line-height: 10px;
}

.form-group {
    $form-group: &;
    position: relative;
    margin-bottom: 25px;
    max-width: 770px;
    color: $color-primary-1-base;

    &.has-icon-before,
    &.has-icon-after {
        flos-icon {
            position: absolute;
            pointer-events: none;
            top: 34px;
            &.left {
                left: 0;
            }
            &.right {
                left: calc(100% - 38px);
            }
        }
    }

    .help-block ~ flos-icon {
        bottom: 38px;
    }

    &.has-icon-before {
        padding-left: 41px;
    }

    &.form-group-animated {
        color: $color-secondary-2-base;

        &.has-icon-before,
        &.has-icon-after {
            flos-icon {
                top: 12px;
            }
        }
        .form-control {
            padding-bottom: 7px;
            padding-top: 18px;
            color: $color-primary-1-base;
        }

        .form-control::placeholder {
            color: transparent;
        }

        .form-control:placeholder-shown ~ .form-label {
            font-size: $size-font-xsmall;
            top: 10px;
        }

        label,
        .form-control:focus ~ .form-label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            font-size: 1.2rem;
            font-weight: 300;
        }

        .form-label {
            left: 16px;
        }

        &.has-icon-before {
            .form-label {
                left: 57px;
            }
        }

        &.is-required {
            > label {
                &::after {
                    content: '\00a0*';
                }
            }
        }
    }

    .help-block {
        color: inherit;
        font-size: $size-font-xsmall;
    }

    &.is-required {
        > label:first-child {
            &:not(.has-popover) {
                &::after {
                    content: '\00a0*';
                    color: $color-supplement-error-base;
                    font-size: 16px;
                    line-height: 1;
                }
            }

            &.has-popover i[data-toggle]::before {
                content: '*';
                color: $color-supplement-error-base;
                font-size: 16px;
                line-height: 1;
            }

            &.has-popover flos-popover-icon {
                &::before {
                    content: ' * ';
                    color: $color-supplement-error-base;
                    font-size: 16px;
                    line-height: 1;
                }
            }
        }
    }

    > label:first-child {
        margin-bottom: 2px;

        &.has-popover i[data-toggle] svg {
            position: relative;
            left: 5px;
            top: 4px;
        }
    }

    /* only set width on medium devices and up */
    @media (min-width: $screen-md) {
        &--narrow {
            max-width: 670px;
        }

        &--medium {
            max-width: 280px;
        }

        &--small {
            max-width: 220px;
        }
    }

    &--wide {
        max-width: 1170px;

        &-thin {
            max-width: inherit;
            margin-bottom: calc(25px - 7.5px);
        }
    }

    &--compact {
        > label:first-child {
            margin-bottom: 4px;
        }
        .form-control {
            padding: 6px 8px;
        }
        input[type='text'] {
            height: 32px;
            max-width: 320px;
            font-size: 14px;
        }
        .help-block {
            margin-bottom: 0;
        }
    }

    &.has {
        &-icon-after,
        &-success,
        &-error {
            .form-control {
                background-repeat: no-repeat;
                background-position: calc(100% - 10px) 8px;
                padding-right: 50px;
            }
        }

        &-success {
            flos-icon {
                &.right {
                    svg {
                        width: 50%;
                        stroke-width: 2px;
                    }
                }
            }

            .help-block {
                color: $color-supplement-success-base;
            }
        }

        &-error {
            color: $color-supplement-error-base;

            .form-control {
                color: $color-supplement-error-base;
            }
        }

        &-success,
        &-error {
            &.has-icon-after > .form-control:focus + flos-icon {
                display: none;
            }
        }

        &-success:not(.has-icon-after) {
            .form-control {
                @extend .ico-tick-success;

                &:focus {
                    background-image: none;
                }
            }
        }

        &-error:not(.has-icon-after) {
            .form-control {
                @extend .ico-exclamation-mark;

                &:focus {
                    background-image: none;
                }
            }
        }
    }

    .form-control {
        font-size: $size-font-xsmall;
        box-shadow: none;
        &.editable {
            background-color: $color-secondary-4-light;
        }
    }

    .input-list {
        > label:not(.checkbox):not(.radio) {
            display: inline-block;
            margin-right: 25px;
            padding-left: 25px; /* Added 2018-05-14 */
        }

        &--vertical {
            > label:not(.checkbox):not(.radio) {
                display: block;
            }
        }
    }

    &--header {
        margin-bottom: 0;
    }

    &--label {
        margin-bottom: 0 !important;
    }
}

.help-block {
    // ! "This classes might deprecate soon along with bootstrap, please stop using.";
    display: block; // account for any element using help-block
    margin-top: 5px;
    margin-bottom: 10px;
    color: lighten($color-primary-1-base, 25%); // lighten the text some for contrast
}

.form-group.has-error .date-input input.form-control {
    border-color: $color-supplement-error-base;
}

.date-input {
    position: relative;
    max-width: 220px;

    &-label {
        display: none;
    }

    .form-control {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $color-secondary-2-base;
    }

    .no-input-date & {
        .form-control {
            padding-right: 30px;
        }

        &-label {
            display: block;
            height: 100%;
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;
        }

        .form-control {
            background-image: none !important;
        }

        flos-icon {
            position: relative;
            top: 9px;
            right: 16px;
        }
    }
}

.input-short {
    input {
        max-width: 220px;
    }
}

.form-wide {
    .form-group {
        max-width: none !important;
    }
}

.top-toggle {
    position: relative;
    width: 46px;
    user-select: none;

    input {
        display: none;
    }

    label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        height: 18px;
        padding: 0;
        line-height: 20px;
        border-radius: 9px;
        background-color: #c6c6c6;
        transition: all 0.3s ease-in 0s;

        &:before {
            content: '';
            display: block;
            width: 30px;
            margin: -5px;
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 22px;
            border-radius: 30px;
            transition: all 0.25s ease-in 0s;
        }
    }

    input:checked + label {
        background-color: #75bee9;
        transition: all 0.25s ease-in 0s;

        &:before {
            right: 4px;
        }
    }

    &__col {
        float: left;
        width: 90px;
        padding-left: 25px;
        padding-top: 6px;
    }
}

.checkbox-terms {
    padding-left: 50px;
    line-height: 1.1;
    padding-top: 5px;
    margin-bottom: 6px;

    &.checkbox:before {
        position: absolute;
        left: 0;
    }
}

/* ca. 1 col width */
.input-width--xs {
    /*width: 8.3333%;*/
    max-width: 70px;
}

/* only set width on medium devices and up */
@media (min-width: $screen-md) {
    /* ca.2 col width */
    .input-width--sm {
        /*width: 16.6667%;*/
        max-width: 170px;
    }

    /* ca. 4 col width */
    .input-width--md {
        /*width: 33.3333%;*/
        max-width: 370px;
    }

    /* ca. 7 col width */
    .input-width--lg {
        /*width: 58.3333%;*/
        max-width: 670px;
    }
}

.form-group--small {
    input,
    textarea {
        max-width: 220px;
    }
}

h3.has-success {
    display: inline-block;

    &::after {
        width: 18px;
        height: 18px;
        content: '';
        display: inline-block;
        padding-right: 32px;
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M7%2014.7L1.1%209.5l1.8-2.1%203.5%203.2%206.1-8.9%202.3%201.5%22%20fill%3D%22%238dc63f%22%2F%3E%3C%2Fsvg%3E%0A')
            no-repeat 10px -2px;
    }
}

.compound-field.is-required {
    h3:first-child:after {
        content: '\00a0*';
        color: $color-supplement-error-base;
        font-size: 16px;
        line-height: 1;
    }
}

.datepick-nav {
    a.datepick-cmd {
        color: $color-primary-1-base;
    }

    .datepick-cmd-next {
        right: -4px;
    }

    .datepick-cmd-prev {
        left: -4px;
    }
}

h3.has-error,
.compound-field.is-required h3.has-error {
    display: inline-block;
    color: $color-supplement-error-base;

    &::after {
        content: '!' !important;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid rgba(230, 92, 92, 0.8);
        text-align: center;
        font-size: 20px !important;
        font-weight: 700;
        line-height: 26px !important;
        font-family: arial, sans-serif;
        margin-right: 17px;
        flex: 0 0 auto;
        align-self: center;
        color: $color-supplement-error-base;
    }
}

.less-margin {
    margin-top: 0;
    margin-bottom: 5px;
}
