.cvi-loading-dots {
    color: $color-secondary-2-base;
    font-size: 10px;
    margin-left: 20px;
    margin-top: -20px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s !important;
    &,
    &::before,
    &::after {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation-fill-mode: both;
        animation: dotPulse 1.5s infinite ease-in-out;
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
    }
    &::before {
        left: -20px;
        animation-delay: -0.32s;
    }
    &::after {
        left: 20px;
    }
    &--abs {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
    }
}

@keyframes dotPulse {
    0%,
    90%,
    100% {
        box-shadow: 0 20px 0 -8px;
    }
    50% {
        box-shadow: 0 20px 0 -1px;
    }
}
