//============================================
// Shared variables
//============================================

/* Colors: */
$transparent-white: rgba(255, 255, 255, 0.5);

// Extra small screen: portrait phone
$screen-xs: 480px;
// Small screen: landscape phone / portrait tablet
$screen-sm: 768px;
// Medium screen: landscape tablet
$screen-md: 992px;
// Large screen: desktop
$screen-lg: 1200px;
// Large screen: wide desktop
$screen-xl: 1440px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);

$svg-data-uri-prefix: 'data:image/svg+xml;charset=utf8';
$prefix: 'td';

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg` and up.
$container-lg: $container-large-desktop !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-size-base: 14px !default;
//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//## Font, line-height, and color for body text, headings, and more.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-line-height: inherit !default;
$headings-color: inherit !default;

//== Forms
//
//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

//== Modals
//
//##

//== Modals
//
//##
