.flos-carousel {
    display: flex;
    height: auto;
    overflow: auto hidden;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
        height: $spacing-3;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
        border-radius: $border-radius-small;
    }

    @media (any-hover: none) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    > * {
        flex: 1;
        margin-right: $spacing-5;
    }

    @media (max-width: $screen-sm-max) and (any-hover: none) {
        > * {
            scroll-snap-align: center;
            flex: 1 ; //0 0 calc((100vw - (2 * 10vw)) - (2 * $spacing-5));
        }
    }
}
