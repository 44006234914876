.tds-Coverage--inline-list {
    .tds-Coverage--inline {
        .tds-Coverage-list,
        .tds-Coverage-title {
            font-size: $size-font-base;
        }
        &:last-of-type {
            .tds-Coverage-header {
                border-bottom: 0 !important;
            }
        }
        .tds-Coverage-content {
            padding-left: 0;
            padding-right: 5px;
            font-size: $size-font-base;
        }
    }
    .tds-Coverage-listitem {
        padding-left: 20px;
        flos-icon {
            position: absolute;
            left: -10px;
        }
    }
    .Section {
        .tds-Coverage-listitem {
            & flos-icon {
                left: -5px;
            }
        }
    }
}
