@font-face {
    font-family: Coop;
    src: url('../../fonts/COOPWeb-Regular.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: Coop;
    src: url('../../fonts/COOPWeb-Bold.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}
