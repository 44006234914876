.alert-message,
.info-message {
    padding: 10px;
    @media (min-width: $screen-md) {
        padding: 15px 15px 15px 60px;
    }

    background: $color-supplement-white-base;
    margin: 15px 0 15px 0;
    position: relative;

    .h2,
    h2 {
        margin-bottom: 10px;
        @media (min-width: $screen-md) {
            margin-bottom: 15px;
        }
    }

    flos-icon {
        display: none;
        @media (min-width: $screen-md) {
            display: inherit;
            position: absolute;
            left: 15px;
            stroke: $color-primary-1-base;
        }
    }

    &__title {
        font-size: $size-font-medium;
        margin: 0 0 15px 0;
        font-weight: bold;
        color: $color-primary-1-base;
    }

    &__content {
        margin: 0 0 15px 0;
        color: $color-secondary-2-base;
    }

    &__btn {
        margin-top: 10px;
        @media (min-width: $screen-md) {
            margin-top: 15px;
        }
        background-color: $color-primary-1-base;
        color: $color-supplement-white-base;
        display: inline-block;
    }
}

.alert-message {
    border: 3px $color-primary-1-base solid;
}

.info-message {
    border: 3px solid $color-secondary-2-base;
}

.message {
    position: relative;
    background: $color-primary-2-light;
    border: 1px solid $color-primary-2-medium;
    box-sizing: border-box;
    padding: calc(#{$spacing-4} - 1px) calc(#{$spacing-5} - 1px);
    width: 100%;
    border-radius: 4px;
}
.message--inline {
    width: auto;
    display: inline;
}

.message-header {
    display: flex;
    flex-direction: row;
    gap: $spacing-4;
    align-items: center;
}

.message-title {
    flex-grow: 1;
}

.message-close-button {
    align-self: flex-start;
}

.message--large {
    padding: $spacing-5;
}
.message--secondary {
    background: $color-supplement-grey-light;
    color: $color-secondary-4-medium;
    border: 1px solid transparent;
}
.message--warning {
    background: $color-supplement-warning-light;
    border: 1px solid $color-supplement-warning-medium;
    color: $color-supplement-warning-dark;
}
.message--success {
    background: $color-supplement-success-light;
    border: 1px solid $color-supplement-success-medium;
    color: $color-supplement-success-dark;
}
.message--error {
    background: $color-supplement-error-light;
    border: 1px solid $color-supplement-error-medium;
    color: $color-supplement-error-dark;
}
