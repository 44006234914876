@use 'sass:math';

$SummaryBar-padding-y: 10px;
$SummaryBar-padding-y-desktop: 20px;

.SummaryBar {
    .SummaryBar-bar {
        position: relative;
    }

    .SummaryBar__invisible-when-collapsed {
        transition: 350ms opacity;
        opacity: 1;
    }
    .SummaryBar__visible-only-when-collapsed {
        transition: 350ms opacity;
        opacity: 0;
    }

    &.SummaryBar--collapsed {
        .SummaryBar__invisible-when-collapsed {
            opacity: 0;
        }
        .SummaryBar__visible-only-when-collapsed {
            opacity: 1;
        }
    }

    [class*='col-'] {
        padding-top: $SummaryBar-padding-y;
        padding-bottom: $SummaryBar-padding-y;

        @media (min-width: $screen-md) {
            width: auto;
            padding-top: $SummaryBar-padding-y-desktop;
            padding-bottom: $SummaryBar-padding-y-desktop;

            + [class*='col-'] {
                .SummaryBar-price {
                    padding-left: 20px;
                }
            }

            &:not(.SummaryBar-collapse-toggle) {
                min-width: 250px;
            }
        }

        + [class*='col-'] {
            position: relative;

            .SummaryBar-price {
                &:after {
                    content: '';
                    height: calc(100% - #{$SummaryBar-padding-y} * 2);
                    width: 1px;
                    left: 0;
                    top: $SummaryBar-padding-y;
                    position: absolute;
                    background: rgba($color-supplement-white-base, 0.2);

                    @media (min-width: $screen-md) {
                        height: calc(100% - #{$SummaryBar-padding-y-desktop} * 2);
                        top: $SummaryBar-padding-y-desktop;
                    }
                }
            }
        }
    }
}

.SummaryBar-price {
    .SummaryBar-panel-link {
        display: none;

        @media (min-width: $screen-md) {
            display: inline-block;
            margin-left: 50px;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 10px;
        }

        @media (max-width: $screen-sm-max) {
            margin-left: 30px;
        }
    }
    .new-price {
        padding-left: 40px;
        margin-left: 60px;
        border-left: 1px solid #d3d3d3;

        @media (max-width: 320px) {
            margin-left: 25px;
        }
    }

    &.SummaryBar-price--primary {
        .SummaryBar-panel-link {
            color: red;
        }
    }
    .SummaryBar-panel-link--show {
        display: inline-block;
    }

    .priceNumber,
    .currency,
    .SummaryBar-price__heading:not(.cm-yourPrice) {
        &.dimmed,
        &:not(.ommit) {
            -webkit-animation-name: blinker;
            -webkit-animation-duration: 0.5s;
            -webkit-animation-timing-function: linear;
            -webkit-animation-iteration-count: 1;

            -moz-animation-name: blinker;
            -moz-animation-duration: 0.5s;
            -moz-animation-timing-function: linear;
            -moz-animation-iteration-count: 1;

            animation-name: blinker;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
        }
    }
}

.SummaryBar-price__label {
    display: block;
    line-height: 1.2;
}

@media (max-width: 480px) {
    .SummaryBar-price {
        .SummaryBar-price__label {
            .curr-price-header-mobile {
                font-size: $size-font-xxsmall;
            }
        }
        .SummaryBar-price__heading {
            .price-mobile {
                font-size: $size-font-base;
            }

            .price-mobile-new {
                font-size: $size-font-medium !important;
            }
        }
    }
}

.SummaryBar-price__heading {
    margin-bottom: 0;
    line-height: 1.2;
    white-space: nowrap;
    font-weight: 300;

    .SummaryBar-price--primary & {
        font-weight: bold;
    }

    @media (min-width: $screen-md) {
        font-size: $size-font-medium;
    }
}

.SummaryBar-panel-link {
    font-size: $font-size-base;
    color: $color-primary-2-base !important;
    font-weight: 300;
}

.SummaryBar-collapse-toggle {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: percentage(math.div(1, 12) * 2);

    @media (min-width: $screen-md) {
        display: none;
    }

    .ico {
        position: absolute;
        top: 50%;
        right: math.div($grid-gutter-width, 2);
        transform: translateY(-50%) rotate(180deg);
        transition: 350ms transform;

        @media (min-width: $screen-xs) {
            right: math.div($grid-gutter-width, 2) + 15px;
        }
    }

    &.collapsed .ico {
        transform: translateY(-50%);
    }
}

.SummaryBar-collapse {
    @media (min-width: $screen-md) {
        display: none;
    }

    &__inner {
        padding-bottom: $SummaryBar-padding-y;

        @media (min-width: $screen-md) {
            padding-bottom: $SummaryBar-padding-y-desktop;
        }
    }

    .SummaryBar-price__heading {
        font-weight: bold;
    }

    .Kmberegner-new-price-field,
    .SummaryBar-panel-link {
        display: block;
        margin-bottom: 10px;
    }
}

@-moz-keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        color: #e0861a;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        color: #e0861a;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        color: #e0861a;
    }
    100% {
        opacity: 1;
    }
}
