//
// Modals
// --------------------------------------------------
// !These styling might get deprecated along with bootstrapcss, please stop using them.
// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

//** Padding applied to the modal body
$modal-inner-padding: 15px !default;

//** Padding applied to the modal title
$modal-title-padding: 15px !default;
//** Modal title line-height
$modal-title-line-height: $line-height-base !default;

//** Background color of modal content area
$modal-content-bg: #fff !default;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg: $color-supplement-black-light !default;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.95 !default;
//** Modal header border color
$modal-header-border-color: #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

$zindex-modal-background: 10040 !default;
$zindex-modal: 10050 !default;

// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        @include translate(0, -25%);
        @include transition-transform(0.3s ease-out);
    }
    &.in .modal-dialog {
        @include translate(0, 0);
    }
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

// Actual modal
.modal-content {
    position: relative;
    background-color: $modal-content-bg;
    background-clip: padding-box;
    border: 1px solid $modal-content-border-color;
    border-radius: $border-radius-large;
    @include box-shadow(0 3px 9px rgba(0, 0, 0, 0.5));
    // Remove focus outline from opened modal
    outline: 0;
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-background;
    background-color: $modal-backdrop-bg;
    // Fade for backdrop
    &.fade {
        opacity: 0;
    }
    &.in {
        opacity: $modal-backdrop-opacity;
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: $modal-title-padding;
    border-bottom: 1px solid $modal-header-border-color;
    @include clearfix;
}
// Close icon
.modal-header .close {
    margin-top: -2px;
}

// Title text within header
.modal-title {
    margin: 0;
    line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
    padding: $modal-inner-padding;
    text-align: right; // right align buttons
    border-top: 1px solid $modal-footer-border-color;
    @include clearfix; // clear it in case folks use .pull-* classes on buttons

    // Properly space out buttons
    .btn + .btn {
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
        margin-left: 5px;
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// Scale up the modal
@media (min-width: $screen-sm) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        width: 854px;
        margin: 30px auto;
    }
    .modal-content {
        @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
    }

    // Modal sizes
    .modal-sm {
        width: $modal-sm;
    }
}

@media (min-width: $screen-md) {
    .modal-lg {
        width: $modal-lg;
    }
}

// ! END

.topdk-modal {
    &__content {
        position: relative;
        background-color: #fff;
        border-radius: $spacing-3;
        width: 100%;

        &-transparent {
            background-color: transparent;
            border: none;
        }
        &-primary {
            background-color: $color-primary-1-base;
        }
    }
    &-close {
        position: absolute;
        top: $spacing-5;
        right: $spacing-5;

        @media (min-width: $screen-md) {
            top: $spacing-7;
            right: $spacing-7;
        }

        button,
        button:hover,
        button:focus {
            padding: 0;
            min-width: auto;
            border: none;
            background: none;
        }
        .flos-button--icon-only {
            padding: 0;
        }
    }

    &__highlight {
        border: 5px solid $color-primary-1-base;
    }

    &__header {
        padding: 24px 24px 12px;
    }

    &__body {
        position: relative;
        padding: 0 24px 8px;
    }
    &__footer {
        text-align: right;
        padding: 12px 24px 24px;
    }

    &__form {
        max-width: 485px;
        margin: 0 auto;
    }

    &__input {
        width: 100%;
    }

    &__input-dialog {
        min-height: 30px;

        .checkbox {
            margin: 0;
        }

        label {
            font-weight: normal;
            font-size: $size-font-xxsmall;
        }
    }

    &__form .form-group {
        margin-bottom: 0;
    }

    &__form .form-group.has-error > label {
        color: #e65c5c;
    }
}

.tdk-modal-content {
    border-radius: 6px;
    border-width: 5px;
}

.tdk-modal-header {
    border-bottom: 0;
}

.modal-dialog--fullscreen {
    position: relative;
    background-color: $color-supplement-white-base;
    margin: 0;
    width: 100%;
    height: 100%;
    padding-bottom: $spacing-11;

    &-header {
        height: $spacing-7;
        position: sticky;
        top: 0;
        z-index: 100;
    }
}

@media (min-width: 991px) {
    .modal-dialog {
        margin: 70px auto;
        border-radius: $spacing-3;
        &-center {
            max-height: 80vh;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
        }
    }
}

/* FADE IN 0 - 1 */

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* FADE IN 0 - 0 */

@keyframes fadein-half {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein-half {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein-half {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein-half {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein-half {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

/* FADE OUT 0 - 1 */

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* FADE OUT 0 - 0 */

@keyframes fadeout-half {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout-half {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout-half {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout-half {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeout-half {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(100%);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
        animation-timing-function: ease-in;
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateY(0%);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
        animation-timing-function: ease-in;
    }
}

.modal.in {
    animation: fadein 0.25s;
    opacity: 1;
}

.modal.slide-in {
    animation: slideIn 0.25s ease-in;
    opacity: 1;
}

.modal-backdrop.in {
    transition: opacity 0.25s ease-in;
    opacity: 0.6;
}

.modal.slide-out {
    animation: slideOut 0.25s ease-out;
    opacity: 0;
}

.modal {
    animation: fadeout 0.25s;
    opacity: 0;
}

.modal-backdrop {
    opacity: 0;
    transition: opacity 0.25s ease-in;
}

@keyframes slide-in-mobile {
    0% {
        opacity: 0;
        max-height: 0;
        transform: translateY(100%);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        animation-timing-function: ease-in;
    }
}
@keyframes slide-out-mobile {
    0% {
        opacity: 1;
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 0;
        max-height: 0;
        transform: translateY(100%);
        animation-timing-function: ease-in;
    }
}
@media (max-width: $screen-sm-max) {
    .modal:not(:where(.slide-in, .slide-out)) {
        top: auto;
        animation: slide-out-mobile 0.25s ease-in;
        animation-fill-mode: forwards;
        &.in {
            animation: slide-in-mobile 0.25s ease-in;
            animation-fill-mode: forwards;
        }
        .modal-dialog {
            margin: 0;
            overflow-y: auto;
            max-height: 100vh;
            max-width: 100%;
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            width: unset;
            .topdk-modal__content {
                border-radius: $border-radius-medium $border-radius-medium 0 0;
            }
        }
    }
}
