.panel {
    box-shadow: none;

    &--claims {
        &:last-of-type {
            margin-bottom: 0;
        }
        p:last-child {
            margin-bottom: 0;
        }
        > a,
        > a:hover {
            color: $color-primary-1-base;
            text-decoration: none;
        }
        &.button-effect:hover {
            background-color: $color-supplement-grey-light;
            transform: scale(1.005);
        }
        .panel-heading {
            h2 {
                font-size: 3rem;
                font-weight: bold;
                line-height: 1;
                margin: 0 0 15px;
            }
        }
        .panel-body {
            hr {
                margin-top: 0;
            }
        }
    }

    &-body {
        &.row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--insurance {
        .panel-body {
            padding: 15px;
        }

        &__image {
            display: none;
            fill: $color-primary-2-base;

            @media (min-width: $screen-sm) {
                display: inherit;
                width: 50px;
                height: 50px;
                margin-right: 15px;
                vertical-align: middle;
            }
        }

        &__status {
            clear: left;
            @media (min-width: $screen-sm) {
                clear: none;
                float: right;
            }

            .fa-check {
                color: $color-supplement-success-base;
            }

            .fa-circle {
                color: red;
                font-size: 66%;
                vertical-align: middle;
            }
        }
    }
}
