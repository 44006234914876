.flos-wizard {
    position: relative;

    &-steps {
        display: inline-block;
        margin: 0 auto;
        position: relative;
        width: 100%;
        transition-property: height, margin-top;
        transition-timing-function: $transition-timing-function;
        transition-duration: inherit;

        &--vertical {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            gap: $spacing-5;
        }
        &--with-progress {
            margin-top: $spacing-7;
        }
    }
    &-step {
        &--vertical {
            background-color: $wizard-vertical-bg-color;
            border-radius: $spacing-3;
            border: 1px solid transparent;
            overflow-x: hidden;
            overflow-y: auto;
            padding: $spacing-7;
            transition-duration: inherit;
            transition-property: background-color, border;
            transition-timing-function: $transition-timing-function;

            scrollbar-color: $color-primary-1-base $color-supplement-grey-base;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: $spacing-2;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-primary-1-base;
            }

            &::-webkit-scrollbar-track {
                background: $color-supplement-grey-base;
            }
        }
        &-animated-div,
        &--vertical,
        &--horizontal {
            display: unset;
        }

        &--active {
            background-color: $wizard-vertical-bg-color--active;
            border: 1px solid $wizard-vertical-border-color--active;
        }
    }
    &-title {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        font-size: $wizard-odd-size;
        gap: $wizard-odd-size;
        line-height: $spacing-7;
        margin: 0;
        position: relative;
        transition-duration: inherit;
        transition-property: color;
        transition-timing-function: $transition-timing-function;

        &--small {
            font-size: $spacing-4;
            line-height: $spacing-5;
            text-align: center;
            color: $wizard-step-color;
        }
        &--subtle {
            color: $wizard-step-color--subtle;
        }
        &:not(:only-child) {
            margin-bottom: $spacing-7;
        }
    }
    &-icon {
        position: absolute;
        opacity: 1;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition-property: transform, opacity;
        transition-duration: inherit;
        transition-timing-function: $transition-timing-function;

        &--hide {
            opacity: 0;
            transform: translate3d(-100%, -50%, 0);
        }
    }
    &-span {
        width: 100%;
        transform: translateX(0);
        transition-property: transform;
        transition-duration: inherit;
        transition-timing-function: $transition-timing-function;

        &--with-icon {
            transform: translateX($spacing-8);
        }
    }

    &-action {
        font-size: $size-font-base;
        z-index: 1;
    }
}
