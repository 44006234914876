.Box--selectable-wrapper {
    display: flex;
    flex-direction: column;
    transition: 0.4s;
    cursor: pointer;
    word-break: break-word;
    width: 100%;
    padding: $spacing-5 0;
    border: 1px solid $selectable-box-border-color;
    border-left-width: $spacing-2;
    border-radius: $border-radius-small;
    min-height: $spacing-9;

    &.Box {
        background-color: $selectable-box-bg-color;
        &.is-active {
            background-color: $selectable-box-bg-color--active;
        }
        &.Box--dimmed,
        &.Box--disabled {
            border-color: $selectable-box-color--dimmed;
            .Box {
                color: $selectable-box-color--dimmed;

                flos-icon {
                    stroke: $selectable-box-color--dimmed;
                }
            }
        }
        &.Box--disabled {
            cursor: not-allowed;
        }
        &:hover:not(.Box--disabled) {
            background-color: $selectable-box-bg-color--hover;
            border-color: $selectable-box-border-color;
            .Box {
                color: $selectable-box-color--hover;
                flos-icon {
                    stroke: $selectable-box-color--hover;
                }
            }
        }
    }

    .Box {
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;

        small {
            font-size: $size-font-xxsmall;
            line-height: $size-lineheight-small;
            font-weight: 400;
        }

        p.strong {
            font-weight: 400;
        }

        &:nth-child(1) {
            padding-top: 0;
            padding-bottom: 0;
            font-size: $size-font-xsmall;
            line-height: $size-lineheight-medium;
            font-weight: 400;
        }
        &:nth-child(2) {
            padding-top: $spacing-3;
            padding-bottom: 0;
            font-size: $size-font-xxsmall;
            line-height: $size-lineheight-small;
            font-weight: 400;
        }

        &--has-icon {
            display: flex;
            align-items: center;

            flos-icon {
                flex-shrink: 0;
            }
        }
    }

    &.Box--selectable-plain-description {
        .Box:last-child {
            flex-grow: 1;
        }
    }

    &.Box--more {
        text-align: center;
        .Box {
            padding-top: 1px;
            padding-bottom: 1px;
            color: $selectable-box-more-color;

            flos-icon {
                height: auto;
                width: $selectable-box-more-icon-size;
                margin: -15px 0;
            }
        }
        &.is-active,
        &:hover,
        &:active {
            .Box {
                color: $selectable-box-more-color;
                flos-icon {
                    stroke: $selectable-box-more-color;
                }
            }
        }
    }
}

.Box--selectable-grid {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;

    @media (min-width: $screen-sm) {
        display: grid;
        grid-column-gap: $spacing-5;
        grid-row-gap: $spacing-5;
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
    @media (min-width: $screen-md) {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
}

.Box--selectable-grid-compact {
    display: grid;
    grid-column-gap: $spacing-3;
    grid-row-gap: $spacing-3;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-bottom: $spacing-2;

    .Box--selectable-wrapper:nth-of-type(n) {
        grid-row-start: 1;
    }

    &:not(.Box--selectable-grid-compact-popup) {
        @media (max-width: $screen-sm-max) {
            display: flex;
            flex-direction: column;
            gap: $spacing-3;
        }
    }

    &.Box--selectable-grid-compact-popup {
        @media (max-width: $screen-sm-max) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .Box--selectable-wrapper {
            display: none;
            &.is-active,
            &.Box--more {
                display: initial;
            }
        }
    }

    .Box--selectable-wrapper {
        margin-bottom: 1px;
        padding: 0;
    }

    .Box--selectable-wrapper:not(.Box--more) {
        margin-bottom: 1px;
        padding: 13px 0 0 0;

        .Box {
            display: flex;
            justify-content: center;
            p {
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (max-width: $screen-sm-max) {
                    max-width: 200px;
                }
            }
        }
    }
}

.Box--selectable-grid-popup-menu {
    display: none;
    &.Box--selectable-grid-popup-menu-show {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 8px;
        grid-row-gap: 4px;
        @media (max-width: $screen-sm-max) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .Box--selectable-wrapper {
            grid-column: 1 / span 2;
            margin-bottom: 1px;

            &.is-active {
                display: none;
            }
            .Box {
                display: flex;
                justify-content: center;

                p {
                    max-width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
